import React, { createContext, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import en from '../locales/en.json';
import ko from '../locales/ko.json';

const TranslationContext = createContext();

const defaultLanguage = 'en';

const translations = {
  en,
  ko,
};

export function TranslationProvider({ children }) {
  const language = useSelector(state => state.language);

  const contextValue = useMemo(
    () => ({
      translation: translations[language] || translations[defaultLanguage],
      language,
    }),
    [language],
  );
  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
}

export function useTranslation() {
  return useContext(TranslationContext);
}
