/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Header, CustomNavigator } from '@components';
import { Container, Image, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import { CustomSwal } from '../../components';
import { getQnaDetail, removeQna, repostQna } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

// ===================================================================
// [ 게시물 관리 > FAQ 상세 ]
// ===================================================================
export default React.memo(function QnaDetail(props) {
  const navigate = useNavigate();

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [qnaDetail, setQnaDetail] = useState([]);
  const [date, setDate] = useState(new Date());
  const { qnaIdx } = useParams();

  const goModify = () => {
    navigate(`${RouterPath.qnaEdit}`, { state: { qnaIdx } });
  };

  // ===================================================================
  // [ API ] 공지 상세 불러오기
  // ===================================================================
  const getDetail = async () => {
    try {
      const param = {
        qnaIdx,
      };

      const data = await getQnaDetail(param.qnaIdx);
      if (data.data.code === 200) {
        const info = data.data.data;

        setQnaDetail(info);
        setDate(new Date(info.regDate));
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 공지 리스트 삭제
  // ===================================================================
  const qnaDelete = async () => {
    try {
      const data = await removeQna(qnaIdx);
      if (data.data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: '삭제가 완료되었습니다.',
        });
        getDetail();
      }
    } catch (error) {
      handleError(error);
    }
  };
  // ===================================================================
  // [ Api ] FAQ 재게시
  // ===================================================================
  const repost = async idx => {
    try {
      const { data } = await repostQna(idx);
      if (data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: 'FAQ를 다시 게시했습니다.',
        });
        getDetail();
      }
    } catch (error) {
      handleError(error);
      getDetail();
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getDetail();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="qna-detail">
      <Header title="FAQ" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['FAQ', '상세보기']}
          pathList={[RouterPath.qnaList]}
        />
        <div className="title">
          <p className="title-text">FAQ</p>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {/* 삭제 btn */}
            {qnaDetail.useYn === 'Y' ? (
              <Button
                type="button"
                onClick={() => {
                  CustomSwal.fire({
                    title: 'FAQ 삭제',
                    text: 'FAQ를 삭제하시겠습니까?',
                    then: () => {
                      qnaDelete(qnaDetail.qnaIdx);
                    },
                    cancel: true,
                  });
                }}
              >
                삭제
              </Button>
            ) : (
              <Button
                type="button"
                onClick={() => {
                  CustomSwal.fire({
                    title: 'FAQ 재게시',
                    text: 'FAQ를 다시 게시하시겠습니까?',
                    then: () => {
                      repost(qnaDetail.qnaIdx);
                    },
                    cancel: true,
                  });
                }}
              >
                재게시
              </Button>
            )}
            {/* 수정 btn */}
            <Button onClick={() => goModify()}>수정</Button>
          </div>
        </div>
        {/* 바디 */}
        <article className="partner-detail-info">
          <div className="form-box">
            <div className="info-box">
              {/* 작성자 */}
              <div className="info-box-grid">
                <p className="grid-title">작성자</p>
                <p className="grid-body">{qnaDetail.adminName}</p>
              </div>
              {/* 등록일 */}
              <div className="info-box-grid">
                <p className="grid-title">등록일시</p>
                <p className="grid-body">{format(date, 'yyyy-MM-dd HH:mm')}</p>
              </div>
              {/* 제목 */}
              <div className="info-box-grid">
                <p className="grid-title">FAQ 질문</p>
                <p className="grid-body">{qnaDetail.qnaQuestion}</p>
              </div>
              {/* 내용 */}
              <div className="info-box-grid">
                <p className="grid-title">FAQ 답변</p>
                <p className="grid-body">{qnaDetail.qnaAnswer}</p>
              </div>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
