/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Header, CustomNavigator } from '@components';
import { Container, Image, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import { CustomSwal } from '../../components';
import {
  getDownloadFile,
  getNoticeDetail,
  removeNotice,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

// ===================================================================
// [ 게시물 관리 > 공지사항 상세 ]
// ===================================================================
export default React.memo(function NoticeDetail(props) {
  const navigate = useNavigate();

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [noticeDetail, setNoticeDetail] = useState([]);
  const [date, setDate] = useState(new Date());
  const { noticeIdx } = useParams();

  const goModify = () => {
    navigate(`${RouterPath.noticeEdit}`, { state: { noticeIdx } });
  };

  // ===================================================================
  // [ API ] 파일 다운로드
  // ===================================================================
  const downloadFile = async (fileNm, fileUrl) => {
    try {
      const params = {
        filePath: fileUrl,
      };
      const response = await getDownloadFile(params);

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileNm;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 공지 상세 불러오기
  // ===================================================================
  const getDetail = async () => {
    try {
      const param = {
        noticeIdx,
      };

      const data = await getNoticeDetail(param.noticeIdx);
      if (data.data.code === 200) {
        const info = data.data.data;

        setNoticeDetail(info);
        setDate(new Date(info.regDate));
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 공지 리스트 삭제
  // ===================================================================
  const noticeDelete = async () => {
    try {
      const data = await removeNotice(noticeIdx);
      if (data.data.code === 200) {
        CustomSwal.fire({
          text: '삭제가 완료되었습니다.',
          finally: () => navigate(`${RouterPath.noticeList}/1`),
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getDetail();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="notice-detail">
      <Header title="공지사항" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['공지사항', '상세보기']}
          pathList={[RouterPath.noticeList]}
        />
        <div className="title">
          <p className="title-text">공지사항</p>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {/* 삭제 btn */}
            <Button
              variant="outline-primary"
              onClick={e => {
                CustomSwal.fire({
                  text: '삭제하시겠습니까?',
                  cancel: true,
                  then: noticeDelete,
                });
              }}
            >
              삭제
            </Button>
            {/* 수정 btn */}
            <Button onClick={() => goModify()}>수정</Button>
          </div>
        </div>
        {/* 바디 */}
        <article className="partner-detail-info">
          <div className="form-box">
            <div className="info-box">
              {/* 공지 */}
              <div className="info-box-grid">
                <p className="grid-title">공지유형</p>
                <p className="grid-body">{[noticeDetail.categoryName]}</p>
              </div>
              {/* 수정 */}
              <div className="info-box-grid">
                <p className="grid-title">등록일시</p>
                <p className="grid-body">{format(date, 'yyyy-MM-dd HH:mm')}</p>
              </div>
              {/* 작성자 */}
              <div className="info-box-grid">
                <p className="grid-title">작성자</p>
                <p className="grid-body">{noticeDetail.adminName}</p>
              </div>
              {/* 제목 */}
              <div className="info-box-grid">
                <p className="grid-title">제목</p>
                <p className="grid-body">{noticeDetail.noticeTitle}</p>
              </div>
              {/* 내용 */}
              <div className="info-box-grid">
                <p className="grid-title">내용</p>
                <p className="grid-body">{noticeDetail.noticeBody}</p>
              </div>
              {/* 파일 */}
              <div className="info-box-grid">
                <p className="grid-title">파일첨부</p>
                {noticeDetail.noticeFiles &&
                noticeDetail.noticeFiles.length > 0 ? (
                  <div>
                    {noticeDetail.noticeFiles.map((file, fileIndex) => (
                      <p
                        style={{
                          color: '#326BFF',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        className="grid-body"
                        key={fileIndex}
                        onClick={() => {
                          downloadFile(
                            file.originalFileName,
                            file.uploadedFilePath,
                          );
                        }}
                      >
                        {file.originalFileName}
                      </p>
                    ))}
                  </div>
                ) : (
                  <p className="grid-body">파일 첨부 없음</p>
                )}
              </div>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
