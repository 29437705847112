/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Accordion, Container, Button } from 'react-bootstrap';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { authAction } from '@reducers/authSlice';
import Moment from 'react-moment';
import 'moment/locale/ko'; // 한글 로케일 설정
import { useNavigate } from 'react-router-dom';
import { store } from '../../store';
import RouterPath from '../../common/constants/RouterPath';
import { WALLET_STATUS, walletAction } from '../../reducers/walletSlice';

export default React.memo(function Header() {
  const navigate = useNavigate();
  const language = useSelector(state => state.language);

  const auth = useSelector(state => state.auth);

  const { walletAddr, walletNetworkId, walletStatus } = useSelector(
    state => state.wallet,
  );

  const logout = () => {
    store.dispatch(authAction.removeAuth());
    navigate(RouterPath.slash);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (window.ethereum && walletStatus === WALLET_STATUS.CONNECT) {
      const handleAccountsChanged = accounts => {
        store.dispatch(walletAction.setWalletAddr(accounts[0]));
      };

      const handleChainChanged = chainIdHex => {
        const chainId = parseInt(chainIdHex, 16);
        store.dispatch(walletAction.setWalletNetworkId(chainId));
      };

      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);

      return () => {
        window.ethereum.removeListener(
          'accountsChanged',
          handleAccountsChanged,
        );
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      };
    }
  }, [walletStatus]);

  return (
    <header css={header}>
      <Container style={{ paddingRight: 0 }}>
        <div className="flex-btn-box">
          <p>{auth.managerNm}</p>
          <Button
            variant="outline-primary"
            onClick={() => {
              logout();
            }}
          >
            로그아웃
          </Button>
        </div>
      </Container>
    </header>
  );
});

const header = css`
  position: fixed;
  top: 0;
  left: 208px;
  width: calc(100% - 208px);
  background: var(--bs-white);
  z-index: 10;
  transition: 0.25s ease-in-out;
  .container {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 56px;
    h2 {
      margin: 0;
      font-size: var(--fs-20);
      color: #2c2f38;
    }
    .flex-btn-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      Button {
        padding: 0;
        height: 34px;
        width: 65px;
        margin-right: 16px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    // left: 60px;
    width: calc(100% - 60px);
  }
`;
