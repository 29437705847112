/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */
import React, { useState, forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { images } from '@assets';
import { css } from '@emotion/react';
import { Form } from 'react-bootstrap';
import ko from 'date-fns/locale/ko';

registerLocale('ko', ko);

// 참고 https://reactdatepicker.com/
const CustomInput = forwardRef(
  ({ value, placeholder, onClick, onChange, disabled, maxWidth }, ref) => {
    // css
    const customInput = css`
      padding: 12px 8px 12px 16px;
      max-width: ${maxWidth || '200px;'}
      text-align: left;
      font-size: 16px;
      background-image: url(${images.icCalendarMonth});
      background-position: right 8px center;
      background-repeat: no-repeat;
      background-size: 24px 24px;
    `;

    return (
      <Form.Control
        css={customInput}
        className="form-control"
        onClick={onClick}
        placeholder={placeholder}
        ref={ref}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    );
  },
);
function CustomDatePicker({
  value,
  onChange,
  maxDate,
  dateFormat,
  minDate,
  disabled,
  maxWidth,
  ...rest
}) {
  return (
    <DatePicker
      selected={value}
      onChange={date => onChange(date)}
      // locale="ko"
      customInput={<CustomInput disabled={disabled} maxWidth={maxWidth} />}
      disabled={disabled}
      maxDate={maxDate && maxDate}
      minDate={minDate && minDate}
      disabledKeyboardNavigation
      dateFormat={dateFormat || 'yyyy.MM.dd'}
      dateFormatCalendar="yyyy.MM"
      formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
      calendarClassName="custom-calendar"
      {...rest}
    />
  );
}

export default CustomDatePicker;
