import React, { useState, useEffect, useRef } from 'react';
import { Header, CustomNavigator, CustomSelect } from '@components';
import { Container, Form, Button, Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { format } from 'date-fns';
import images from '../../assets/images';
import { RouterPath } from '../../common';
import {
  getDownloadFile,
  getReviewDetail,
  getReviewType,
  modifyReview,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import { CustomDatePicker, CustomSwal } from '../../components';

// 배너 용량 제한
const MAX_FILE_SIZE = 10 * 1024 * 1024;

const today = new Date();
const initialFormDetail = {
  reviewType: '',
  title: '',
  contents: '',
  files: '',
  regDate: today,
};

// ===================================================================
// [ 게시물 관리 > 후기 수정 ]
// ===================================================================
export default React.memo(function ReviewEdit(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { reviewIdx } = state;
  const fileInput = useRef();
  const thumbnailInputRef = useRef();

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [files, setFiles] = useState([]);
  const [deleteFile, setDeleteFile] = useState([]);
  const [reviewTypes, setReviewTypes] = useState([]);
  const [editForm, setEditForm] = useState(initialFormDetail);
  const [fileThumbnail, setFileThumbnail] = useState(null);
  const [thumbnailSrc, setThumbnailSrc] = useState(null);

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 리스트 페이지 이동
  const moveReviewDetail = () => {
    navigate(`${RouterPath.reviewList}/1`);
  };

  // 검색 유형 값
  const findSearchType = type => {
    return reviewTypes.find(item => item.value === type);
  };

  const onThumbnailUpload = e => {
    const newFile = e.target.files[0];
    if (newFile.size > MAX_FILE_SIZE) {
      CustomSwal.fire({
        text: '최대 용량은 20MB 입니다.',
      });

      thumbnailInputRef.current.value = '';
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => {
        setThumbnailSrc(reader.result || null);
        setFileThumbnail(newFile);
      };
    }
  };

  // 파일 삭제 ( 초기화 )
  const removeThumbnail = () => {
    setThumbnailSrc(null);
    setFileThumbnail(null);
  };

  const onFileUpload = e => {
    const newFiles = e.target.files;
    const promises = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < newFiles.length; i++) {
      const newFile = newFiles[i];
      const reader = new FileReader();

      const promise = new Promise(resolve => {
        reader.onload = () => {
          resolve(newFile);
        };
      });

      reader.readAsDataURL(newFile);
      promises.push(promise);
    }

    return Promise.all(promises).then(filesArray => {
      setFiles(prevFiles => [...prevFiles, ...filesArray]);
    });
  };

  const handleSelectChange = selectedOption => {
    setEditForm({ ...editForm, categoryCode: selectedOption.value });
  };

  // 파일 업로드 버튼
  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  // 파일 삭제
  const removeFile = index => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  // 파일 삭제
  const removeUploadedFile = fileIdx => {
    const updatedDeleteFile = [...deleteFile];
    updatedDeleteFile.push(fileIdx);
    setDeleteFile(updatedDeleteFile);

    const updatedReviewFiles = editForm.reviewFiles.filter(
      file => file.uploadedFileIdx !== fileIdx,
    );
    setEditForm({ ...editForm, reviewFiles: updatedReviewFiles });
  };

  // 등록일 선택 이벤트
  const dateChangeHandler = date => {
    setEditForm({ ...editForm, regDate: format(date, 'yyyy-MM-dd') });
  };

  // Input 검증
  const validateForm = () => {
    const result = {
      isValid: false,
      message: '',
    };

    // 썸네일 확인
    if (!fileThumbnail && !thumbnailSrc) {
      result.message = '썸네일 사진을 등록해 주세요.';
      return result;
    }

    result.isValid = true;
    return result;
  };

  // ===================================================================
  // [ API ] 후기 상세 가져오기
  // ===================================================================
  const getDetail = async () => {
    try {
      const data = await getReviewDetail(reviewIdx);

      if (data.data.code === 200) {
        const info = data.data.data;
        setEditForm(info);
        try {
          const thumbnail = info.reviewFiles?.filter(
            f => f.categoryCode === 'THUMBNAIL',
          );
          if (thumbnail[0]) {
            const params = {
              filePath: thumbnail[0].uploadedFilePath,
            };
            const response = await getDownloadFile(params);

            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            });

            const url = window.URL.createObjectURL(blob);
            setThumbnailSrc(url);
          }
        } catch (downloadError) {
          handleError(downloadError);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 후기 유형조회
  // ===================================================================
  const getType = async () => {
    try {
      const { data } = await getReviewType();
      const info = data.data;

      setReviewTypes(prevTypes => [
        ...prevTypes,
        ...info.map(item => ({
          value: item.subCode,
          label: item.codeName,
        })),
      ]);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 후기 수정 값 보내기
  // ===================================================================
  const editReview = async () => {
    try {
      // Input 검증
      const validationResult = validateForm();
      if (!validationResult.isValid) {
        CustomSwal.fire({ text: validationResult.message });
        return;
      }

      const param = {
        reviewIdx,
        categoryCode: editForm.categoryCode,
        reviewBody: editForm.reviewBody,
        reviewTitle: editForm.reviewTitle,
        // visitDate: format(visitDate, 'yyyy-MM-dd'),
        regDate: editForm.regDate,
        removeFiles: deleteFile,
      };

      const formData = new FormData();
      const json = JSON.stringify(param);
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('dto', blob);
      formData.append('thumbnail', fileThumbnail);

      if (files && files.length > 0) {
        files.forEach((file, index) => {
          formData.append(`files`, file);
        });
      }

      const { data } = await modifyReview(formData);

      if (data.code === 200) {
        CustomSwal.fire({
          text: '수정이 완료되었습니다.',
          finally: () => navigate(`${RouterPath.reviewDetail}/${reviewIdx}`),
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getType();
    getDetail();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="review-Register">
      <Header title="치료후기 수정" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['치료후기', '치료후기 수정']}
          pathList={[RouterPath.reviewList]}
        />
        <div className="title">
          <p className="title-text">치료후기 수정</p>
        </div>
        <article className="menu-article">
          <div className="align-left">
            {/* 후기 유형 */}
            <div className="add-div">
              <p>
                후기유형 <images.Ellipse />
              </p>
              <CustomSelect
                className="max-200"
                options={reviewTypes}
                onChange={handleSelectChange}
                value={findSearchType(editForm.categoryCode)}
              />
            </div>
            {/* 등록일 */}
            <div className="add-div">
              <p>등록일</p>
              <CustomDatePicker
                className="max-200"
                maxDate={today}
                value={new Date(editForm.regDate)}
                onChange={dateChangeHandler}
              />
            </div>
            {/* 제목 */}
            <div className="add-div">
              <p>제목</p>
              <Form.Control
                type="text"
                placeholder="제목을 입력하세요."
                value={editForm.reviewTitle}
                onChange={e =>
                  setEditForm({ ...editForm, reviewTitle: e.target.value })
                }
              />
            </div>
            {/* 내용 */}
            <div className="add-div">
              <p>내용</p>
              <Form.Control
                as="textarea"
                placeholder="내용을 입력하세요."
                value={editForm.reviewBody}
                style={{ height: '215px', resize: 'none' }}
                onChange={e =>
                  setEditForm({ ...editForm, reviewBody: e.target.value })
                }
              />
            </div>
            <div style={{ marginBottom: '32px', marginTop: '16px' }}>
              <p>썸네일</p>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                {/* 썸네일 미리보기 */}
                {thumbnailSrc && (
                  <div className="upload-css">
                    <Image
                      width="100%"
                      src={thumbnailSrc}
                      className="select-img"
                    />
                    <Image
                      src={images.IcCloseImg}
                      className="close-img"
                      onClick={removeThumbnail}
                    />
                  </div>
                )}
                {/* 이미지 Input */}
                {!thumbnailSrc && (
                  <label htmlFor="ex_file" className="image-input">
                    <Image src={images.cameraAlt} />
                    <span>이미지등록</span>
                    <input
                      id="ex_file"
                      type="file"
                      accept="image/*"
                      ref={thumbnailInputRef}
                      onChange={e => onThumbnailUpload(e)}
                    />
                  </label>
                )}
              </div>
              <p className="helper-text">
                *파일형식 : jpg, gif, png / 파일용량 20MB이하
              </p>
            </div>
            <div>
              <p>파일첨부</p>
              {editForm.reviewFiles?.map(
                (f, i) =>
                  f.categoryCode === 'BODY' && (
                    <div style={{ display: 'flex' }} key={i}>
                      <Form.Control
                        type="text"
                        value={f.originalFileName}
                        placeholder="파일을 업로드 해주세요"
                        style={{ margin: '0 0 5px', flex: 1 }}
                        onChange={() => false}
                      />
                      <Image
                        src={images.icCancleBtn}
                        className="close-img"
                        onClick={() => removeUploadedFile(f.uploadedFileIdx)}
                      />
                    </div>
                  ),
              )}
              {files.map((file, index) => (
                <div style={{ display: 'flex' }}>
                  <Form.Control
                    type="text"
                    value={file.name}
                    placeholder="파일을 업로드 해주세요"
                    style={{ margin: '0 0 5px', flex: 1 }}
                    onChange={() => false}
                  />
                  <Image
                    src={images.icCancleBtn}
                    className="close-img"
                    onClick={() => removeFile(index)}
                  />
                </div>
              ))}
              {(
                editForm.reviewFiles?.filter(f => f.categoryCode === 'BODY') ??
                []
              ).length +
                files.length <
              5 ? (
                <Button onClick={handleFileButtonClick}>파일첨부</Button>
              ) : (
                ''
              )}
              <input
                type="file"
                ref={fileInput}
                onChange={e => onFileUpload(e)}
                multiple={false}
                style={{ display: 'none' }}
              />
            </div>
            <span
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#525252',
              }}
            >
              *이미지 또는 문서를 첨부해주세요.
            </span>
            <div style={{ textAlign: 'right' }}>
              {/* 취소 btn */}
              <button
                type="button"
                className="cancle-btn"
                onClick={() => {
                  CustomSwal.fire({
                    text: '취소하시겠습니까?',
                    cancel: true,
                    then: moveReviewDetail,
                  });
                }}
              >
                취소
              </button>
              {/* 수정 btn */}
              <button
                type="button"
                className="register"
                onClick={() => {
                  CustomSwal.fire({
                    text: '치료후기을 수정하시겠습니까?',
                    cancel: true,
                    then: editReview,
                  });
                }}
              >
                수정
              </button>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
