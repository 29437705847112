import authReducer from './authSlice';
import languageReducer from './languageSlice';
import loadingAction from './lodingSlice';
import walletAction from './walletSlice';

const auth = authReducer;
const language = languageReducer;
const loading = loadingAction;
const wallet = walletAction;

export default {
  auth,
  language,
  loading,
  wallet,
};
