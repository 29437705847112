/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef } from 'react';
import { Header, CustomNavigator } from '@components';
import { Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { RouterPath, Utils } from '../../common';
import { handleError } from '../../utils/HandleError';
import { saveQna } from '../../api/RestAPI';
import CustomSwal from '../../components/CustomSwal';

export default React.memo(function QnaRegister(props) {
  const navigate = useNavigate();
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [qnaQuestion, setQnaQuestion] = useState(0);
  const [qnaAnswer, setQnaAnswer] = useState(0);

  // ===================================================================
  // [ Utils ]
  // ===================================================================
  const validateForm = () => {
    const result = {
      isValid: false,
      message: '',
    };
    // 질문
    if (qnaQuestion === '') {
      result.message = 'FAQ 질문을 입력해 주세요.';
      return result;
    }

    // 답변
    if (qnaAnswer === '') {
      result.message = 'FAQ 답변을 입력해 주세요.';
      return result;
    }

    result.isValid = true;
    return result;
  };

  // 리스트 페이지 이동
  const moveQnaListPage = () => {
    navigate(RouterPath.qnaList);
  };

  // ===================================================================
  // [ Api ] FAQ 등록
  // ===================================================================
  const registerQna = async e => {
    const { target } = e;
    try {
      // Input 검증
      const validationResult = validateForm();
      if (!validationResult.isValid) {
        CustomSwal.fire({
          title: '알람',
          text: validationResult.message,
        });
        return;
      }

      // JSON 파라미터
      const param = {
        qnaQuestion,
        qnaAnswer,
        orderNo: 1,
      };

      // Axios
      const { data } = await saveQna(param);

      // Return
      if (data.code === 200) {
        CustomSwal.fire({
          text: 'FAQ 등록이 완료되었습니다.',
          finally: moveQnaListPage,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="qna-Register">
      <Header title="FAQ 등록" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['FAQ 관리', 'FAQ 등록']}
          pathList={[RouterPath.qnaList, '']}
        />
        <div className="title">
          <p className="title-text">FAQ 등록</p>
        </div>
        <article className="qna-article">
          <div className="align-left">
            <div className="add-div">
              <p>FAQ 질문</p>
              <Form.Control
                type="text"
                placeholder="FAQ 질문을 입력 해 주세요."
                onChange={e => setQnaQuestion(e.target.value)}
              />
            </div>
            <div className="add-div">
              <p>FAQ 답변</p>
              <Form.Control
                as="textarea"
                placeholder="FAQ 답변을 입력해주세요."
                style={{ height: '215px', resize: 'none' }}
                onChange={e => setQnaAnswer(e.target.value)}
              />
            </div>
            {/* 버튼 */}
            <div style={{ textAlign: 'right' }}>
              {/* 버튼 > 취소 */}
              <button
                type="button"
                className="cancle-btn"
                onClick={() => {
                  CustomSwal.fire({
                    text: '취소하시겠습니까?',
                    cancel: true,
                    then: moveQnaListPage,
                  });
                }}
              >
                취소
              </button>
              {/* 버튼 > 등록 */}
              <button
                type="button"
                className="register"
                onClick={() => {
                  CustomSwal.fire({
                    text: '등록하시겠습니까?',
                    cancel: true,
                    then: registerQna,
                  });
                }}
              >
                등록
              </button>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
