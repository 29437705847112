/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { decodeToken } from 'react-jwt';

const initialState = {
  isLogin: false,
  accessToken: undefined,
  refreshToken: undefined,
  managerNm: undefined,
  clientNm: undefined,
  firstMenuUrl: undefined,
  role: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const decodedToken = decodeToken(action.payload.accessToken);
      state.isLogin = action.payload.isLogin;
      state.accessToken = action.payload.accessToken;
      state.managerNm = decodedToken.id || undefined;
      state.clientNm = decodedToken.clientNm || undefined;
      state.refreshToken = action.payload.refreshToken;
      state.firstMenuUrl = action.payload.firstMenuUrl;
      state.role = decodedToken.role || undefined;
    },
    getAuth: (state, action) => {
      const auth = {
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        managerNm: state.managerNm,
        firstMenuUrl: state.firstMenuUrl,
        role: state.role,
      };
      action.payload = auth;
    },
    removeAuth: state => {
      state.isLogin = false;
      state.accessToken = initialState.accessToken;
      state.managerNm = initialState.managerNm;
      state.refreshToken = initialState.refreshToken;
      state.firstMenuUrl = initialState.firstMenuUrl;
    },
    setFirstMenuUrl: (state, action) => {
      state.firstMenuUrl = action.payload.firstMenuUrl;
    },
  },
});

// Action creators are generated for each case reducer function
export const authAction = authSlice.actions;

export default authSlice.reducer;
