const POPUP_TYPE = {
  MAIN: {
    code: 'MAIN',
    desc: '메인',
  },
  CLINIC_HEIGHT: {
    code: 'CLINIC_HEIGHT',
    desc: '키성장',
  },
  CLINIC_PROSTATE: {
    code: 'CLINIC_PROSTATE',
    desc: '전립선',
  },
  CLINIC_FOCUS: {
    code: 'CLINIC_FOCUS',
    desc: '집중력',
  },
  CLINIC_DIET: {
    code: 'CLINIC_DIET',
    desc: '다이어트',
  },
};

export default POPUP_TYPE;
