/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Accordion, Image } from 'react-bootstrap';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { handleError } from '../utils/HandleError';

// Images
import images from '../assets/images';

// API
import { getAdmiMenuList } from '../api/RestAPI';

// ===================================================================
// [ 사이드바 ]
// ===================================================================
export default React.memo(function CustomSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  // 로그인 회원 정보 ( isLogin, accessToken, managerNm )
  const auth = useSelector(state => state.auth);

  // ===================================================================
  // [ State ]
  // ===================================================================

  // 메뉴 리스트
  const [menuList, setMenuList] = useState([]);

  // 메뉴 활성화
  const [activeMenu, setActiveMenu] = useState('');

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 메뉴 활성화 이벤트
  const menuClickHandler = key => {
    if (activeMenu === key) setActiveMenu('');
    else setActiveMenu(key);
  };

  // ===================================================================
  // [ API ] 메뉴 조회
  // ===================================================================
  const getMenus = async () => {
    try {
      // Axios
      const { data } = await getAdmiMenuList();

      // Return
      if (data.code === 200) {
        const list = [...data.data];
        localStorage.setItem('menuPermissions', JSON.stringify(list));

        // Depth 1
        const firstDepthMenuList = list
          .filter(menu => menu.menuDepth === 1)
          .map(item => {
            return {
              menuSeq: item.menuIdx,
              menuUrl: item.menuRouteUrl,
              menuNm: item.menuName,
              subList: [],
              menuImg: item.menuIconClass,
            };
          });

        // Depth 2
        const secondDepthMenuList = list
          .filter(menu => menu.menuDepth === 2)
          .map(item => {
            return {
              mainMenuSeq: item.menuParentIdx,
              subMenuSeq: item.menuIdx,
              subMenuNm: item.menuName,
              subMenuUrl: item.menuRouteUrl,
            };
          });

        // 메뉴 정보 설정
        for (const mainMenu of firstDepthMenuList) {
          const { subList = [] } = mainMenu;
          for (const subMenu of secondDepthMenuList) {
            if (mainMenu.menuSeq === subMenu.mainMenuSeq) {
              subList.push(subMenu);
            }
          }
        }

        // 메뉴 정보 갱신
        setMenuList([...firstDepthMenuList]);
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getMenus();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <aside className="overflow-auto">
      {/* 사이드바 - 로고 */}
      <h1
        className="menu-logo"
        onClick={() => navigate(auth.firstMenuUrl)}
        style={{ cursor: 'pointer' }}
      >
        <p
          className="logo-text"
          onClick={() => navigate(auth.firstMenuUrl)}
          style={{ cursor: 'pointer' }}
        >
          골든힐 어드민
        </p>
      </h1>

      {/* 사이드바 - 메뉴 리스트 */}
      <Accordion className="sidebar sidebar-scroll" activeKey={activeMenu}>
        {/* 메인메뉴 */}
        {menuList.length > 0 &&
          menuList.map((v, i) => {
            const activeCheckUrl = v.menuRouterUrl?.split('/')[1];

            // Return
            return v.subList.length > 0 ? (
              <Accordion.Item
                className="sidebar-item"
                eventKey={`sidebar-item-${i + 1}`}
                key={`sidebar-item-${i + 1}`}
                onClick={() => {
                  menuClickHandler(`sidebar-item-${i + 1}`);
                }}
              >
                <div
                  className={classNames('is-sub-active', {
                    active: location.pathname.split('/')[1] === activeCheckUrl,
                  })}
                >
                  {/* 메뉴 */}
                  <Accordion.Header>
                    <Image className="sidebar-image" src={images[v.menuImg]} />
                    <p className="sidebar-text">{v.menuNm}</p>
                  </Accordion.Header>

                  {/* 드랍다운 */}
                  <Accordion.Body>
                    <ul>
                      {/* 서브메뉴 리스트 */}
                      {v.subList.length > 0 &&
                        v.subList.map((s, j) => {
                          const activeCheckSubUrl = `${
                            s.subMenuUrl?.split('/')[1]
                          }/${s.subMenuUrl?.split('/')[2]}`;
                          // Return
                          return (
                            <li
                              key={`sub-item-${i + 1}-${j + 1}`}
                              onClick={e => e.stopPropagation()}
                            >
                              <NavLink
                                to={s.subMenuUrl}
                                className={classNames({
                                  active:
                                    `${location.pathname.split('/')[1]}/${
                                      location.pathname.split('/')[2]
                                    }` === activeCheckSubUrl,
                                })}
                              >
                                {s.subMenuNm}
                              </NavLink>
                            </li>
                          );
                        })}
                    </ul>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            ) : (
              <Accordion.Item
                eventKey={`sidebar-item-${i + 1}`}
                key={`sidebar-item-${i + 1}`}
                className="sidebar-item"
                style={{
                  height: '48px',
                }}
                onClick={() => {
                  menuClickHandler(`sidebar-item-${i + 1}`);
                }}
              >
                <div>
                  <h2>
                    <button
                      type="button"
                      className={classNames('sidebar-button', {
                        active:
                          location.pathname?.split('/')[1] === activeCheckUrl,
                      })}
                      onClick={() => navigate(v.menuUrl)}
                    >
                      <Image
                        className="sidebar-image"
                        src={images[v.menuImg]}
                      />
                      <p className="sidebar-text">{v.menuNm}</p>
                    </button>
                  </h2>
                </div>
              </Accordion.Item>
            );
          })}
      </Accordion>
    </aside>
  );
});
