/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Header } from '@components';
import { Container, Table, Form, Button } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { CustomNavigator, CustomSelect } from '../../components';
import IsYN from '../../common/constants/IsYN';
import { getMenuList, modifyMenu, saveMenu } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import CustomSwal from '../../components/CustomSwal';

export default React.memo(function CodeSet(props) {
  const [menuList, setMenuList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState();
  const [menuSaveVisible, setMenuSaveVisible] = useState(false);
  const [subMenuSaveVisible, setSubMenuSaveVisible] = useState(false);
  const [editMenuIdx, setEditMenuIdx] = useState();
  const [menuParentIdx, setMenuParentIdx] = useState();
  const [menuDepth, setMenuDepth] = useState();
  const [menuRouteUrl, setMenuRouteUrl] = useState('');
  const [menuRestUrl, setMenuRestUrl] = useState('');
  const [orderNo, setOrderNo] = useState('');
  const [menuName, setMenuName] = useState('');
  const [useYn, setUseYn] = useState('');
  const [menuIconClass, setMenuIconClass] = useState('');

  const useYnOptions = [
    {
      value: IsYN.Y,
      label: IsYN.Y,
    },
    {
      value: IsYN.N,
      label: IsYN.N,
    },
  ];

  /**
   * api
   */
  // list
  const getList = async () => {
    try {
      const { data } = await getMenuList();
      const resultList = getDepsList(data.data);
      setMenuList(resultList);
      if (selectedMenu) {
        const selectedMenuItem = resultList.find(
          item => item.menuIdx === selectedMenu.menuIdx,
        );
        setSelectedMenu(selectedMenuItem);
      }
    } catch (error) {
      handleError(error);
    }
  };

  // save
  const registerMenu = async e => {
    const { target } = e;
    target.disabled = true;
    try {
      const params = {
        menuParentIdx,
        menuRouteUrl,
        menuRestUrl,
        orderNo,
        menuName,
        useYn,
        menuIconClass,
        menuDepth,
      };
      const { data } = await saveMenu(params);

      if (data.code === 200) {
        CustomSwal.fire({
          title: 'SUCCESS',
          text: '메뉴가 등록되었습니다.',
          finally: () => {
            initEditValues();
            getList();
          },
        });
      }
    } catch (error) {
      handleError(error);
    }
    target.disabled = false;
  };

  // modify
  const modify = async e => {
    const target = e.currentTarget;
    target.disabled = true;
    try {
      const params = {
        menuIdx: editMenuIdx,
        menuParentIdx,
        menuRouteUrl,
        menuRestUrl,
        orderNo,
        menuName,
        useYn,
        menuIconClass,
        menuDepth,
      };

      const { data } = await modifyMenu(params);

      if (data.code === 200) {
        CustomSwal.fire({
          title: 'SUCCESS',
          text: '메뉴가 수정되었습니다.',
          finally: () => {
            initEditValues();
            getList();
          },
        });
      }
    } catch (error) {
      handleError(error);
    }
    target.disabled = false;
  };

  /**
   * event
   */

  const showMenuSave = () => {
    initEditValues();
    setMenuDepth(1);
    setMenuSaveVisible(true);
  };
  const closeMenuSave = () => {
    initEditValues();
    setMenuSaveVisible(false);
  };

  const showSubMenuSave = () => {
    initEditValues();
    setMenuDepth(2);
    setMenuParentIdx(selectedMenu.menuIdx);
    setSubMenuSaveVisible(true);
  };
  const closeSubMenuSave = () => {
    initEditValues();
    setSubMenuSaveVisible(false);
  };

  /**
   * help function
   */
  const getDepsList = list => {
    if (list && list.length > 0) {
      let permList = list;
      const removeList = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const obj of permList) {
        const subList = [];
        permList.forEach(item => {
          // eslint-disable-next-line no-unused-expressions
          if (obj.menuIdx === item.menuParentIdx) {
            subList.push(item);
            removeList.push(item);
          }
        });
        obj.subList = subList;
      }
      permList = permList.filter(item => !removeList.includes(item));
      return permList;
    }
    return [];
  };

  const findYnOptions = itemYnValue => {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    const find = useYnOptions.find(item => item.value === itemYnValue);
    return find;
  };

  const showModifyEdit = item => {
    setMenuSaveVisible(false);
    setSubMenuSaveVisible(false);
    setEditMenuIdx(item.menuIdx);
    setUseYn(item.useYn);
    setOrderNo(item.orderNo);
    setMenuRouteUrl(item.menuRouteUrl);
    setMenuRestUrl(item.menuRestUrl);
    setMenuName(item.menuName);
    setMenuIconClass(item.menuIconClass);
    setMenuParentIdx(item.menuParentIdx ? item.menuParentIdx : '');
  };

  const initEditValues = () => {
    setMenuSaveVisible(false);
    setSubMenuSaveVisible(false);
    setEditMenuIdx('');
    setUseYn(useYnOptions[0].value);
    setOrderNo(1);
    setMenuRouteUrl('');
    setMenuRestUrl('');
    setMenuName('');
    setMenuIconClass('');
    setMenuParentIdx('');
  };

  /**
   * useEffect
   * */
  useEffect(() => {
    getList();
  }, []);

  return (
    <main id="codeset">
      <Header title="메뉴 관리" />
      <Container className="contents">
        <CustomNavigator pageNameList={['시스템 관리', '메뉴 관리']} />
        <div className="title">
          <p className="title-text">메뉴 관리</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '16px',
            width: '100%',
          }}
        >
          <article className="menu-article">
            <div className="add-btn-title">
              <h6>대메뉴</h6>
              <Button onClick={showMenuSave}>
                <i className="material-icons">add</i>
              </Button>
            </div>
            <div
              style={{ overflowY: 'scroll', height: '640px', width: '100%' }}
            >
              <Table className="menulists table-hover">
                <colgroup>
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                  <col width={50} />
                  <col width={50} />
                  <col width={50} />
                </colgroup>

                <thead
                  className="table-tr"
                  style={{ position: 'sticky', top: '-1px' }}
                >
                  <tr>
                    <th>메뉴명</th>
                    <th>라우팅</th>
                    <th>API</th>
                    <th>아이콘</th>
                    <th className="th-center">순서</th>
                    <th className="th-center">사용여부</th>
                    <th aria-label="emty1"> </th>
                  </tr>
                </thead>

                <tbody>
                  {!menuList || menuList.length === 0 ? (
                    <tr>
                      <td
                        colSpan="6"
                        style={{
                          textAlign: 'center',
                          borderBottom: '0',
                          color: '#818185',
                          fontWeight: '500',
                        }}
                      >
                        데이터가 없습니다.
                      </td>
                    </tr>
                  ) : (
                    menuList.map((item, index) => (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <Fragment key={index}>
                        {editMenuIdx === item.menuIdx ? (
                          <tr className="edit-tr" key={index}>
                            <td aria-label="control1">
                              <div>
                                <Form.Control
                                  placeholder="메뉴명"
                                  style={{
                                    width: '100%',
                                    marginBottom: '39px',
                                  }}
                                  onChange={e => {
                                    setMenuName(e.target.value);
                                  }}
                                  value={menuName}
                                />
                              </div>
                            </td>

                            <td aria-label="control1">
                              <div>
                                <Form.Control
                                  placeholder="URL"
                                  style={{
                                    width: '100%',
                                    marginBottom: '39px',
                                  }}
                                  onChange={e => {
                                    setMenuRouteUrl(e.target.value);
                                  }}
                                  value={menuRouteUrl}
                                />
                              </div>
                            </td>
                            <td aria-label="control1">
                              <div>
                                <Form.Control
                                  placeholder="URL"
                                  style={{
                                    width: '100%',
                                    marginBottom: '39px',
                                  }}
                                  onChange={e => {
                                    setMenuRestUrl(e.target.value);
                                  }}
                                  value={menuRestUrl}
                                />
                              </div>
                            </td>
                            <td aria-label="control1">
                              <div>
                                <Form.Control
                                  placeholder="아이콘"
                                  style={{
                                    width: '100%',
                                    marginBottom: '39px',
                                  }}
                                  value={menuIconClass}
                                  onChange={e => {
                                    setMenuIconClass(e.target.value);
                                  }}
                                />
                              </div>
                            </td>
                            <td aria-label="control1">
                              <div>
                                <Form.Control
                                  placeholder="순서"
                                  style={{
                                    width: '100%',
                                    marginBottom: '39px',
                                  }}
                                  value={orderNo}
                                  onChange={e => {
                                    setOrderNo(e.target.value);
                                  }}
                                />
                              </div>
                            </td>
                            <td colSpan="2">
                              <CustomSelect
                                className="mw-85"
                                options={useYnOptions}
                                onChange={e => {
                                  setUseYn(e.value);
                                }}
                                value={findYnOptions(useYn)}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                  justifyContent: 'end',
                                }}
                                className="td-btn-box"
                              >
                                <Button
                                  variant="outline-primary"
                                  onClick={() => {
                                    initEditValues();
                                  }}
                                >
                                  취소
                                </Button>
                                <Button onClick={modify}>저장</Button>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr
                            key={index}
                            className={selectedMenu === item ? 'active' : ''}
                            onClick={() => {
                              setSelectedMenu(item);
                            }}
                          >
                            <td>{item.menuName}</td>
                            <td>{item.menuRouteUrl}</td>
                            <td>{item.menuRestUrl}</td>
                            <td>{item.menuIconClass}</td>
                            <td className="th-center">{item.orderNo}</td>
                            <td className="th-center">{item.useYn}</td>
                            <td>
                              <Button
                                className="edit-btn"
                                onClick={() => {
                                  showModifyEdit(item);
                                }}
                              >
                                수정
                              </Button>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))
                  )}
                  {menuSaveVisible && (
                    <tr className="edit-tr">
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="메뉴명"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            onChange={e => {
                              setMenuName(e.target.value);
                            }}
                            value={menuName}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="URL"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            value={menuRouteUrl}
                            onChange={e => {
                              setMenuRouteUrl(e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="URL"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            value={menuRestUrl}
                            onChange={e => {
                              setMenuRestUrl(e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="아이콘"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            value={menuIconClass}
                            onChange={e => {
                              setMenuIconClass(e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="순서"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            value={orderNo}
                            onChange={e => {
                              setOrderNo(e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td colSpan="2">
                        <CustomSelect
                          className="mw-85"
                          options={useYnOptions}
                          onChange={e => {
                            setUseYn(e.value);
                          }}
                          value={findYnOptions(useYn)}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            justifyContent: 'end',
                          }}
                          className="td-btn-box"
                        >
                          <Button
                            variant="outline-primary"
                            onClick={closeMenuSave}
                          >
                            취소
                          </Button>
                          <Button
                            onClick={e => {
                              CustomSwal.fire({
                                title: '등록',
                                html: '대메뉴를 등록하시겠습니까?',
                                then: () => {
                                  registerMenu(e);
                                },
                              });
                            }}
                          >
                            등록
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </article>
          <article className="menu-article">
            <div className="add-btn-title">
              <h6>소메뉴</h6>
              {selectedMenu && (
                <Button onClick={showSubMenuSave}>
                  <i className="material-icons">add</i>
                </Button>
              )}
            </div>
            <div
              style={{ overflowY: 'scroll', height: '640px', width: '100%' }}
            >
              <Table
                className={`menulists ${
                  selectedMenu?.subList?.length > 0 ? 'table-hover' : ''
                }`}
              >
                <colgroup>
                  <col width={150} />
                  <col width={150} />
                  <col width={150} />
                  <col width={50} />
                  <col width={50} />
                  <col width={50} />
                </colgroup>
                <thead
                  className="table-tr"
                  style={{ position: 'sticky', top: '-1px' }}
                >
                  <tr>
                    <th>메뉴 이름</th>
                    <th>라우팅</th>
                    <th>API</th>
                    <th className="th-center">순서</th>
                    <th className="th-center">사용여부</th>
                    <th aria-label="emty"> </th>
                  </tr>
                </thead>

                <tbody>
                  {!selectedMenu && (
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          textAlign: 'center',
                          borderBottom: '0',
                          color: '#818185',
                          fontWeight: '500',
                        }}
                      >
                        대분류 메뉴를 선택해 주세요.
                      </td>
                    </tr>
                  )}
                  {selectedMenu &&
                    (!selectedMenu.subList ||
                      (selectedMenu.subList.length === 0 &&
                        !subMenuSaveVisible)) && (
                      <tr>
                        <td
                          colSpan="5"
                          style={{
                            textAlign: 'center',
                            borderBottom: '0',
                            color: '#818185',
                            fontWeight: '500',
                          }}
                        >
                          데이터가 없습니다.
                        </td>
                      </tr>
                    )}
                  {selectedMenu &&
                    selectedMenu.subList &&
                    selectedMenu.subList.length > 0 &&
                    selectedMenu.subList.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          {editMenuIdx === item.menuIdx ? (
                            <tr className="edit-tr" key={index}>
                              <td aria-label="control1">
                                <div>
                                  <Form.Control
                                    placeholder="메뉴명"
                                    style={{
                                      width: '100%',
                                      marginBottom: '39px',
                                    }}
                                    onChange={e => {
                                      setMenuName(e.target.value);
                                    }}
                                    value={menuName}
                                  />
                                </div>
                              </td>
                              <td aria-label="control1">
                                <div>
                                  <Form.Control
                                    placeholder="URL"
                                    style={{
                                      width: '100%',
                                      marginBottom: '39px',
                                    }}
                                    value={menuRouteUrl}
                                    onChange={e => {
                                      setMenuRouteUrl(e.target.value);
                                    }}
                                  />
                                </div>
                              </td>
                              <td aria-label="control1">
                                <div>
                                  <Form.Control
                                    placeholder="URL"
                                    style={{
                                      width: '100%',
                                      marginBottom: '39px',
                                    }}
                                    value={menuRestUrl}
                                    onChange={e => {
                                      setMenuRestUrl(e.target.value);
                                    }}
                                  />
                                </div>
                              </td>
                              <td aria-label="control1">
                                <div>
                                  <Form.Control
                                    placeholder="순서"
                                    style={{
                                      width: '100%',
                                      marginBottom: '39px',
                                    }}
                                    value={orderNo}
                                    onChange={e => {
                                      setOrderNo(e.target.value);
                                    }}
                                  />
                                </div>
                              </td>
                              <td colSpan="2">
                                <CustomSelect
                                  className="mw-85"
                                  options={useYnOptions}
                                  onChange={e => {
                                    setUseYn(e.value);
                                  }}
                                  value={findYnOptions(useYn)}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    justifyContent: 'end',
                                  }}
                                  className="td-btn-box"
                                >
                                  <Button
                                    variant="outline-primary"
                                    onClick={() => {
                                      initEditValues();
                                    }}
                                  >
                                    취소
                                  </Button>
                                  <Button onClick={modify}>저장</Button>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr key={index}>
                              <td>{item.menuName}</td>
                              <td>{item.menuRouteUrl}</td>
                              <td>{item.menuRestUrl}</td>
                              <td className="th-center">{item.orderNo}</td>
                              <td className="th-center">{item.useYn}</td>
                              <td>
                                <Button
                                  className="edit-btn"
                                  onClick={() => {
                                    showModifyEdit(item);
                                  }}
                                >
                                  수정
                                </Button>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                  {subMenuSaveVisible && (
                    <tr className="edit-tr">
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="메뉴명"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            onChange={e => {
                              setMenuName(e.target.value);
                            }}
                            value={menuName}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="URL"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            value={menuRouteUrl}
                            onChange={e => {
                              setMenuRouteUrl(e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="URL"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            value={menuRestUrl}
                            onChange={e => {
                              setMenuRestUrl(e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="순서"
                            style={{
                              width: '100%',
                              marginBottom: '39px',
                            }}
                            value={orderNo}
                            onChange={e => {
                              setOrderNo(e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td colSpan="2">
                        <CustomSelect
                          className="mw-85"
                          options={useYnOptions}
                          onChange={e => {
                            setUseYn(e.value);
                          }}
                          value={findYnOptions(useYn)}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            justifyContent: 'end',
                          }}
                          className="td-btn-box"
                        >
                          <Button
                            variant="outline-primary"
                            onClick={closeSubMenuSave}
                          >
                            취소
                          </Button>
                          <Button
                            onClick={e => {
                              CustomSwal.fire({
                                title: '등록',
                                html: '소메뉴를 등록하시겠습니까?',
                                then: () => {
                                  registerMenu(e);
                                },
                              });
                            }}
                          >
                            등록
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </article>
        </div>
      </Container>
    </main>
  );
});
