/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import { Header, CustomNavigator, CustomSelect } from '@components';
import { Container, Image, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { RouterPath } from '../../common';
import TERMS_TYPE from '../../common/constants/TermsType';
import { handleError } from '../../utils/HandleError';
import { saveTerm } from '../../api/RestAPI';
import { CustomException } from '../../common/exceptions';
import CustomSwal from '../../components/CustomSwal';

export default React.memo(function NoticeRegister(props) {
  const navigate = useNavigate();
  const [options, setOptions] = useState({});
  const file = useRef();

  const [selectType, setSelectType] = useState(null);
  const [selectFile, setSelectFile] = useState(null);
  const [fileNm, setFileNm] = useState('');

  const goTerms = () => {
    navigate(`${RouterPath.termList}/1`);
  };

  const postTerm = async e => {
    const { target } = e;
    target.disabled = true;
    try {
      if (selectType === null) {
        throw new CustomException('약관 유형을 선택해주세요.');
      } else if (selectFile === null) {
        throw new CustomException('파일을 첨부해주세요.');
      } else if (selectFile.type !== 'text/html') {
        throw new CustomException('HTML파일을 첨부해주세요.');
      } else {
        const param = {
          termsCategory: selectType.value,
        };
        const formData = new FormData();
        const json = JSON.stringify(param);
        const blob = new Blob([json], { type: 'application/json' });
        formData.append('dto', blob);
        formData.append('file', selectFile);
        const { data } = await saveTerm(formData);
        CustomSwal.fire({
          title: 'success',
          text: '약관 등록이 완료되었습니다.',
          finally: () => {
            goTerms();
          },
        });
      }
    } catch (error) {
      handleError(error);
    }
    target.disabled = false;
  };

  const handleSelectChange = selectedOption => {
    setSelectType(selectedOption);
  };

  const handleFileChange = e => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();

      reader.onload = event => {
        setSelectFile(e.target.files[0]);
        setFileNm(e.target.files[0].name);
        e.target.value = null;
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const initOption = () => {
    setOptions(
      Object.values(TERMS_TYPE)
        .filter(item => {
          return item.code !== '';
        })
        .map(value => ({
          value: value.code,
          label: value.desc,
        })),
    );
  };

  useEffect(() => {
    initOption();
  }, []);
  return (
    <main id="sys-Register">
      <Header title="QNA" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['시스템 관리', '약관 관리', '등록']}
          pathList={('', [RouterPath.termList])}
        />
        <div className="title">
          <p className="title-text">약관 등록</p>
        </div>
        <article className="menu-article">
          <div className="align-left">
            <div className="add-div">
              <p>약관 유형</p>
              <CustomSelect
                className="max-200"
                options={options}
                onChange={handleSelectChange}
                placeholder="유형선택"
              />
            </div>
            <div className="add-div">
              <p>파일첨부</p>
              <div
                style={{
                  display: 'grid',
                  alignItems: 'center',
                  gridTemplateColumns: '1fr 85px',
                  gap: '8px',
                }}
              >
                <Form.Control
                  type="text"
                  placeholder="파일을 첨부해주세요."
                  value={fileNm}
                  onChange={e => {
                    e.target.value = fileNm;
                  }}
                />
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    file.current?.click();
                  }}
                >
                  파일첨부
                </Button>
                <Form.Control
                  style={{ display: 'none' }}
                  ref={file}
                  type="file"
                  accept=".html"
                  onChange={handleFileChange}
                />
              </div>
              <span
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#525252',
                }}
              >
                *html 파일을 업로드해주세요.
              </span>
            </div>
            <div style={{ textAlign: 'right' }}>
              <button type="button" className="cancle-btn" onClick={goTerms}>
                취소
              </button>
              <button
                type="button"
                className="register"
                onClick={e => {
                  CustomSwal.fire({
                    title: '약관 등록',
                    text: '약관을 등록하시겠습니까?',
                    then: () => {
                      postTerm(e);
                    },
                    cancel: true,
                  });
                }}
              >
                등록
              </button>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
