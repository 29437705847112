/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Header, CustomPagination } from '@components';
import { Container, Table, Form, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import { CustomNavigator, CustomSelect } from '../../components';
import { getReviewList, getReviewType } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

// ===================================================================
// [ 게시물 관리 > 치료후기 관리 ]
// ===================================================================
export default React.memo(function ReviewList(props) {
  const { page } = useParams();
  const { state } = useLocation();
  const initialSearchType = [{ value: '', label: '전체' }];
  const refresh = state?.params.refresh || false;
  const navigate = useNavigate();

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [reviewList, setReviewList] = useState([]);
  const [pageCnt, setPageCnt] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [searchingNow, setSearchingNow] = useState(false);
  const [searchTypes, setSearchTypes] = useState(initialSearchType);
  const [searchType, setSearchType] = useState(searchTypes[0].value);

  const searchedKeyword = state?.params.keyword || '';
  const searchedSearchType = state?.params.categoryCode || searchTypes[0];
  const initState = () => {
    setSearchType(searchedSearchType);
    setKeyword(searchedKeyword);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 검색 유형 선택
  const searchTypeChangeHandler = selectedOption => {
    setSearchType(selectedOption);
  };

  // 검색 유형 값
  const findSearchType = type => {
    return searchTypes.find(item => item.value === type);
  };

  // 페이징
  const nextPage = pageNum => {
    const params = {
      categoryCode: searchedSearchType.value,
      keyword: searchedKeyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.reviewList}/${pageNum}`, { state: { params } });
  };

  // 검색 핸들러
  const searching = () => {
    if (searchingNow) return;
    setSearchingNow(true);
    const params = {
      categoryCode: searchType,
      keyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.reviewList}/1`, { state: { params } });
  };

  // ===================================================================
  // [ API ] 치료후기 유형조회
  // ===================================================================
  const getType = async () => {
    try {
      const { data } = await getReviewType();
      const info = data.data;

      setSearchTypes(prevSearchTypes => [
        ...prevSearchTypes,
        ...info.map(item => ({
          value: item.subCode,
          label: item.codeName,
        })),
      ]);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 치료후기 리스트 불러오기
  // ===================================================================
  const getList = async () => {
    try {
      const params = {
        page,
        size: 10,
        categoryCode: searchedSearchType.value,
        keyword: searchedKeyword,
      };

      const { data } = await getReviewList(params);
      const info = data.data;

      setReviewList([...info.list]);
      setPageCnt(info.totalPages);
    } catch (error) {
      handleError(error);
    }
    setSearchingNow(false);
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    initState();
    getList();
  }, [page, refresh]);

  useEffect(() => {
    getType();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="review">
      <Header title="치료후기" />
      <Container className="contents">
        <CustomNavigator pageNameList={['치료후기 관리']} />
        <div className="title">
          <p className="title-text">치료후기 관리</p>
          <Button onClick={() => navigate(RouterPath.reviewRegister)}>
            후기등록
          </Button>
        </div>
        <article className="menu-top-article">
          {/* 유형 선택칸 */}
          <div className="grid-top-box">
            <p>유형</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomSelect
                className="mw-160"
                options={searchTypes}
                value={findSearchType(searchType)}
                onChange={searchTypeChangeHandler}
              />
            </div>
          </div>
          {/* 검색어 입력칸 */}
          <div className="grid-top-box">
            <p>검색어</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Form.Control
                type="text"
                placeholder="검색어를 입력해주세요"
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                value={keyword || ''}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    searching();
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="mt-3"
          >
            {/* 검색 btn */}
            <Button onClick={searching}>검색</Button>
          </div>
        </article>
        <article className="menu-article">
          {/* 치료후기 리스트 목록 */}
          <Table className="menulists table-hover">
            <colgroup>
              <col width={100} />
              <col width={200} />
              <col width={100} />
              <col width={100} />
            </colgroup>
            <thead className="table-tr">
              <tr>
                <th>후기유형</th>
                <th>제목</th>
                <th>작성자</th>
                <th>등록일</th>
              </tr>
            </thead>
            <tbody>
              {reviewList.length > 0 ? (
                reviewList.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() =>
                      navigate(`${RouterPath.reviewDetail}/${item.reviewIdx}`)
                    }
                  >
                    <td className="td-text-detail">{item.categoryName}</td>
                    <td className="td-text-detail">{item.reviewTitle}</td>
                    <td className="td-text-detail">{item.adminName}</td>
                    <td>{format(new Date(item.regDate), 'yyyy-MM-dd')}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="no-data" colSpan={4}>
                    치료후기가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* 페이징 */}
          <CustomPagination
            pageCount={pageCnt}
            pageNum={page}
            setPage={nextPage}
          />
        </article>
      </Container>
    </main>
  );
});
