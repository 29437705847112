import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// 레이아웃
import { useSelector } from 'react-redux';
import { RouterPath } from '../common';
import Layout from './Layout';
import AdminRouters from './AdminRouters';
import Utils from '../utils/Utils';
import SignIn from '../pages/Auth/SignIn';
import Error from '../pages/Errors';

function Router() {
  const isAuthorized = useSelector(state => {
    return {
      ...state.auth,
      isLogin: Utils.decrypt(state.auth.isLogin),
    };
  });

  switch (isAuthorized.isLogin) {
    case 'SUCCESS':
      return <AdminRouters firstMenuUrl={isAuthorized.firstMenuUrl} />;
    default:
      return (
        <Routes>
          <Route
            path={RouterPath.slash}
            element={<Navigate to={RouterPath.signin} />}
          />
          <Route
            path={RouterPath.signin}
            element={
              <Layout>
                <SignIn />
              </Layout>
            }
          />
          <Route
            path="*"
            element={
              <Layout>
                <Error />
              </Layout>
            }
          />
        </Routes>
      );
  }
}

export default Router;
