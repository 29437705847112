import React from 'react';
import { Button } from 'react-bootstrap';
import { RouterPath } from '@common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default React.memo(function OnlyErrors(props) {
  const auth = useSelector(state => {
    return state.auth;
  });
  const navigate = useNavigate();

  return (
    <main id="errors">
      <h2>404</h2>
      <p className="text-gray">페이지를 찾을 수 없습니다.</p>
      <Button
        variant="primary"
        size="lg"
        onClick={() => {
          navigate(RouterPath.slash);
        }}
        className="mt-3"
      >
        홈으로 돌아가기
      </Button>
    </main>
  );
});
