/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Header, CustomPagination, CustomNavigator } from '@components';
import { Container, Table, Image, Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { CustomSelect } from '../../components';
import IsYN from '../../common/constants/IsYN';
import {
  getCodeList,
  getSubCodeList,
  modifyCode,
  modifySubCode,
  saveCode,
  saveSubCode,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import CustomSwal from '../../components/CustomSwal';
import { RouterPath } from '../../common';

export default React.memo(function CodeSet(props) {
  /**
   * state
   */
  const { state } = useLocation();
  const navigate = useNavigate();
  const codeSaveRef = useRef();
  const subCodeSaveRef = useRef();
  const refresh = state?.params.refresh || false;

  const searchedKeyword = state?.params.keyword || '';
  const searchedSearchType = state?.params.searchType || '';

  // searching
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword] = useState('');

  const [codeList, setCodeList] = useState([]);
  const [subCodeList, setSubCodeList] = useState([]);
  const [searchingNow, setSearchingNow] = useState(false);

  // code save/modify
  const [codeSaveVisible, setCodeSaveVisible] = useState(false);
  const [saveCodeValues, setSaveCodeValues] = useState({});
  const [editCodeValues, setEditCodeValues] = useState({});
  const [selectedCode, setSelectedCode] = useState();
  const [editCode, setEditCode] = useState();

  // subCode save/modify
  const [subCodeSaveVisible, setSubCodeSaveVisible] = useState(false);
  const [saveSubCodeValues, setSaveSubCodeValues] = useState({});
  const [editSubCodeValues, setEditSubCodeValues] = useState({});
  const [editSubCode, setEditSubCode] = useState();

  const useYnOptions = [
    {
      value: IsYN.Y,
      label: IsYN.Y,
    },
    {
      value: IsYN.N,
      label: IsYN.N,
    },
  ];

  /**
   * api
   */
  // list
  const getList = async () => {
    try {
      const params = {
        useYn: searchedSearchType,
        keyword: searchedKeyword,
      };

      const { data } = await getCodeList(params);
      setCodeList(data.data);
    } catch (error) {
      handleError(error);
    }
    setSearchingNow(false);
  };

  const getSubList = async () => {
    try {
      const { data } = await getSubCodeList(selectedCode);
      setSubCodeList(data.data);
    } catch (error) {
      handleError(error);
    }
  };

  // save
  const registerCode = async e => {
    const { target } = e;
    target.disabled = true;
    try {
      const params = {
        ...saveCodeValues,
      };
      const { data } = await saveCode(params);

      CustomSwal.fire({
        title: 'SUCCESS',
        text: '코드가 등록되었습니다.',
        finally: () => {
          refreshCode();
        },
      });
    } catch (error) {
      handleError(error);
    }
    target.disabled = false;
  };
  const registerSubCode = async e => {
    const { target } = e;
    target.disabled = true;
    try {
      const params = {
        mainCode: selectedCode,
        ...saveSubCodeValues,
      };
      const { data } = await saveSubCode(params);

      CustomSwal.fire({
        title: 'SUCCESS',
        text: '상세코드가 등록되었습니다.',
        finally: () => {
          refreshSubCode();
        },
      });
    } catch (error) {
      handleError(error);
    }
    target.disabled = false;
  };

  // modify
  const patchCode = async e => {
    const target = e.currentTarget;
    target.disabled = true;
    try {
      const params = {
        ...editCodeValues,
      };

      const { data } = await modifyCode(params);

      CustomSwal.fire({
        title: 'SUCCESS',
        text: '코드가 수정되었습니다.',
        finally: () => {
          refreshCode();
        },
      });
    } catch (error) {
      handleError(error);
    }
    target.disabled = false;
  };
  const patchSubCode = async e => {
    const target = e.currentTarget;
    target.disabled = true;
    try {
      const params = {
        ...editSubCodeValues,
      };
      console.log(params);
      const { data } = await modifySubCode(params);

      CustomSwal.fire({
        title: 'SUCCESS',
        text: '상세코드가 수정되었습니다.',
        finally: () => {
          refreshSubCode();
        },
      });
    } catch (error) {
      handleError(error);
    }
    target.disabled = false;
  };

  /**
   * event
   */
  const searching = async () => {
    if (searchingNow) return;
    setSearchingNow(true);
    const params = {
      searchType,
      keyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.sysCode}`, { state: { params } });
  };
  const refreshCode = async () => {
    codeInit();
    getList();
  };

  const refreshSubCode = async () => {
    subCodeInit();
    getSubList();
  };

  const showCodeSave = () => {
    setSaveCodeValues({ useYn: IsYN.Y });
    setCodeSaveVisible(true);
    setTimeout(() => {
      scrollToCodeSaveRef();
    }, 0);
  };
  const closeCodeSave = () => {
    setCodeSaveVisible(false);
  };

  const showSubCodeSave = () => {
    setSaveSubCodeValues({ useYn: IsYN.Y });
    setSubCodeSaveVisible(true);
    setTimeout(() => {
      scrollToSubCodeSaveRef();
    }, 0);
  };
  const closeSubCodeSave = () => {
    setSubCodeSaveVisible(false);
  };

  /**
   * help function
   */
  const codeInit = () => {
    setCodeSaveVisible(false);
    setSubCodeSaveVisible(false);
    setSelectedCode(null);
    setEditCode(null);
    setEditSubCode(null);
    setSubCodeList([]);
  };
  const subCodeInit = () => {
    setSubCodeSaveVisible(false);
    setEditSubCode(null);
    setSubCodeList([]);
  };
  const findYnOptions = itemYnValue => {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    const find = useYnOptions.find(item => item.value === itemYnValue);
    return find;
  };

  const scrollToCodeSaveRef = () => {
    // eslint-disable-next-line no-unused-expressions
    if (codeSaveRef.current) {
      codeSaveRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };
  const scrollToSubCodeSaveRef = () => {
    // eslint-disable-next-line no-unused-expressions
    if (subCodeSaveRef.current) {
      subCodeSaveRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  const initState = () => {
    codeInit();
    setSearchType(searchedSearchType);
    setKeyword(searchedKeyword);
  };

  /**
   * useEffect
   */
  useEffect(() => {
    initState();
    getList();
  }, [refresh]);

  useEffect(() => {
    if (selectedCode) {
      setSubCodeSaveVisible(false);
      getSubList();
    }
  }, [selectedCode]);

  return (
    <main id="codeset">
      <Header title="코드 관리" />
      <Container className="contents">
        <CustomNavigator pageNameList={['시스템 관리', '코드 관리']} />
        <div className="title">
          <p className="title-text">코드 관리</p>
        </div>
        <article className="menu-top-article">
          <div className="grid-top-box">
            <p>사용여부</p>
            <div>
              {['radio'].map(type => (
                <div
                  key={`radio-${type}`}
                  style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
                >
                  <Form.Check
                    type={type}
                    id={`radio1-${type}`}
                    name="search_type"
                    label="전체"
                    checked={searchType === ''}
                    onChange={e => {
                      if (e.target.checked) setSearchType('');
                    }}
                  />
                  <Form.Check
                    type={type}
                    id={`radio2-${type}`}
                    name="search_type"
                    label="사용"
                    checked={searchType === IsYN.Y}
                    onChange={e => {
                      if (e.target.checked) setSearchType(IsYN.Y);
                    }}
                  />
                  <Form.Check
                    type={type}
                    id={`radio3-${type}`}
                    name="search_type"
                    label="미사용"
                    checked={searchType === IsYN.N}
                    onChange={e => {
                      if (e.target.checked) setSearchType(IsYN.N);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="grid-top-box">
            <p>검색어</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Form.Control
                type="text"
                placeholder="검색어를 입력해주세요"
                value={keyword || ''}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    searching();
                  }
                }}
              />
              <Button onClick={searching}>검색</Button>
            </div>
          </div>
        </article>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '16px',
            width: '100%',
          }}
        >
          <article className="menu-article">
            <div className="add-btn-title">
              <h6>대분류 코드</h6>
              <Button onClick={showCodeSave}>
                <i className="material-icons">add</i>
              </Button>
            </div>
            <div
              style={{ overflowY: 'scroll', height: '640px', width: '100%' }}
            >
              <Table className="menulists table-hover">
                <colgroup>
                  <col width={150} />
                  <col width={150} />
                  <col width={150} />
                  <col width={95} />
                  <col width={50} />
                </colgroup>

                <thead
                  className="table-tr"
                  style={{ position: 'sticky', top: '-1px' }}
                >
                  <tr>
                    <th>코드</th>
                    <th>코드명</th>
                    <th>설명</th>
                    <th className="th-center">사용여부</th>
                    <th aria-label="emty1"> </th>
                  </tr>
                </thead>

                <tbody>
                  {!codeList || codeList.length === 0 ? (
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          textAlign: 'center',
                          borderBottom: '0',
                          color: '#818185',
                          fontWeight: '500',
                        }}
                      >
                        데이터가 없습니다.
                      </td>
                    </tr>
                  ) : (
                    codeList.map((item, index) => (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <Fragment key={index}>
                        {editCode === item.mainCode ? (
                          <tr className="edit-tr" key={index}>
                            <td aria-label="control1">
                              <div>
                                <Form.Control
                                  placeholder="코드 입력"
                                  style={{
                                    width: '100%',
                                    marginBottom: '39px',
                                  }}
                                  disabled
                                  value={editCodeValues.mainCode || ''}
                                />
                              </div>
                            </td>

                            <td aria-label="control1">
                              <div>
                                <Form.Control
                                  placeholder="코드명 입력"
                                  style={{
                                    width: '100%',
                                    marginBottom: '39px',
                                  }}
                                  value={editCodeValues.codeName || ''}
                                  onChange={e => {
                                    setEditCodeValues(prev => {
                                      return {
                                        ...prev,
                                        codeName: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </td>
                            <td aria-label="control1">
                              <div>
                                <Form.Control
                                  placeholder="설명 입력"
                                  style={{
                                    width: '100%',
                                    marginBottom: '39px',
                                  }}
                                  value={editCodeValues.memo || ''}
                                  onChange={e => {
                                    setEditCodeValues(prev => {
                                      return {
                                        ...prev,
                                        memo: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </td>
                            <td colSpan="2">
                              <CustomSelect
                                className="mw-85"
                                options={useYnOptions}
                                onChange={e => {
                                  setEditCodeValues(prev => {
                                    return {
                                      ...prev,
                                      useYn: e.value,
                                    };
                                  });
                                }}
                                value={findYnOptions(editCodeValues.useYn)}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                  justifyContent: 'end',
                                }}
                                className="td-btn-box"
                              >
                                <Button
                                  variant="outline-primary"
                                  onClick={() => {
                                    setEditCode(null);
                                  }}
                                >
                                  취소
                                </Button>
                                <Button
                                  onClick={patchCode}
                                  disabled={
                                    !(
                                      editCodeValues.mainCode &&
                                      editCodeValues.codeName &&
                                      editCodeValues.useYn
                                    )
                                  }
                                >
                                  수정
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr
                            key={index}
                            className={
                              selectedCode === item.mainCode ? 'active' : ''
                            }
                            onClick={() => {
                              setSelectedCode(item.mainCode);
                            }}
                          >
                            <td>{item.mainCode}</td>
                            <td>{item.codeName}</td>
                            <td>{item.memo ? item.memo : '-'}</td>
                            <td className="th-center">{item.useYn}</td>
                            <td>
                              <Button
                                className="edit-btn"
                                onClick={() => {
                                  setEditCode(item.mainCode);
                                  setEditCodeValues({ ...item });
                                }}
                              >
                                수정
                              </Button>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))
                  )}
                  {codeSaveVisible && (
                    <tr className="edit-tr" ref={codeSaveRef}>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="코드 입력"
                            style={{ width: '100%', marginBottom: '39px' }}
                            value={saveCodeValues.mainCode || ''}
                            onChange={e => {
                              setSaveCodeValues(prev => {
                                return { ...prev, mainCode: e.target.value };
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="코드명 입력"
                            style={{ width: '100%', marginBottom: '39px' }}
                            value={saveCodeValues.codeName || ''}
                            onChange={e => {
                              setSaveCodeValues(prev => {
                                return {
                                  ...prev,
                                  codeName: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="설명 입력"
                            style={{ width: '100%', marginBottom: '39px' }}
                            value={saveCodeValues.memo || ''}
                            onChange={e => {
                              setSaveCodeValues(prev => {
                                return {
                                  ...prev,
                                  memo: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td colSpan="2">
                        <CustomSelect
                          className="mw-85"
                          options={useYnOptions}
                          onChange={e => {
                            setSaveCodeValues(prev => {
                              return {
                                ...prev,
                                useYn: e.value,
                              };
                            });
                          }}
                          value={findYnOptions(saveCodeValues.useYn)}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            justifyContent: 'end',
                          }}
                          className="td-btn-box"
                        >
                          <Button
                            variant="outline-primary"
                            onClick={closeCodeSave}
                          >
                            취소
                          </Button>
                          <Button
                            onClick={e => {
                              CustomSwal.fire({
                                title: '등록',
                                html: '대문류 코드를 등록하시겠습니까?',
                                then: () => {
                                  registerCode(e);
                                },
                              });
                            }}
                            disabled={
                              !(
                                saveCodeValues.mainCode &&
                                saveCodeValues.codeName &&
                                saveCodeValues.useYn
                              )
                            }
                          >
                            등록
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {/* <CustomPagination
              pageCount={pageCnt}
              pageNum={page}
              setPage={nextPage}
            /> */}
          </article>
          <article className="menu-article">
            <div className="add-btn-title">
              <h6>상세코드</h6>
              {selectedCode && (
                <Button onClick={showSubCodeSave}>
                  <i className="material-icons">add</i>
                </Button>
              )}
            </div>
            <div
              style={{ overflowY: 'scroll', height: '640px', width: '100%' }}
            >
              <Table
                className={`menulists ${
                  subCodeList.length > 0 ? 'table-hover' : ''
                }`}
              >
                <colgroup>
                  <col width={160} />
                  <col width={160} />
                  <col width={160} />
                  <col width={95} />
                  <col width={50} />
                </colgroup>
                <thead
                  className="table-tr"
                  style={{ position: 'sticky', top: '-1px' }}
                >
                  <tr>
                    <th>코드</th>
                    <th>코드명</th>
                    <th>설명</th>
                    <th className="th-center">사용여부</th>
                    <th aria-label="emty"> </th>
                  </tr>
                </thead>

                <tbody>
                  {!selectedCode && (
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          textAlign: 'center',
                          borderBottom: '0',
                          color: '#818185',
                          fontWeight: '500',
                        }}
                      >
                        대분류 코드를 선택해 주세요.
                      </td>
                    </tr>
                  )}
                  {selectedCode &&
                    (!subCodeList || subCodeList.length === 0) && (
                      <tr>
                        <td
                          colSpan="5"
                          style={{
                            textAlign: 'center',
                            borderBottom: '0',
                            color: '#818185',
                            fontWeight: '500',
                          }}
                        >
                          데이터가 없습니다.
                        </td>
                      </tr>
                    )}
                  {selectedCode &&
                    subCodeList &&
                    subCodeList.length > 0 &&
                    subCodeList.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          {editSubCode === item.subCode ? (
                            <tr className="edit-tr" key={index}>
                              <td aria-label="control1">
                                <div>
                                  <Form.Control
                                    placeholder="코드 입력"
                                    style={{
                                      width: '100%',
                                      marginBottom: '39px',
                                    }}
                                    disabled
                                    value={editSubCodeValues.subCode || ''}
                                  />
                                </div>
                              </td>
                              <td aria-label="control1">
                                <div>
                                  <Form.Control
                                    placeholder="코드명 입력"
                                    style={{
                                      width: '100%',
                                      marginBottom: '39px',
                                    }}
                                    value={editSubCodeValues.codeName || ''}
                                    onChange={e => {
                                      setEditSubCodeValues(prev => {
                                        return {
                                          ...prev,
                                          codeName: e.target.value,
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </td>
                              <td aria-label="control1">
                                <div>
                                  <Form.Control
                                    placeholder="설명 입력"
                                    style={{
                                      width: '100%',
                                      marginBottom: '39px',
                                    }}
                                    value={editSubCodeValues.memo || ''}
                                    onChange={e => {
                                      setEditSubCodeValues(prev => {
                                        return {
                                          ...prev,
                                          memo: e.target.value,
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </td>
                              <td colSpan="2">
                                <CustomSelect
                                  className="mw-85"
                                  options={useYnOptions}
                                  onChange={e => {
                                    setEditSubCodeValues(prev => {
                                      return {
                                        ...prev,
                                        useYn: e.value,
                                      };
                                    });
                                  }}
                                  value={findYnOptions(editSubCodeValues.useYn)}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    justifyContent: 'end',
                                  }}
                                  className="td-btn-box"
                                >
                                  <Button
                                    variant="outline-primary"
                                    onClick={() => {
                                      setEditSubCode(null);
                                    }}
                                  >
                                    취소
                                  </Button>
                                  <Button
                                    onClick={patchSubCode}
                                    disabled={
                                      !(
                                        editSubCodeValues.subCode &&
                                        editSubCodeValues.codeName &&
                                        editSubCodeValues.useYn
                                      )
                                    }
                                  >
                                    수정
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr key={index}>
                              <td>{item.subCode}</td>
                              <td>{item.codeName}</td>
                              <td>{item.memo ? item.memo : '-'}</td>
                              <td className="th-center">{item.useYn}</td>
                              <td>
                                <Button
                                  className="edit-btn"
                                  onClick={() => {
                                    setEditSubCode(item.subCode);
                                    setEditSubCodeValues({ ...item });
                                  }}
                                >
                                  수정
                                </Button>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                  {subCodeSaveVisible && (
                    <tr className="edit-tr" ref={subCodeSaveRef}>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="코드 입력"
                            style={{ width: '100%', marginBottom: '39px' }}
                            value={saveSubCodeValues.subCode || ''}
                            onChange={e => {
                              setSaveSubCodeValues(prev => {
                                return { ...prev, subCode: e.target.value };
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="코드명 입력"
                            style={{ width: '100%', marginBottom: '39px' }}
                            value={saveSubCodeValues.codeName || ''}
                            onChange={e => {
                              setSaveSubCodeValues(prev => {
                                return {
                                  ...prev,
                                  codeName: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td aria-label="control1">
                        <div>
                          <Form.Control
                            placeholder="설명 입력"
                            style={{ width: '100%', marginBottom: '39px' }}
                            value={saveSubCodeValues.memo || ''}
                            onChange={e => {
                              setSaveSubCodeValues(prev => {
                                return {
                                  ...prev,
                                  memo: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td colSpan="2">
                        <CustomSelect
                          className="mw-85"
                          options={useYnOptions}
                          onChange={e => {
                            setSaveSubCodeValues(prev => {
                              return {
                                ...prev,
                                useYn: e.value,
                              };
                            });
                          }}
                          value={findYnOptions(saveSubCodeValues.useYn)}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            justifyContent: 'end',
                          }}
                          className="td-btn-box"
                        >
                          <Button
                            variant="outline-primary"
                            onClick={closeSubCodeSave}
                          >
                            취소
                          </Button>
                          <Button
                            onClick={e => {
                              CustomSwal.fire({
                                title: '등록',
                                html: '상세코드를 등록하시겠습니까?',
                                then: () => {
                                  registerSubCode(e);
                                },
                              });
                            }}
                            disabled={
                              !(
                                saveSubCodeValues.subCode &&
                                saveSubCodeValues.codeName &&
                                saveSubCodeValues.useYn
                              )
                            }
                          >
                            등록
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {/* <CustomPagination /> */}
          </article>
        </div>
      </Container>
    </main>
  );
});
