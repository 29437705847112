import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Router from './router/Router';
import Loading from './components/Loading';

function App() {
  // const isAuthorized = localStorage.getItem('auth') != null;
  // redux store를 통해 로딩 여부 획득
  const loadingShow = useSelector(state => {
    return state.loading?.loadingShow;
  });

  useEffect(() => {
    // WalletUtils.connect();
  });

  return (
    <div className="App">
      {loadingShow && <Loading />}
      <Router />
    </div>
  );
}

export default React.memo(App);
