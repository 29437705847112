const SEARCH_TYPE = {
  LOGIN_ID: 'LOGIN_ID',
  CONTENT_ID: 'CONTENT_ID',
  NAME: 'NAME',
  SHOP: 'SHOP',
  PHONE: 'PHONE',
  ACCESS_IP: 'ACCESS_IP',
  ACCESS_TYPE: 'ACCESS_TYPE',
  CONTENTS: 'CONTENTS',
  TITLE: 'TITLE',
  // TODO ...
};

export default SEARCH_TYPE;
