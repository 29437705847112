const RouterPath = {
  slash: '/',
  signin: '/auth/signin',

  signin2: '/auth/signin2',
  pwdchange: '/auth/pwdchange', // 비밀번호 변경

  // 회원 관리
  userList: '/user/list',
  bookingList: '/booking/list',

  // 배너
  bannerList: '/banner/list',
  bannerRegister: '/banner/register',
  bannerEdit: '/banner/edit',

  // 팝업
  popupList: '/popup/list',
  popupRegister: '/popup/register',
  popupEdit: '/popup/edit',

  // 공지사항
  noticeList: '/notice/list',
  noticeDetail: '/notice/detail',
  noticeRegister: '/notice/register',
  noticeEdit: '/notice/edit',

  // QNA
  qnaList: '/qna/list',
  qnaDetail: '/qna/detail',
  qnaRegister: '/qna/register',
  qnaEdit: '/qna/edit',

  // 치료후기
  reviewList: '/review/list',
  reviewDetail: '/review/detail',
  reviewRegister: '/review/register',
  reviewEdit: '/review/edit',

  // 코드관리
  menuList: '/system/menu/list',
  menuPermission: '/system/menu-permission',
  termList: '/system/term/list',
  termRegister: '/system/term/register',
  sysCode: '/system/sys-code',
  mngLog: '/system/mng-log',

  // 에러
  Errors: '/errors',
};

export default RouterPath;
