/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Header, CustomPagination, CustomNavigator } from '@components';
import { Container, Table, Image, Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { RouterPath, Utils } from '../../common';
import 'react-quill/dist/quill.snow.css';
import { CustomSelect } from '../../components';
import TERMS_TYPE from '../../common/constants/TermsType';
import { handleError } from '../../utils/HandleError';
import { getDownloadFile, getTermList } from '../../api/RestAPI';

export default React.memo(function SysTerms(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const termsType = [
    { value: '', label: '전체' },
    {
      value: TERMS_TYPE.TERMS_SERVICE.code,
      label: TERMS_TYPE.TERMS_SERVICE.desc,
    },
    {
      value: TERMS_TYPE.TERMS_PRIVATE.code,
      label: TERMS_TYPE.TERMS_PRIVATE.desc,
    },
    {
      value: TERMS_TYPE.TERMS_LOCATE.code,
      label: TERMS_TYPE.TERMS_LOCATE.desc,
    },
    {
      value: TERMS_TYPE.TERMS_OPENSOURCE.code,
      label: TERMS_TYPE.TERMS_OPENSOURCE.desc,
    },
    {
      value: TERMS_TYPE.TERMS_AD.code,
      label: TERMS_TYPE.TERMS_AD.desc,
    },
  ];

  // searched
  const searchedTermType = state?.params.termType || null;
  const refresh = state?.params.refresh || false;

  // searching
  const { page } = useParams();
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [selectType, setSelectType] = useState(null);
  const [searchingNow, setSearchingNow] = useState(false);

  const [termList, setTermList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const goRigister = () => {
    navigate(`${RouterPath.termRegister}`);
  };

  const getList = async () => {
    try {
      const params = {
        page,
        size,
        termsCategory: searchedTermType?.value,
      };
      const { data } = await getTermList(params);
      console.log(data.data);

      setTermList(data.data.list);
      setTotalPage(data.data.totalPages);
    } catch (error) {
      handleError(error);
    }
    setSearchingNow(false);
  };

  // ===================================================================
  // [ API ] 파일 다운로드
  // ===================================================================
  const downloadFile = async (fileNm, fileUrl) => {
    try {
      const params = {
        filePath: fileUrl,
      };
      const response = await getDownloadFile(params);

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileNm;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleError(error);
    }
  };

  const searching = () => {
    if (searchingNow) return;
    setSearchingNow(true);
    const params = {
      termType: selectType,
      refresh: !refresh,
    };
    navigate(`${RouterPath.termList}/1`, { state: { params } });
  };

  const nextPage = pageNum => {
    const params = {
      termType: searchedTermType,
      refresh: !refresh,
    };
    navigate(`${RouterPath.termList}/${pageNum}`, { state: { params } });
  };

  const handleSelectChange = selectedOption => {
    setSelectType(selectedOption);
  };
  const initState = () => {
    setSelectType(searchedTermType);
  };

  useEffect(() => {
    initState();
    getList();
  }, [page, refresh]);
  return (
    <main id="sys-terms">
      <Header title="약관 관리" />
      <Container className="contents">
        <CustomNavigator pageNameList={['시스템 관리', '약관 관리']} />
        <div className="title">
          <p className="title-text">약관 관리</p>
          <Button onClick={goRigister}>약관등록</Button>
        </div>
        <article className="menu-top-article">
          <div className="grid-top-box">
            <p>구분</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomSelect
                className="mw-160"
                value={selectType || termsType[0]}
                options={termsType}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="mt-3"
          >
            <Button onClick={searching}>검색</Button>
          </div>
        </article>
        <article className="menu-article">
          <Table className="menulists table-hover">
            <colgroup>
              <col width={100} />
              <col width={200} />
              <col width={100} />
              <col width={100} />
            </colgroup>
            <thead className="table-tr">
              <tr>
                <th>구분</th>
                <th>파일명</th>
                <th>작성자</th>
                <th>등록일시</th>
              </tr>
            </thead>
            <tbody>
              {termList.map((item, index) => (
                <tr key={index}>
                  <td>
                    {TERMS_TYPE[item.termsCategory]?.desc
                      ? TERMS_TYPE[item.termsCategory]?.desc
                      : '-'}
                  </td>
                  <td
                    onClick={() => {
                      downloadFile(item.termsFileName, item.termsFilePath);
                    }}
                    style={{
                      color: '#326BFF',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    className="td-text-detail"
                  >
                    {item.termsFileName}
                  </td>
                  <td>{item.adminName}</td>
                  <td>{format(new Date(item.regDate), 'yyyy-MM-dd HH:mm')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={totalPage}
            pageNum={page}
            setPage={nextPage}
          />
        </article>
      </Container>
    </main>
  );
});
