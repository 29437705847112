/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

export const LOADING_TYPE = {
  NORMAL: 'NORMAL',
  WITH_MSG: 'WITH_MSG',
};

const initialState = {
  loadingShow: false,
  msg: '',
  type: LOADING_TYPE.NORMAL,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    show: (state, action) => {
      state.loadingShow = true;
      state.msg = action.payload.msg;
      state.type = action.payload.type;
    },
    setMsg: (state, action) => {
      state.msg = action.payload.msg;
      state.type = LOADING_TYPE.WITH_MSG;
    },
    hide: state => {
      state.loadingShow = false;
      state.msg = initialState.msg;
      state.type = initialState.type;
    },
  },
});

// Action creators are generated for each case reducer function
export const loadingAction = loadingSlice.actions;

const loadingPersistConfig = {
  key: 'loading',
  storage,
  whitelist: [], // `address`만 저장하도록 지정
};

export default persistReducer(loadingPersistConfig, loadingSlice.reducer);
