import api from '../utils/Api';

const API_VERSION = 'api/v1';

const API_AUTH = `${API_VERSION}/auth`;
const API_COMMON = `${API_VERSION}/common`;
const API_BOOKING = `${API_VERSION}/booking`;
const API_USER = `${API_VERSION}/users`;
const API_BANNER = `${API_VERSION}/board-banner`;
const API_NOTICE = `${API_VERSION}/board-notice`;
const API_QNA = `${API_VERSION}/board-qna`;
const API_REVIEW = `${API_VERSION}/board-review`;
const API_POPUP = `${API_VERSION}/board-popup`;
const API_CODE = `${API_VERSION}/config-code/codes`;
const API_SUB_CODE = `${API_VERSION}/config-code/sub-codes`;
const API_MENU = `${API_VERSION}/config-menu/menus`;
const API_MENU_PERM = `${API_VERSION}/config-perm`;
const API_TERM = `${API_VERSION}/config-terms`;
const API_LOG = `${API_VERSION}/config-adm-log/logs`;

export const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// -----------------------------
// [ COMMON ]
// -----------------------------
// 파일 다운로드
export const getDownloadFile = data => {
  return api.get(`${API_COMMON}/files/download`, {
    params: data,
    responseType: 'blob',
  });
};

// 공지사항 유형조회
export const getNoticeType = () => {
  return api.get(`${API_COMMON}/notice-types`);
};

// 치료후기 유형조회
export const getReviewType = () => {
  return api.get(`${API_COMMON}/review-types`);
};

// 컨텐츠 유형조회
export const getContentsType = () => {
  return api.get(`${API_COMMON}/contents-types`);
};

// -----------------------------
// [ AUTH ]
// -----------------------------

// 로그인 - GDHA_IF_001
export const login = data => {
  return api.post(`${API_AUTH}/login`, data);
};

// 패스워드 변경 - GDHA_IF_003
export const modifyPassword = data => {
  return api.patch(`${API_AUTH}/password`, data);
};

// 관리자별 메뉴 조회 - GDHA_IF_004
export const getAdmiMenuList = data => {
  return api.get(`${API_AUTH}/menus`, data);
};

// -----------------------------
// [ BOOKING ]
// -----------------------------

// 회원 리스트 조회 - GDHA_IF_101
export const getBookingList = data => {
  return api.get(`${API_BOOKING}`, { params: data });
};

// -----------------------------
// [ USER ]
// -----------------------------

// 회원 리스트 조회 - GDHA_IF_101
export const getUserList = data => {
  return api.get(`${API_USER}`, { params: data });
};

// 회원 탈퇴 조치 - GDHA_IF_103
export const cancelUser = userIdx => {
  return api.delete(`${API_USER}/${userIdx}`);
};

// -----------------------------
// [ BANNER ]
// -----------------------------

// 배너 리스트 조회 - GDHA_IF_401
export const getBannerList = () => {
  return api.get(`${API_BANNER}`);
};

// 배너 상세 조회 - GDHA_IF_402
export const getBannerDetail = idx => {
  return api.get(`${API_BANNER}/${idx}`);
};

// 배너 등록 - GDHA_IF_403
export const saveBanner = data => {
  return api.post(`${API_BANNER}`, data, formDataConfig);
};

// 배너 수정 - GDHA_IF_404
export const modifyBanner = data => {
  return api.put(`${API_BANNER}`, data, formDataConfig);
};

// 배너 삭제 - GDHA_IF_405
export const removeBanner = idx => {
  return api.delete(`${API_BANNER}/${idx}`);
};

// 배너 순서 변경 - GDHA_IF_406
export const modifyBannerOrder = data => {
  return api.patch(`${API_BANNER}`, data);
};

// -----------------------------
// [ NOTICE ]
// -----------------------------

// 공지사항 리스트 조회 - GDHA_IF_410
export const getNoticeList = data => {
  return api.get(`${API_NOTICE}`, { params: data });
};

// 공지사항 상세 조회 - GDHA_IF_411
export const getNoticeDetail = idx => {
  return api.get(`${API_NOTICE}/${idx}`);
};

// 공지사항 등록 - GDHA_IF_412
export const saveNotice = data => {
  return api.post(`${API_NOTICE}`, data, formDataConfig);
};

// 공지사항 수정 - GDHA_IF_413
export const modifyNotice = data => {
  return api.put(`${API_NOTICE}`, data, formDataConfig);
};

// 공지사항 삭제 - GDHA_IF_414
export const removeNotice = idx => {
  return api.delete(`${API_NOTICE}/${idx}`);
};

// 공지사항 상단 고정 - GDHA_IF_415
export const onNoticeTop = idx => {
  return api.patch(`${API_NOTICE}/on/${idx}`);
};

// 공지사항 상단 고정 해제 - GDHA_IF_416
export const offNoticeTop = idx => {
  return api.patch(`${API_NOTICE}/off/${idx}`);
};

// -----------------------------
// [ QNA ]
// -----------------------------

// Qna 리스트 조회 - GDHA_IF_420
export const getQnaList = data => {
  return api.get(`${API_QNA}`, { params: data });
};

// Qna 상세 조회 - GDHA_IF_421
export const getQnaDetail = idx => {
  return api.get(`${API_QNA}/${idx}`);
};

// Qna 등록 - GDHA_IF_422
export const saveQna = data => {
  return api.post(`${API_QNA}`, data);
};

// Qna 수정 - GDHA_IF_423
export const modifyQna = data => {
  return api.put(`${API_QNA}`, data);
};

// Qna 삭제 - GDHA_IF_424
export const removeQna = idx => {
  return api.delete(`${API_QNA}/${idx}`);
};

// Qna 순서 변경 - GDHA_IF_425
export const modifyQnaOrder = data => {
  return api.patch(`${API_QNA}`, data);
};

// Qna 순서 변경 - GDHA_IF_426
export const repostQna = idx => {
  return api.patch(`${API_QNA}/${idx}`);
};

// -----------------------------
// [ REVIEW ]
// -----------------------------

// 치료후기 리스트 조회 - GDHA_IF_430
export const getReviewList = data => {
  return api.get(`${API_REVIEW}`, { params: data });
};

// 치료후기 상세 조회 - GDHA_IF_431
export const getReviewDetail = idx => {
  return api.get(`${API_REVIEW}/${idx}`);
};

// 치료후기 등록 - GDHA_IF_432
export const saveReview = data => {
  return api.post(`${API_REVIEW}`, data, formDataConfig);
};

// 치료후기 수정 - GDHA_IF_433
export const modifyReview = data => {
  return api.put(`${API_REVIEW}`, data, formDataConfig);
};

// 치료후기 삭제 - GDHA_IF_434
export const removeReview = idx => {
  return api.delete(`${API_REVIEW}/${idx}`);
};

// -----------------------------
// [ POPUP ]
// -----------------------------

// 팝업 리스트 조회 - GDHA_IF_440
export const getPopupList = data => {
  return api.get(`${API_POPUP}`, { params: data });
};

// 팝업 상세 조회 - GDHA_IF_441
export const getPopupDetail = idx => {
  return api.get(`${API_POPUP}/${idx}`);
};

// 팝업 등록 - GDHA_IF_442
export const savePopup = data => {
  return api.post(`${API_POPUP}`, data, formDataConfig);
};

// 팝업 수정 - GDHA_IF_443
export const modifyPopup = data => {
  return api.put(`${API_POPUP}`, data, formDataConfig);
};

// 팝업 삭제 - GDHA_IF_444
export const removePopup = idx => {
  return api.delete(`${API_POPUP}/${idx}`);
};

// 팝업 순서 변경 - GDHA_IF_445
export const modifyPopupOrder = data => {
  return api.patch(`${API_POPUP}`, data);
};

// -----------------------------
// [ SYS_CODE ]
// -----------------------------

// 메인 코드 조회 - GDHA_IF_920
export const getCodeList = data => {
  return api.get(`${API_CODE}`, { params: data });
};

// 메인 코드 등록 - GDHA_IF_922
export const saveCode = data => {
  return api.post(`${API_CODE}`, data);
};

// 메인 코드 수정 - GDHA_IF_923
export const modifyCode = data => {
  return api.put(`${API_CODE}`, data);
};

// 메인 코드 삭제 - GDHA_IF_924
export const removeCode = mainCode => {
  return api.delete(`${API_CODE}/${mainCode}`);
};

// 서브 코드 조회 - GDHA_IF_930
export const getSubCodeList = mainCode => {
  return api.get(`${API_SUB_CODE}/${mainCode}`);
};

// 서브 코드 상세 조회 - GDHA_IF_931
export const getSubCode = (mainCode, subCode) => {
  return api.get(`${API_SUB_CODE}/${mainCode}/${subCode}`);
};

// 서브 코드 등록 - GDHA_IF_932
export const saveSubCode = data => {
  return api.post(`${API_SUB_CODE}`, data);
};

// 서브 코드 수정 - GDHA_IF_933
export const modifySubCode = data => {
  return api.put(`${API_SUB_CODE}`, data);
};

// 서브 코드 삭제 - GDHA_IF_934
export const removeSubCode = (mainCode, subCode) => {
  return api.delete(`${API_SUB_CODE}/${mainCode}/${subCode}`);
};

// -----------------------------
// [ MENU ]
// -----------------------------

// 메인 코드 조회 - GDHA_IF_901
export const getMenuList = () => {
  return api.get(`${API_MENU}`);
};

// 메인 코드 등록 - GDHA_IF_902
export const saveMenu = data => {
  return api.post(`${API_MENU}`, data);
};

// 메인 코드 수정 - GDHA_IF_903
export const modifyMenu = data => {
  return api.put(`${API_MENU}`, data);
};

// 메인 코드 삭제 - GDHA_IF_904
export const removeMenu = idx => {
  return api.delete(`${API_MENU}/${idx}`);
};

// -----------------------------
// [ MENU_PERMISSION ]
// -----------------------------

// 메뉴 권한 조회 - GDHA_IF_910
export const getMenuPermList = adminGrade => {
  return api.get(`${API_MENU_PERM}/${adminGrade}`);
};

// 메뉴 권한 수정 - GDHA_IF_911
export const modifyMenuPerm = (adminGrade, data) => {
  return api.put(`${API_MENU_PERM}/${adminGrade}`, data);
};

// -----------------------------
// [ TERMS ]
// -----------------------------

// 약관 리스트 조회 - GDHA_IF_800
export const getTermList = data => {
  return api.get(`${API_TERM}`, { params: data });
};

// 약관 등록 - GDHA_IF_801
export const saveTerm = data => {
  return api.post(`${API_TERM}`, data);
};

// -----------------------------
// [ LOG ]
// -----------------------------

// 관리자 로그 조회 - GDHA_IF_990
export const getAdminLog = data => {
  return api.get(`${API_LOG}`, { params: data });
};
