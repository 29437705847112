/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from 'react';
import { Header, CustomNavigator } from '@components';
import { Container, Table, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { format } from 'date-fns';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import images from '../../assets/images';
import { handleError } from '../../utils/HandleError';
import { CustomSwal } from '../../components';
import {
  getBannerList,
  getDownloadFile,
  modifyBannerOrder,
  removeBanner,
} from '../../api/RestAPI';

export default React.memo(function BannerManage(props) {
  const navigate = useNavigate();
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [bannerList, setBannerList] = useState([]);

  // ===================================================================
  // [ Utils ]
  // ===================================================================
  const goRegister = () => {
    navigate(`${RouterPath.bannerRegister}`);
  };

  const goModify = bannerIdx => {
    navigate(`${RouterPath.bannerEdit}`, { state: { bannerIdx } });
  };

  // ===================================================================
  // [ Api ] 배너 리스트 조회
  // ===================================================================
  const getList = async () => {
    try {
      const { data } = await getBannerList();

      await Promise.all(
        data.data.map(async (item, index) => {
          const params = {
            filePath: item.bannerImagePath,
          };

          try {
            const response = await getDownloadFile(params);
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            });

            const url = window.URL.createObjectURL(blob);
            data.data[index].bannerImagePath = url;
          } catch (downloadError) {
            handleError(downloadError);
          }
        }),
      );

      setBannerList(data.data);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ Api ] 배너 순서 변경
  // ===================================================================
  const handleDragEnd = async result => {
    if (!result.destination) return;
    const items = Array.from(bannerList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setBannerList(items);
  };
  const orderParams = useRef({});
  const changeBannerOrder = async () => {
    try {
      bannerList.forEach((item, idx) => {
        orderParams.current[idx + 1] = item.bannerIdx;
      });
      const { data } = await modifyBannerOrder(orderParams.current);
      if (data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: '순서 변경 성공',
        });
      }
    } catch (error) {
      handleError(error);
      getList();
    }
  };

  // ===================================================================
  // [ Api ] 배너 삭제
  // ===================================================================
  const deleteBanner = async idx => {
    try {
      const { data } = await removeBanner(idx);
      if (data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: '배너 삭제 성공',
        });
        getList();
      }
    } catch (error) {
      handleError(error);
      getList();
    }
  };

  // ===================================================================
  // [ UseEffect ]
  // ===================================================================
  useEffect(() => {
    getList();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="banner">
      <Header title="배너 관리" />
      <Container className="contents">
        <CustomNavigator pageNameList={['배너 관리']} />
        <div className="title">
          <p className="title-text">배너 관리</p>
          <span>
            <button
              type="button"
              disabled={bannerList?.length <= 0}
              style={{ marginRight: '8px' }}
              onClick={() => {
                CustomSwal.fire({
                  title: '배너 순서 변경',
                  text: '변경된 순서를 저장하시겠습니까?',
                  then: () => {
                    changeBannerOrder();
                  },
                  cancel: true,
                });
              }}
            >
              순서 저장
            </button>
            <button type="button" onClick={() => goRegister()}>
              배너 등록
            </button>
          </span>
        </div>
        <article className="banner-article">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="bannerlists">
              {dropprovided => (
                <Table
                  className="bannerlists table-hover"
                  {...dropprovided.droppableProps}
                  ref={dropprovided.innerRef}
                >
                  <colgroup>
                    <col width={50} />
                    <col width={80} />
                    <col width={250} />
                    <col width={250} />
                    <col width={150} />
                    <col width={50} />
                    <col width={50} />
                  </colgroup>
                  <thead className="table-tr">
                    <tr>
                      <th> </th>
                      <th> </th>
                      <th>메인 텍스트</th>
                      <th>서브 텍스트</th>
                      <th className="td-center">등록일자</th>
                      <th className="td-center"> </th>
                      <th className="td-center"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bannerList?.length > 0 ? (
                      bannerList.map((item, index) => (
                        <Draggable
                          draggableId={`banner-${item.bannerIdx}`}
                          index={index}
                          key={`banner-${item.bannerIdx}`}
                        >
                          {(provided, snapshot) => {
                            return (
                              <tr
                                key={index}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <td
                                  className="td-center col-draghandle"
                                  {...provided.dragHandleProps}
                                >
                                  <Image src={images.dragHandle} />
                                </td>
                                <td>
                                  <Image
                                    className="banner-image"
                                    src={item.bannerImagePath}
                                  />
                                </td>
                                <td>{item.bannerMainText}</td>
                                <td>{item.bannerSubText}</td>
                                <td className="td-center">
                                  {item.regDate
                                    ? format(
                                        new Date(item.regDate),
                                        'yyyy-MM-dd',
                                      )
                                    : '-'}
                                </td>
                                <td className="td-center col-bannerModi">
                                  <button
                                    type="button"
                                    onClick={() => goModify(item.bannerIdx)}
                                  >
                                    수정
                                  </button>
                                </td>
                                <td className="td-center col-bannerModi">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      CustomSwal.fire({
                                        title: '배너 삭제',
                                        text: '배너를 삭제하시겠습니까?',
                                        then: () => {
                                          deleteBanner(item.bannerIdx);
                                        },
                                        cancel: true,
                                      });
                                    }}
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            );
                          }}
                        </Draggable>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={5}>
                          배너가 없습니다.
                        </td>
                      </tr>
                    )}
                    {dropprovided.placeholder}
                  </tbody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </article>
      </Container>
    </main>
  );
});
