/* eslint-disable prefer-const */
/* eslint-disable no-alert */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { authAction } from '@reducers/authSlice';
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import { RouterPath } from '../common/constants';
import { NetworkException } from '../common/exceptions';
import { store } from '../store';
import EfficacyException from '../common/exceptions/EfficacyException';
import Utils from './Utils';
import STORAGE_KEY from '../common/constants/StorageKey';
import { getStorage, setStorage } from '../components/AsyncStorage';

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});
api.defaults.paramsSerializer = params => {
  return qs.stringify(params, { skipNulls: true });
};

// 자동 로그인시 접속 로그 적재 API
export const accessByToken = () => {
  return api.get(`${baseURL}api/v1/auth/access-by-token`);
};

// 일정 시간이 지나면 관리자 로그 적재하는 함수
const checkAndLogAdminAccess = async () => {
  // 로컬에 저장된 로그인 시간과 현재 로그인 시간 차이 계산
  const currentDate = new Date();
  const beforeDate = new Date(await getStorage(STORAGE_KEY.LOGIN_CHECK));
  const timeDifferenceInHours = (currentDate - beforeDate) / (1000 * 60 * 60);

  // 지정한 시간 (3시간)이 지나면 관리자 로그 적재
  if (timeDifferenceInHours > 3) {
    await setStorage(STORAGE_KEY.LOGIN_CHECK, currentDate);
    await accessByToken();
  }
};

// 1. 요청 인터셉터 2개의 콜백함수
api.interceptors.request.use(
  async config => {
    // 요청 성공 직전 호출됩니다.
    // axios 설정값을 넣습니다. (사용자 정의 설정도 추가 가능)
    const originalRequest = config;

    try {
      Utils.showLoadingSpinner();
      const auth = store.dispatch(authAction.getAuth()).payload;
      if (auth) {
        if (auth.accessToken) {
          originalRequest.headers.Authorization = `Bearer ${auth.accessToken}`;
        }
      }
    } catch (err) {
      console.log(err, 'err');
    }

    return config;
  },
  error => {
    Utils.hideLoadingSpinner();
    return Promise.reject(error);
  },
);

// 2. 응답 인터셉터 2개의 콜백함수
api.interceptors.response.use(
  response => {
    /*
        http status가 200인 경우
        응답 성공 직전 호출됩니다.
        .then() 으로 이어집니다.
        */
    checkAndLogAdminAccess();
    Utils.hideLoadingSpinner();
    return response;
  },
  async error => {
    try {
      Utils.hideLoadingSpinner();
      console.log('api error ::', error);
      const { config, response } = error;
      let result = response;

      // response이 Blob으로 왔을 경우  response data to json
      // [[
      if (
        response.data instanceof Blob &&
        response.data.type === 'application/json'
      ) {
        const responseData = await response.data.text();
        const responseJson =
          typeof responseData === 'string'
            ? JSON.parse(responseData)
            : responseData;
        result = responseJson;
      }
      // ]]

      const originalRequest = config;

      const errorMessage = result.data.message;

      // 토큰 유무 확인 > 로그인 이동
      if (result.status === 401) {
        // 토큰 기간 만료 시 Refresh 토큰으로 Access 발급 후 api 재요청
        if (String(result.data.code) === '1001' && !originalRequest._retry) {
          // alert('token expired');
          const auth = store.dispatch(authAction.getAuth()).payload;
          if (!auth) {
            alert('api error!!!!!');
            store.dispatch(authAction.removeAuth());
            window.location.href = RouterPath.signin;
          }
          originalRequest._retry = true;
          // Refresh 토큰
          axios.defaults.headers.common.Authorization = `Bearer ${auth.refreshToken}`;

          // Access 토큰 재발급
          try {
            const { data } = await axios.get(
              `${baseURL}api/v1/auth/refresh-token`,
            );
            const { accessToken } = data.data;
            const newAuth = {
              ...auth,
              accessToken,
              isLogin: Utils.encrypt('SUCCESS'),
            };

            store.dispatch(authAction.setAuth(newAuth));
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            return api(originalRequest);
          } catch (err) {
            if (err.response) {
              // alert('token refresh and api recall but error occured');

              // 로그아웃
              const {
                response: {
                  data: { code, msg },
                },
              } = err;
              store.dispatch(authAction.removeAuth());
              setStorage(STORAGE_KEY.LOGIN_CHECK, '');
              window.location.href = RouterPath.signin;
              delete axios.defaults.headers.common.Authorization;
              delete api.defaults.headers.common.Authorization;
            } else {
              console.log('err', err);
            }
          }
        }
      }

      if (result.status === 401) {
        return Promise.reject(new EfficacyException(errorMessage));
      }

      if (result.data.intended) {
        return Promise.reject(new NetworkException(errorMessage));
      }

      // // 에러 메세지 Swal
      // CustomSwal.fire({
      //   html: errorMessage,
      //   confirmButtonText: '확인',
      //   finally: () => {
      //     if (result.status === 401) {
      //       store.dispatch(authAction.removeAuth());
      //       window.location.href = RouterPath.signin;
      //     } else {
      //       window.location.href = RouterPath.Errors;
      //     }
      //   },
      // });
      return Promise.reject(new NetworkException(errorMessage));
    } catch (err) {
      console.log(err, '-err');
      return Promise.reject(err);
    }
  },
);

export default api;
