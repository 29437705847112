/* eslint-disable consistent-return */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import CustomSidebar from '../components/CustomSidebar';

function LayoutAuth({ children, isLoging, redirect, type, ...rest }) {
  return (
    <Container fluid className="app-container">
      <div className="app-contents">
        <CustomSidebar />
        {redirect ? (
          <Navigate to={redirect} />
        ) : (
          <div className="app-contents">{children}</div>
        )}
      </div>
    </Container>
  );
}

export default React.memo(LayoutAuth);
