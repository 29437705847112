/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Header, CustomPagination, CustomNavigator } from '@components';
import { Container, Table, Image, Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import SEARCH_TYPE from '../../common/constants/SearchType';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import { CustomDatePicker, CustomSelect, CustomSwal } from '../../components';
import { getAdminLog } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

export default React.memo(function UserLog(props) {
  const navigate = useNavigate();
  const { page } = useParams();
  const { state } = useLocation();
  const searchTypes = [
    { value: SEARCH_TYPE.LOGIN_ID, label: '아이디' },
    { value: SEARCH_TYPE.ACCESS_IP, label: '접속 IP' },
    { value: SEARCH_TYPE.ACCESS_TYPE, label: '접속 브라우저' },
  ];

  // searched
  const searchedStartDate = state?.params.startDate || new Date();
  const searchedEndDate = state?.params.endDate || new Date();
  const searchedSearchType = state?.params.searchType || searchTypes[1];
  const searchedKeyword = state?.params.keyword || '';
  const refresh = state?.params.refresh || false;

  // searching
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [selectType, setSelectType] = useState(searchTypes[1]);
  const [searchingNow, setSearchingNow] = useState(false);

  const [totalPage, setTotalPage] = useState(1);
  const [logList, setLogList] = useState([]);

  const getLogs = async () => {
    try {
      const params = {
        page,
        size,
        startDate: format(searchedStartDate, 'yyyy-MM-dd'),
        endDate: format(searchedEndDate, 'yyyy-MM-dd'),
        keyword: searchedKeyword,
        searchType: searchedSearchType.value,
      };
      const { data } = await getAdminLog(params);

      setTotalPage(data.data.totalPages);
      setLogList(data.data.list);
    } catch (error) {
      handleError(error);
    }
    setSearchingNow(false);
  };

  const searching = () => {
    if (searchingNow) return;
    setSearchingNow(true);
    const params = {
      startDate: fromDate,
      endDate: toDate,
      searchType: selectType,
      keyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.mngLog}/1`, { state: { params } });
  };

  const startDateChangeHandler = date => {
    if (format(date, 'yyyy-MM-dd') > format(toDate, 'yyyy-MM-dd')) {
      CustomSwal.fire({ text: '최대 생성일 이후는 선택할 수 없습니다.' });
      return;
    }
    setFromDate(date);
  };

  const endDateChangeHandler = date => {
    if (format(date, 'yyyy-MM-dd') < format(fromDate, 'yyyy-MM-dd')) {
      CustomSwal.fire({ text: '최소 생성일 이전은 선택할 수 없습니다.' });
      return;
    }
    setToDate(date);
  };

  const nextPage = pageNum => {
    const params = {
      startDate: searchedStartDate,
      endDate: searchedEndDate,
      searchType: searchedSearchType,
      keyword: searchedKeyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.mngLog}/${pageNum}`, { state: { params } });
  };

  const handleSelectChange = selectedOption => {
    setSelectType(selectedOption);
  };
  const initState = () => {
    setFromDate(searchedStartDate);
    setToDate(searchedEndDate);
    setSelectType(searchedSearchType);
    setKeyword(searchedKeyword);
  };

  useEffect(() => {
    initState();
    getLogs();
  }, [page, refresh]);
  return (
    <main id="syslog">
      <Header title="관리자 접속 로그 관리" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['시스템 관리', '관리자 접속 로그 관리']}
        />
        <div className="title">
          <p className="title-text">관리자 접속 로그 관리</p>
        </div>
        <article className="menu-top-article">
          <div className="grid-top-box">
            <p>생성일</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomDatePicker
                maxWidth="145px;"
                value={fromDate}
                onChange={startDateChangeHandler}
              />
              ~
              <CustomDatePicker
                maxWidth="145px;"
                value={toDate}
                onChange={endDateChangeHandler}
              />
            </div>
          </div>
          <div className="grid-top-box">
            <p>검색어</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomSelect
                className="mw-110"
                value={selectType}
                options={searchTypes}
                onChange={handleSelectChange}
              />
              <Form.Control
                type="text"
                placeholder="검색어를 입력해주세요"
                value={keyword || ''}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    searching(e);
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="mt-3"
          >
            <Button onClick={searching}>검색</Button>
          </div>
        </article>
        <article className="menu-article">
          <Table className="menulists">
            <colgroup>
              <col width={50} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={150} />
            </colgroup>
            <thead className="table-tr">
              <tr>
                <th>번호</th>
                <th>아이디</th>
                <th>이름</th>
                <th>접속 IP</th>
                <th>접속 OS</th>
                <th>접속 브라우저</th>
                <th>생성일시</th>
              </tr>
            </thead>
            <tbody>
              {logList.length > 0 ? (
                logList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.logSeq}</td>
                    <td>{item.userLoginId}</td>
                    <td>{item.userName}</td>
                    <td>{item.accessIP}</td>
                    <td>{item.accessOS}</td>
                    <td>{item.accessBrowser}</td>
                    <td>{item.regDate}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={7}>
                    내역이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={totalPage}
            pageNum={page}
            setPage={nextPage}
          />
        </article>
      </Container>
    </main>
  );
});
