/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import { Header, CustomNavigator } from '@components';
import { Container, Image, Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import images from '../../assets/images';
import { RouterPath, Utils } from '../../common';
import { handleError } from '../../utils/HandleError';
import { getQnaDetail, getDownloadFile, modifyQna } from '../../api/RestAPI';
import CustomSwal from '../../components/CustomSwal';

export default React.memo(function QnaEdit(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { qnaIdx } = state;
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [qnaQuestion, setQnaQuestion] = useState('');
  const [qnaAnswer, setQnaAnswer] = useState('');

  // ===================================================================
  // [ Utils ]
  // ===================================================================
  const validateForm = () => {
    const result = {
      isValid: false,
      message: '',
    };

    result.isValid = true;
    return result;
  };

  // 리스트 페이지 이동
  const moveQnaListPage = () => {
    navigate(RouterPath.qnaList);
  };

  // ===================================================================
  // [ Api ] FAQ 상세조회
  // ===================================================================
  const qnaDetail = async () => {
    const { data } = await getQnaDetail(qnaIdx);

    if (data.code === 200) {
      try {
        setQnaAnswer(data.data.qnaAnswer);
        setQnaQuestion(data.data.qnaQuestion);
      } catch (downloadError) {
        handleError(downloadError);
      }
    }
  };

  // ===================================================================
  // [ Api ] FAQ 수정
  // ===================================================================
  const editQna = async e => {
    const { target } = e;
    try {
      // Input & File 검증
      const validationResult = validateForm();
      if (!validationResult.isValid) {
        CustomSwal.fire({
          title: '알람',
          text: validationResult.message,
        });
        return;
      }

      // JSON 파라미터
      const param = {
        qnaIdx,
        qnaQuestion,
        qnaAnswer,
      };

      // Axios
      const { data } = await modifyQna(param);

      // Return
      if (data.code === 200) {
        CustomSwal.fire({
          text: 'FAQ 수정이 완료되었습니다.',
          finally: moveQnaListPage,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    qnaDetail();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="qna-Register">
      <Header title="FAQ 수정" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['FAQ 관리', 'FAQ 수정']}
          pathList={[RouterPath.qnaList, '']}
        />
        <div className="title">
          <p className="title-text">FAQ 수정</p>
        </div>
        <article className="qna-article">
          <div className="align-left">
            <div className="add-div">
              <p>FAQ 질문</p>
              <Form.Control
                type="text"
                placeholder="FAQ 질문을 입력 해 주세요."
                value={qnaQuestion}
                onChange={e => setQnaQuestion(e.target.value)}
              />
            </div>
            <div className="add-div">
              <p>FAQ 답변</p>
              <Form.Control
                as="textarea"
                value={qnaAnswer}
                placeholder="FAQ 답변을 입력해주세요."
                style={{ height: '215px', resize: 'none' }}
                onChange={e => setQnaAnswer(e.target.value)}
              />
            </div>
            {/* 버튼 */}
            <div style={{ textAlign: 'right' }}>
              {/* 버튼 > 취소 */}
              <button
                type="button"
                className="cancle-btn"
                onClick={() => {
                  CustomSwal.fire({
                    text: '취소하시겠습니까?',
                    cancel: true,
                    then: moveQnaListPage,
                  });
                }}
              >
                취소
              </button>
              {/* 버튼 > 등록 */}
              <button
                type="button"
                className="register"
                onClick={() => {
                  CustomSwal.fire({
                    text: '수정하시겠습니까?',
                    cancel: true,
                    then: editQna,
                  });
                }}
              >
                수정
              </button>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
