// icon
import icUnChecked from './icon/checkbox_unchecked.png';
import icChecked from './icon/checkbox_checked.png';
import icDropdown from './icon/dropdown.png';
import icDropup from './icon/dropup.png';
import icCalendar from './icon/calendar.png';
import icClose from './icon/close_gray.png';
import icExcel from './icon/excel.png';
import icCancleBtn from './icon/cancle_btn.png';
import Ellipse from './icon/Ellipse.svg';
import IcCloseImg from './icon/img_close.png';
import icCalendarMonth from './icon/calendar_month.png';
import icModalCancelBtn from './icon/modal_cancel-btn.png';

import infoSideBar from './icon/sidebar_info.png';
import memberSideBar from './icon/sidebar_member.png';
import badgeSideBar from './icon/sidebar_badge.png';
import calendarSideBar from './icon/sidebar_calendar_month.png';
import inventorySideBar from './icon/sidebar_inventory2.png';
import reviewSideBar from './icon/sidebar_reviews.png';
import franchiseeSideBar from './icon/franchisee.png';
import systemSideBar from './icon/systemsidebar.png';
import boardSideBar from './icon/assignment.png';
import employmentSideBar from './icon/sidebar_employment.png';
import operateSideBar from './icon/sidebar_operate.png';
import postSideBar from './icon/sidebar_post.png';
import dataExploration from './icon/sidebar_data_exploration.png';
import contactPage from './icon/sidebar_contact_page.png';

import menuImg from './icon/menu_img.png';
import dragHandle from './icon/drag_handle.png';
import cameraAlt from './icon/camera_alt.png';

export default {
  icCalendar,
  icClose,
  icDropdown,
  icDropup,
  icUnChecked,
  icChecked,
  icExcel,
  icCancleBtn,
  IcCloseImg,
  icCalendarMonth,
  icModalCancelBtn,

  Ellipse,

  infoSideBar,
  badgeSideBar,
  calendarSideBar,
  inventorySideBar,
  reviewSideBar,
  memberSideBar,
  franchiseeSideBar,
  boardSideBar,
  systemSideBar,
  employmentSideBar,
  operateSideBar,
  postSideBar,
  dataExploration,
  contactPage,

  menuImg,
  dragHandle,
  cameraAlt,
};
