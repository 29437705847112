/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Header, CustomPagination } from '@components';
import { Container, Table, Form, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import {
  CustomDatePicker,
  CustomNavigator,
  CustomSelect,
  CustomSwal,
} from '../../components';
import { getBookingList } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import SEARCH_TYPE from '../../common/constants/SearchType';
import Utils from '../../utils/Utils';
import AVAILABLE_TIME from '../../common/constants/AvailableTime';

// ===================================================================
// [ 게시물 관리 > 상담예약 ]
// ===================================================================
export default React.memo(function BookingList(props) {
  const today = new Date();
  const navigate = useNavigate();
  const { page } = useParams();
  const { state } = useLocation();
  const searchTypes = [
    { value: SEARCH_TYPE.NAME, label: '이름' },
    { value: SEARCH_TYPE.PHONE, label: '전화번호' },
  ];

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [bookingList, setBookingList] = useState([]);
  const [pageCnt, setPageCnt] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [searchingNow, setSearchingNow] = useState(false);
  const [searchType, setSearchType] = useState(searchTypes[0].value);

  const searchedStartDate =
    state?.params.startDate || new Date(today.setDate(today.getDate() - 7));
  const searchedEndDate = state?.params.endDate || new Date();
  const searchedKeyword = state?.params.keyword || '';
  const searchedSearchType = state?.params.searchType || searchTypes[0];
  const refresh = state?.params.refresh || false;

  const initState = () => {
    setSearchType(searchedSearchType);
    setKeyword(searchedKeyword);
    setFromDate(searchedStartDate);
    setToDate(searchedEndDate);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 검색 유형 선택
  const searchTypeChangeHandler = selectedOption => {
    setSearchType(selectedOption);
  };

  // 검색 유형 값
  const findSearchType = type => {
    return searchTypes.find(item => item.value === type);
  };

  const startDateChangeHandler = date => {
    if (format(date, 'yyyy-MM-dd') > format(toDate, 'yyyy-MM-dd')) {
      CustomSwal.fire({ text: '최대 생성일 이후는 선택할 수 없습니다.' });
      return;
    }
    setFromDate(date);
  };

  const endDateChangeHandler = date => {
    if (format(date, 'yyyy-MM-dd') < format(fromDate, 'yyyy-MM-dd')) {
      CustomSwal.fire({ text: '최소 생성일 이전은 선택할 수 없습니다.' });
      return;
    }
    setToDate(date);
  };

  // 페이징
  const nextPage = pageNum => {
    const params = {
      searchType: searchedSearchType.value,
      keyword: searchedKeyword,
      refresh: !refresh,
      startDate: searchedStartDate,
      endDate: searchedEndDate,
    };
    navigate(`${RouterPath.bookingList}/${pageNum}`, { state: { params } });
  };

  // 검색 핸들러
  const searching = () => {
    if (searchingNow) return;
    setSearchingNow(true);
    const params = {
      searchType,
      keyword,
      refresh: !refresh,
      startDate: fromDate,
      endDate: toDate,
    };
    navigate(`${RouterPath.bookingList}/1`, { state: { params } });
  };

  // ===================================================================
  // [ API ] 예약 리스트 불러오기
  // ===================================================================
  const getList = async () => {
    try {
      const params = {
        page,
        size: 10,
        searchType: searchedSearchType.value,
        keyword: searchedKeyword,
        startDate: format(searchedStartDate, 'yyyy-MM-dd'),
        endDate: format(searchedEndDate, 'yyyy-MM-dd'),
      };

      const { data } = await getBookingList(params);
      const info = data.data;

      setBookingList([...info.list]);
      setPageCnt(info.totalPages);
    } catch (error) {
      handleError(error);
    }
    setSearchingNow(false);
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    initState();
    getList();
  }, [page, refresh]);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="booking">
      <Header title="상담예약" />
      <Container className="contents">
        <CustomNavigator pageNameList={['상담예약 관리']} />
        <div className="title">
          <p className="title-text">상담예약 관리</p>
        </div>
        <article className="menu-top-article">
          {/* 유형 선택칸 */}
          <div className="grid-top-box">
            <p>검색유형</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomSelect
                className="mw-160"
                options={searchTypes}
                value={findSearchType(searchType)}
                onChange={searchTypeChangeHandler}
              />
            </div>
          </div>
          <div className="grid-top-box">
            <p>생성일</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomDatePicker
                maxWidth="145px;"
                value={fromDate}
                onChange={startDateChangeHandler}
              />
              ~
              <CustomDatePicker
                maxWidth="145px;"
                value={toDate}
                onChange={endDateChangeHandler}
              />
            </div>
          </div>
          {/* 검색어 입력칸 */}
          <div className="grid-top-box">
            <p>검색어</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Form.Control
                type="text"
                placeholder="검색어를 입력해주세요"
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                value={keyword || ''}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    searching();
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="mt-3"
          >
            {/* 검색 btn */}
            <Button onClick={searching}>검색</Button>
          </div>
        </article>
        <article className="menu-article">
          {/* 공지 리스트 목록 */}
          <Table className="menulists table-hover">
            <colgroup>
              <col width={40} />
              <col width={50} />
              <col width={50} />
              <col width={120} />
              <col width={50} />
              <col width={60} />
            </colgroup>
            <thead className="table-tr">
              <tr>
                <th>이름</th>
                <th>전화번호</th>
                <th>상담 가능 시간</th>
                <th>메모</th>
                <th className="th-center">CMS</th>
                <th>등록일시</th>
              </tr>
            </thead>
            <tbody>
              {bookingList.length > 0 ? (
                bookingList.map((item, index) => (
                  <tr key={index}>
                    <td className="td-text-detail">{item.bookingName}</td>
                    <td className="td-text-detail">
                      {Utils.addHypenToPhoneNumber(item.bookingPhoneNumber)}
                    </td>
                    <td className="td-text-detail">
                      {AVAILABLE_TIME[item.availableTime].desc}
                    </td>
                    <td
                      className="td-text-detail"
                      style={{ overflow: 'visible', whiteSpace: 'pre-wrap' }}
                    >
                      {item.bookingMemo}
                    </td>
                    <td className="td-text-detail th-center">
                      {item.apiResBody === 'Y' ? '성공' : '실패'}
                    </td>
                    <td>
                      {format(new Date(item.regDate), 'yyyy-MM-dd HH:mm')}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="no-data" colSpan={5}>
                    상담예약이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* 페이징 */}
          <CustomPagination
            pageCount={pageCnt}
            pageNum={page}
            setPage={nextPage}
          />
        </article>
      </Container>
    </main>
  );
});
