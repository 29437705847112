/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Header, CustomNavigator } from '@components';
import { Container, Image, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import { CustomSwal } from '../../components';
import {
  getDownloadFile,
  getReviewDetail,
  removeReview,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

// ===================================================================
// [ 게시물 관리 > 치료후기사항 상세 ]
// ===================================================================
export default React.memo(function ReviewDetail(props) {
  const navigate = useNavigate();

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [reviewDetail, setReviewDetail] = useState([]);
  const [regDate, setRegDate] = useState(new Date());
  const [visitDate, setVisitDate] = useState(new Date());
  const { reviewIdx } = useParams();

  const goModify = () => {
    navigate(`${RouterPath.reviewEdit}`, { state: { reviewIdx } });
  };

  // ===================================================================
  // [ API ] 파일 다운로드
  // ===================================================================
  const downloadFile = async (fileNm, fileUrl) => {
    try {
      const params = {
        filePath: fileUrl,
      };
      const response = await getDownloadFile(params);

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileNm;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 치료후기 상세 불러오기
  // ===================================================================
  const getDetail = async () => {
    try {
      const data = await getReviewDetail(reviewIdx);
      if (data.data.code === 200) {
        const info = data.data.data;

        setReviewDetail(info);
        setRegDate(new Date(info.regDate));
        setVisitDate(new Date(info.visitDate));
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 치료후기 리스트 삭제
  // ===================================================================
  const reviewDelete = async () => {
    try {
      const data = await removeReview(reviewIdx);
      if (data.data.code === 200) {
        CustomSwal.fire({
          text: '삭제가 완료되었습니다.',
          finally: () => navigate(`${RouterPath.reviewList}/1`),
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getDetail();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="review-detail">
      <Header title="치료후기" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['치료후기', '상세보기']}
          pathList={[RouterPath.reviewList]}
        />
        <div className="title">
          <p className="title-text">치료후기</p>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {/* 삭제 btn */}
            <Button
              variant="outline-primary"
              onClick={e => {
                CustomSwal.fire({
                  text: '삭제하시겠습니까?',
                  cancel: true,
                  then: reviewDelete,
                });
              }}
            >
              삭제
            </Button>
            {/* 수정 btn */}
            <Button onClick={() => goModify()}>수정</Button>
          </div>
        </div>
        {/* 바디 */}
        <article className="partner-detail-info">
          <div className="form-box">
            <div className="info-box">
              {/* 치료후기 유형 */}
              <div className="info-box-grid">
                <p className="grid-title">후기 유형</p>
                <p className="grid-body">{[reviewDetail.categoryName]}</p>
              </div>
              {/* 수정 */}
              <div className="info-box-grid">
                <p className="grid-title">등록일시</p>
                <p className="grid-body">{format(regDate, 'yyyy-MM-dd')}</p>
              </div>
              {/* 작성자 */}
              <div className="info-box-grid">
                <p className="grid-title">작성자</p>
                <p className="grid-body">{reviewDetail.adminName}</p>
              </div>
              {/* 제목 */}
              <div className="info-box-grid">
                <p className="grid-title">제목</p>
                <p className="grid-body">{reviewDetail.reviewTitle}</p>
              </div>
              {/* 내용 */}
              <div className="info-box-grid">
                <p className="grid-title">내용</p>
                <p className="grid-body">{reviewDetail.reviewBody}</p>
              </div>
              {/* 파일 */}
              <div className="info-box-grid">
                <p className="grid-title">파일첨부</p>
                {reviewDetail.reviewFiles &&
                reviewDetail.reviewFiles.length > 0 ? (
                  <div>
                    {reviewDetail.reviewFiles.map((file, fileIndex) => (
                      <p
                        style={{
                          color: '#326BFF',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        className="grid-body"
                        key={fileIndex}
                        onClick={() => {
                          downloadFile(
                            file.originalFileName,
                            file.uploadedFilePath,
                          );
                        }}
                      >
                        {file.originalFileName}
                      </p>
                    ))}
                  </div>
                ) : (
                  <p className="grid-body">파일 첨부 없음</p>
                )}
              </div>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
