import React from 'react';

function CustomNavigator({ pageNameList, pathList }) {
  return (
    <nav className="d-flex" style={{ alignItems: 'center', gap: '4px' }}>
      {pageNameList &&
        pageNameList.length > 0 &&
        pageNameList.map((v, i) => {
          return (
            <p
              key={`nav-${i}`}
              style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
            >
              {i === 0 ? (
                ''
              ) : (
                <i
                  className="material-icons"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: '#4D4D53',
                  }}
                >
                  arrow_forward_ios
                </i>
              )}
              {pathList && pathList.length > 0 && pathList[i] ? (
                <a href={pathList[i]}>{v}</a>
              ) : (
                <span>{v}</span>
              )}
            </p>
          );
        })}
    </nav>
  );
}
export default CustomNavigator;
