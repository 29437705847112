/* eslint-disable import/no-extraneous-dependencies */
import 'react-quill/dist/quill.snow.css';
import React, { useEffect, useState } from 'react';
import { Header, CustomPagination } from '@components';
import { Container, Table, Form, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from '../../common';
import { CustomNavigator, CustomSelect, CustomSwal } from '../../components';
import { cancelUser, getUserList } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import LOGIN_TYPE from '../../common/constants/LoginType';
import SEARCH_TYPE from '../../common/constants/SearchType';

// ===================================================================
// [ 게시물 관리 > 회원관리 ]
// ===================================================================
export default React.memo(function UserList(props) {
  const location = useLocation();
  const { page } = useParams();
  const { state } = useLocation();
  const refresh = state?.params.refresh || false;
  const navigate = useNavigate();
  const searchTypes = [
    { value: SEARCH_TYPE.LOGIN_ID, label: '아이디' },
    { value: SEARCH_TYPE.NAME, label: '이름' },
  ];

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [userList, setUserList] = useState([]);
  const [pageCnt, setPageCnt] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [searchingNow, setSearchingNow] = useState(false);
  const [searchType, setSearchType] = useState(searchTypes[0].value);
  const [menuPermission, setMenuPermission] = useState(null);

  const searchedKeyword = state?.params.keyword || '';
  const searchedSearchType = state?.params.searchType || searchTypes[0];
  const initState = () => {
    setSearchType(searchedSearchType);
    setKeyword(searchedKeyword);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 검색 유형 선택
  const searchTypeChangeHandler = selectedOption => {
    setSearchType(selectedOption);
  };

  // 검색 유형 값
  const findSearchType = type => {
    return searchTypes.find(item => item.value === type);
  };

  // 페이징
  const nextPage = pageNum => {
    const params = {
      searchType: searchedSearchType.value,
      keyword: searchedKeyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.userList}/${pageNum}`, { state: { params } });
  };

  // 검색 핸들러
  const searching = () => {
    if (searchingNow) return;
    setSearchingNow(true);
    const params = {
      searchType,
      keyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.userList}/1`, { state: { params } });
  };

  // 검색 핸들러
  const cancelMember = async userIdx => {
    const { data } = await cancelUser(userIdx);

    if (data.code === 200) {
      CustomSwal.fire({
        title: 'SUCCESS',
        text: '회원탈퇴가 완료되었습니다.',
        finally: () => {
          getList();
        },
      });
    }
  };

  // ===================================================================
  // [ API ] 회원 리스트 불러오기
  // ===================================================================
  const getList = async () => {
    try {
      const params = {
        page,
        size: 10,
        searchType: searchedSearchType.value,
        keyword: searchedKeyword,
      };

      const { data } = await getUserList(params);
      const info = data.data;

      setUserList([...info.list]);
      setPageCnt(info.totalPages);
    } catch (error) {
      handleError(error);
    }
    setSearchingNow(false);
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    initState();
    getList();
  }, [page, refresh]);

  useEffect(() => {
    setMenuPermission(
      JSON.parse(localStorage.getItem('menuPermissions')).find(
        menu => menu.menuRouteUrl === RouterPath.userList,
      ),
    );
  }, [location]);

  useEffect(() => {
    console.log('menuPermission::: ', menuPermission);
  }, [menuPermission]);
  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="user">
      <Header title="회원 관리" />
      <Container className="contents">
        <CustomNavigator pageNameList={['회원 관리']} />
        <div className="title">
          <p className="title-text">회원 관리</p>
        </div>
        <article className="menu-top-article">
          {/* 유형 선택칸 */}
          <div className="grid-top-box">
            <p>유형</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomSelect
                className="mw-160"
                options={searchTypes}
                value={findSearchType(searchType)}
                onChange={searchTypeChangeHandler}
              />
            </div>
          </div>
          {/* 검색어 입력칸 */}
          <div className="grid-top-box">
            <p>검색어</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Form.Control
                type="text"
                placeholder="검색어를 입력해주세요"
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                value={keyword || ''}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    searching();
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="mt-3"
          >
            {/* 검색 btn */}
            <Button onClick={searching}>검색</Button>
          </div>
        </article>
        <article className="menu-article">
          {/* 공지 리스트 목록 */}
          <Table className="menulists table-hover">
            <colgroup>
              <col width={70} />
              <col width={70} />
              <col width={100} />
              <col width={90} />
              <col width={90} />
              {menuPermission?.canRemoveYn === 'Y' && <col width={50} />}
            </colgroup>
            <thead className="table-tr">
              <tr>
                <th>회원유형</th>
                <th>이름</th>
                <th>로그인 아이디</th>
                <th>가입일시</th>
                <th>최근 로그인</th>
                {menuPermission?.canRemoveYn === 'Y' && <th>관리</th>}
              </tr>
            </thead>
            <tbody>
              {userList.length > 0 ? (
                userList.map((item, index) => (
                  <tr key={index}>
                    <td className="td-text-detail">
                      {LOGIN_TYPE[item.userLoginType].label}
                    </td>
                    <td className="td-text-detail">{item.userName}</td>
                    <td className="td-text-detail">{item.userLoginId}</td>
                    <td>
                      {format(new Date(item.regDate), 'yyyy-MM-dd HH:mm')}
                    </td>
                    <td>
                      {item.lastLoginDate
                        ? format(
                            new Date(item.lastLoginDate),
                            'yyyy-MM-dd HH:mm',
                          )
                        : '-'}
                    </td>
                    {menuPermission?.canRemoveYn === 'Y' && (
                      <td>
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            CustomSwal.fire({
                              title: '회원탈퇴 처리',
                              text: `선택한 회원 (${item.userLoginId})를 탈퇴 처리하시겠습니까?`,
                              then: () => {
                                cancelMember(item.userIdx);
                              },
                              cancel: true,
                            });
                          }}
                        >
                          회원탈퇴
                        </Button>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="no-data" colSpan={6}>
                    회원이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* 페이징 */}
          <CustomPagination
            pageCount={pageCnt}
            pageNum={page}
            setPage={nextPage}
          />
        </article>
      </Container>
    </main>
  );
});
