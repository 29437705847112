/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef } from 'react';
import { Header, CustomNavigator } from '@components';
import { Container, Image, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import images from '../../assets/images';
import { RouterPath, Utils } from '../../common';
import { handleError } from '../../utils/HandleError';
import { saveBanner } from '../../api/RestAPI';
import CustomSwal from '../../components/CustomSwal';

const MAX_FILE_SIZE = 20 * 1024 * 1024;
export default React.memo(function BannerRegister(props) {
  const navigate = useNavigate();
  const fileInputRef = useRef();
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [mainTxt, setMainTxt] = useState('');
  const [subTxt, setSubTxt] = useState('');
  const [fileImage, setFileImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  // ===================================================================
  // [ Utils ]
  // ===================================================================
  const validateForm = () => {
    const result = {
      isValid: false,
      message: '',
    };
    // 메인, 서브 텍스트
    if (mainTxt.trim() === '' || subTxt.trim() === '') {
      result.message = '배너 텍스트를 입력해 주세요.';
      return result;
    }
    // 파일
    if (!fileImage) {
      result.message = '이미지 첨부파일을 확인해주세요.';
      return result;
    }

    // 확장자 확인
    if (fileImage) {
      const fileName = fileImage.name;
      const isImageFile = /\.(jpg|jpeg|png|gif)$/i.test(fileName);

      if (!isImageFile) {
        result.message = '이미지 파일이 아닙니다.(jpg/png/gif)';
        return result;
      }
    }

    result.isValid = true;
    return result;
  };

  // 파일 삭제 ( 초기화 )
  const removeFile = () => {
    setImageSrc(null);
    setFileImage(null);
  };

  // 이미지 업로드 ( File 추가 & 미리보기 )
  // 이미지 업로드
  const onUpload = e => {
    const newFile = e.target.files[0];
    if (newFile.size > MAX_FILE_SIZE) {
      CustomSwal.fire({
        text: '최대 용량은 20MB 입니다.',
      });

      fileInputRef.current.value = '';
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => {
        setImageSrc(reader.result || null);
        setFileImage(newFile);
      };
    }
  };

  // 리스트 페이지 이동
  const moveBannerListPage = () => {
    navigate(RouterPath.bannerList);
  };

  // ===================================================================
  // [ Api ] 배너 등록
  // ===================================================================
  const registerBanner = async e => {
    const { target } = e;
    try {
      // Input & File 검증
      const validationResult = validateForm();
      if (!validationResult.isValid) {
        CustomSwal.fire({
          title: '알람',
          text: validationResult.message,
        });
        return;
      }

      // JSON 파라미터
      const param = {
        bannerMainText: mainTxt,
        bannerSubText: subTxt,
      };

      // FormData 생성
      const formData = new FormData();
      const json = JSON.stringify(param);
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('dto', blob);
      formData.append('file', fileImage);

      // Axios
      const { data } = await saveBanner(formData);

      // Return
      if (data.code === 200) {
        CustomSwal.fire({
          text: '배너 등록이 완료되었습니다.',
          finally: moveBannerListPage,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="banner">
      <Header title="배너 등록" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['배너 관리', '배너 등록']}
          pathList={[RouterPath.bannerList, '']}
        />
        <div className="title">
          <p className="title-text">배너 등록</p>
        </div>
        <article className="banner-article">
          <div className="align-left">
            <div>
              <p>메인 텍스트</p>
              <Form.Control
                as="textarea"
                placeholder="배너의 메인 텍스트를 입력해주세요."
                style={{ height: '215px', width: '100%', resize: 'none' }}
                onChange={e => setMainTxt(e.target.value)}
              />
            </div>
            <div>
              <p>서브 텍스트</p>
              <Form.Control
                as="textarea"
                placeholder="배너의 서브 텍스트를 입력해주세요."
                style={{ height: '215px', width: '100%', resize: 'none' }}
                onChange={e => setSubTxt(e.target.value)}
              />
            </div>
            <div style={{ marginBottom: '32px', marginTop: '16px' }}>
              <p>이미지</p>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                {/* 이미지 미리보기 */}
                {imageSrc && (
                  <div className="upload-css">
                    <Image width="100%" src={imageSrc} className="select-img" />
                    <Image
                      src={images.IcCloseImg}
                      className="close-img"
                      onClick={removeFile}
                    />
                  </div>
                )}
                {/* 이미지 Input */}
                {!imageSrc && (
                  <label htmlFor="ex_file" className="image-input">
                    <Image src={images.cameraAlt} />
                    <span>이미지등록</span>
                    <input
                      id="ex_file"
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      onChange={e => onUpload(e)}
                    />
                  </label>
                )}
              </div>
              <p className="helper-text">
                *파일형식 : jpg, gif, png / 파일용량 20MB이하
              </p>
            </div>
            {/* 버튼 */}
            <div style={{ textAlign: 'right' }}>
              {/* 버튼 > 취소 */}
              <button
                type="button"
                className="cancle-btn"
                onClick={() => {
                  CustomSwal.fire({
                    text: '취소하시겠습니까?',
                    cancel: true,
                    then: moveBannerListPage,
                  });
                }}
              >
                취소
              </button>
              {/* 버튼 > 등록 */}
              <button
                type="button"
                className="register"
                onClick={() => {
                  CustomSwal.fire({
                    text: '등록하시겠습니까?',
                    cancel: true,
                    then: registerBanner,
                  });
                }}
              >
                등록
              </button>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
