/** @jsxImportSource @emotion/react */
import React from 'react';
import Select from 'react-select';
import { images } from '@assets';

// 참고 https://react-select.com/home
function CustomSelect({ value, options, onChange, disabled, ...rest }) {
  // console.log(value);
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      styles={customStyles}
      isDisabled={disabled}
      noOptionsMessage={() => '선택 옵션이 없습니다.'}
      maxMenuHeight={400}
      {...rest}
    />
  );
}

const customStyles = {
  // select box
  container: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    flex: '0 0 auto !important',
    width: 'auto',
    minWidth: 260,
    '&.mw-108': {
      minWidth: 108,
    },
    '&.mw-100': {
      minWidth: 100,
    },
    '&.mw-98': {
      minWidth: 98,
    },
    '&.mw-160': {
      minWidth: 160,
    },
    '&.mw-110': {
      minWidth: 110,
    },
    '&.mw-80': {
      minWidth: 80,
    },
    '&.mw-85': {
      minWidth: 85,
    },
    '&.mw-200': {
      minWidth: 200,
    },
    '&.mw-288': {
      minWidth: 288,
    },
    '&.max-110': {
      maxWidth: 100,
      minWidth: 60,
    },
    '&.max-200': {
      maxWidth: 200,
      minWidth: 60,
    },
    '&.max-160': {
      maxWidth: 160,
      minWidth: 60,
    },
    '&.max-250': {
      maxWidth: 260,
      minWidth: 60,
    },
    '&.mw-auto': {
      minWidth: 'auto',
    },
    '&.flex-1': {
      flex: '1 1 100% !important',
    },
    '&.max-330': {
      maxWidth: 330,
    },
  }),
  // input box
  control: (provided, state) => ({
    ...provided,
    height: 48,
    margin: 0,
    padding: 0,
    borderRadius: '0.375rem',
    border: state.isFocused
      ? '1px solid var(--bs-primary) !important'
      : '1px solid var(--bs-border)  !important',
    boxShadow: state.isFocused
      ? '0px 0px 0px 4px rgba(var(--bs-primary-rgb), 0.2)'
      : 'none',
    display: 'flex',
    alignItems: 'center',
  }),
  // input
  valueContainer: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.8rem',
    height: '100%',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
    fontWeight: 500,
    fontSize: 'var(--fs-16)',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontWeight: 500,
    fontSize: 'var(--fs-16)',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    color: 'var(--bs-dark)',
  }),
  // 우측
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    // margin: '0 0.5rem',
    width: 24,
    height: 24,
    backgroundImage: state.selectProps.menuIsOpen
      ? `url(${images.icDropup})`
      : `url(${images.icDropdown})`,
    svg: {
      display: 'none',
    },
  }),
  // options
  menu: provided => ({
    ...provided,
    padding: 0,
    borderColor: 'var(--bs-border)',
    borderRadius: '0.375rem',
    zIndex: 4,
    overflow: 'hidden',
  }),
  menuList: provided => ({
    ...provided,
    padding: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    padding: '0.5rem 0.75rem',
    color: state.isSelected ? 'var(--bs-primary) !important' : 'var(--bs-dark)',
    fontWeight: state.isSelected ? 700 : 400,
    backgroundColor: state.isSelected
      ? 'var(--bs-ts) !important'
      : 'var(--bs-ts)',
    ':hover': {
      backgroundColor: 'var(--bs-gray-100)',
    },
    ':active': {
      backgroundColor: 'var(--bs-gray-300)',
    },
  }),
};

export default CustomSelect;
