const AVAILABLE_TIME = {
  T001: {
    code: 'T001',
    desc: '아무때나',
  },
  T002: {
    code: 'T002',
    desc: '오전 (09~12)',
  },
  T003: {
    code: 'T004',
    desc: '점심 (12~14)',
  },
  T004: {
    code: 'T004',
    desc: '오후 (14~18)',
  },
  T005: {
    code: 'T005',
    desc: '18시 이후',
  },
};

export default AVAILABLE_TIME;
