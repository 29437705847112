/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Header, CustomPagination } from '@components';
import { Container, Table, Form, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import { CustomNavigator, CustomSelect } from '../../components';
import {
  getNoticeList,
  getNoticeType,
  offNoticeTop,
  onNoticeTop,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

// ===================================================================
// [ 게시물 관리 > 공지사항 ]
// ===================================================================
export default React.memo(function NoticeList(props) {
  const { page } = useParams();
  const { state } = useLocation();
  const initialSearchType = [{ value: '', label: '전체' }];
  const refresh = state?.params.refresh || false;
  const navigate = useNavigate();

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [noticeList, setNoticeList] = useState([]);
  const [pageCnt, setPageCnt] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [searchingNow, setSearchingNow] = useState(false);
  const [searchTypes, setSearchTypes] = useState(initialSearchType);
  const [searchType, setSearchType] = useState(searchTypes[0].value);

  const searchedKeyword = state?.params.keyword || '';
  const searchedSearchType = state?.params.categoryCode || searchTypes[0];
  const initState = () => {
    setSearchType(searchedSearchType);
    setKeyword(searchedKeyword);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 검색 유형 선택
  const searchTypeChangeHandler = selectedOption => {
    setSearchType(selectedOption);
  };

  // 검색 유형 값
  const findSearchType = type => {
    return searchTypes.find(item => item.value === type);
  };

  // 페이징
  const nextPage = pageNum => {
    const params = {
      categoryCode: searchedSearchType.value,
      keyword: searchedKeyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.noticeList}/${pageNum}`, { state: { params } });
  };

  // 검색 핸들러
  const searching = () => {
    if (searchingNow) return;
    setSearchingNow(true);
    const params = {
      categoryCode: searchType,
      keyword,
      refresh: !refresh,
    };
    navigate(`${RouterPath.noticeList}/1`, { state: { params } });
  };

  // ===================================================================
  // [ API ] 공지 유형조회
  // ===================================================================
  const getType = async () => {
    try {
      const { data } = await getNoticeType();
      const info = data.data;

      setSearchTypes(prevSearchTypes => [
        ...prevSearchTypes,
        ...info.map(item => ({
          value: item.subCode,
          label: item.codeName,
        })),
      ]);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 공지 리스트 불러오기
  // ===================================================================
  const getList = async () => {
    try {
      const params = {
        page,
        size: 10,
        categoryCode: searchedSearchType.value,
        keyword: searchedKeyword,
      };

      const { data } = await getNoticeList(params);
      const info = data.data;

      setNoticeList([...info.list]);
      setPageCnt(info.totalPages);
    } catch (error) {
      handleError(error);
    }
    setSearchingNow(false);
  };

  // ===================================================================
  // [ API ] 상위 고정
  // ===================================================================
  const onTop = async noticeIdx => {
    try {
      const data = await onNoticeTop(noticeIdx);
      if (data.data.code === 200) {
        getList();
      }
    } catch (error) {
      handleError(error);
    }
  };
  // ===================================================================
  // [ API ] 상위 고정 해제
  // ===================================================================
  const offTop = async noticeIdx => {
    try {
      const data = await offNoticeTop(noticeIdx);
      if (data.data.code === 200) {
        getList();
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    initState();
    getList();
  }, [page, refresh]);

  useEffect(() => {
    getType();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="notice">
      <Header title="공지사항" />
      <Container className="contents">
        <CustomNavigator pageNameList={['공지사항 관리']} />
        <div className="title">
          <p className="title-text">공지사항</p>
          <Button onClick={() => navigate(RouterPath.noticeRegister)}>
            공지등록
          </Button>
        </div>
        <article className="menu-top-article">
          {/* 유형 선택칸 */}
          <div className="grid-top-box">
            <p>유형</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomSelect
                className="mw-160"
                options={searchTypes}
                value={findSearchType(searchType)}
                onChange={searchTypeChangeHandler}
              />
            </div>
          </div>
          {/* 검색어 입력칸 */}
          <div className="grid-top-box">
            <p>검색어</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Form.Control
                type="text"
                placeholder="검색어를 입력해주세요"
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                value={keyword || ''}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    searching();
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="mt-3"
          >
            {/* 검색 btn */}
            <Button onClick={searching}>검색</Button>
          </div>
        </article>
        <article className="menu-article">
          {/* 공지 리스트 목록 */}
          <Table className="menulists table-hover">
            <colgroup>
              <col width={100} />
              <col width={200} />
              <col width={100} />
              <col width={100} />
              <col width={50} />
            </colgroup>
            <thead className="table-tr">
              <tr>
                <th>공지유형</th>
                <th>제목</th>
                <th>작성자</th>
                <th>등록일시</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {noticeList.length > 0 ? (
                noticeList.map((item, index) => (
                  <tr key={index}>
                    <td
                      className="td-text-detail"
                      onClick={() =>
                        navigate(`${RouterPath.noticeDetail}/${item.noticeIdx}`)
                      }
                    >
                      {item.categoryName}
                    </td>
                    <td
                      className="td-text-detail"
                      onClick={() =>
                        navigate(`${RouterPath.noticeDetail}/${item.noticeIdx}`)
                      }
                    >
                      {item.noticeTitle}
                    </td>
                    <td
                      className="td-text-detail"
                      onClick={() =>
                        navigate(`${RouterPath.noticeDetail}/${item.noticeIdx}`)
                      }
                    >
                      {item.adminName}
                    </td>
                    <td
                      onClick={() =>
                        navigate(`${RouterPath.noticeDetail}/${item.noticeIdx}`)
                      }
                    >
                      {format(new Date(item.regDate), 'yyyy-MM-dd HH:mm')}
                    </td>
                    <td>
                      {item.topYn === 'N' ? (
                        <button
                          type="button"
                          onClick={() => onTop(item.noticeIdx)}
                        >
                          상단고정
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => offTop(item.noticeIdx)}
                        >
                          고정해제
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="no-data" colSpan={5}>
                    공지사항이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* 페이징 */}
          <CustomPagination
            pageCount={pageCnt}
            pageNum={page}
            setPage={nextPage}
          />
        </article>
      </Container>
    </main>
  );
});
