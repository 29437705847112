/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';

export const WALLET_STATUS = {
  NOT_CONNECT: 'NOT_CONNECT',
  CONNECT: 'CONNECT',
  ERROR: 'ERROR',
};

const initialState = {
  walletAddr: '',
  walletNetworkId: null,
  walletStatus: WALLET_STATUS.NOT_CONNECT,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, action) => {
      state.walletAddr = action.payload;
    },
    setWalletAddr: (state, action) => {
      state.walletAddr = action.payload;
    },
    setWalletStatus: (state, action) => {
      state.walletStatus = action.payload;
    },
    setWalletNetworkId: (state, action) => {
      state.walletNetworkId = String(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const walletAction = walletSlice.actions;

const walletPersistConfig = {
  key: 'wallet',
  storage,
  whitelist: ['walletAddr'], // `address`만 저장하도록 지정
};

export default persistReducer(walletPersistConfig, walletSlice.reducer);
