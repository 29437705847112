const TERMS_TYPE = {
  TERMS_SERVICE: {
    code: 'TERMS_SERVICE',
    desc: '이용약관',
  },
  TERMS_PRIVATE: {
    code: 'TERMS_PRIVATE',
    desc: '개인정보처리방침',
  },
  TERMS_LOCATE: {
    code: 'TERMS_LOCATE',
    desc: '위치기반서비스',
  },
  TERMS_OPENSOURCE: {
    code: 'TERMS_OPENSOURCE',
    desc: '오픈소스 라이선스',
  },
  TERMS_AD: {
    code: 'TERMS_AD',
    desc: '광고성 정보수신',
  },
};

export default TERMS_TYPE;
