import React from 'react';
import { Container } from 'react-bootstrap';

function Layout({ children }) {
  return (
    <Container fluid className="app-container">
      <div className="app-contents">{children}</div>
    </Container>
  );
}

export default React.memo(Layout);
