/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from 'react';
import { Header, CustomNavigator } from '@components';
import { Container, Table, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { format } from 'date-fns';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import images from '../../assets/images';
import { handleError } from '../../utils/HandleError';
import { CustomSwal } from '../../components';
import {
  getQnaList,
  modifyQnaOrder,
  removeQna,
  repostQna,
} from '../../api/RestAPI';

export default React.memo(function QnaManage(props) {
  const navigate = useNavigate();
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [qnaList, setQnaList] = useState([]);

  // ===================================================================
  // [ Utils ]
  // ===================================================================
  const goRegister = () => {
    navigate(`${RouterPath.qnaRegister}`);
  };

  const goModify = qnaIdx => {
    navigate(`${RouterPath.qnaEdit}`, { state: { qnaIdx } });
  };

  const goDetail = qnaIdx => {
    navigate(`${RouterPath.qnaDetail}/${qnaIdx}`);
  };

  // ===================================================================
  // [ Api ] FAQ 리스트 조회
  // ===================================================================
  const getList = async () => {
    try {
      const params = {
        page: 1,
        size: 1000,
      };
      const { data } = await getQnaList(params);
      setQnaList(data.data.list);
      console.log(data.data.list);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ Api ] FAQ 순서 변경
  // ===================================================================
  const handleDragEnd = async result => {
    if (!result.destination) return;
    const items = Array.from(qnaList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setQnaList(items);
  };
  const orderParams = useRef([]);
  const changeQnaOrder = async () => {
    try {
      qnaList.forEach((item, idx) => {
        orderParams.current.push({
          qnaIdx: item.qnaIdx,
          orderNo: idx + 1,
        });
      });

      console.log(orderParams.current);
      const { data } = await modifyQnaOrder(orderParams.current);
      if (data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: '순서 변경 성공',
        });
      }
    } catch (error) {
      handleError(error);
      getList();
    }
  };

  // ===================================================================
  // [ Api ] FAQ 삭제
  // ===================================================================
  const deleteQna = async idx => {
    try {
      const { data } = await removeQna(idx);
      if (data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: 'FAQ 삭제 성공',
        });
        getList();
      }
    } catch (error) {
      handleError(error);
      getList();
    }
  };

  // ===================================================================
  // [ Api ] FAQ 재게시
  // ===================================================================
  const repost = async idx => {
    try {
      const { data } = await repostQna(idx);
      if (data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: 'FAQ를 다시 게시했습니다.',
        });
        getList();
      }
    } catch (error) {
      handleError(error);
      getList();
    }
  };

  // ===================================================================
  // [ UseEffect ]
  // ===================================================================
  useEffect(() => {
    getList();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="qna">
      <Header title="FAQ 관리" />
      <Container className="contents">
        <CustomNavigator pageNameList={['FAQ 관리']} />
        <div className="title">
          <p className="title-text">FAQ 관리</p>
          <span>
            <button
              type="button"
              disabled={qnaList?.length <= 0}
              style={{ marginRight: '8px' }}
              onClick={() => {
                CustomSwal.fire({
                  title: 'FAQ 순서 변경',
                  text: '변경된 순서를 저장하시겠습니까?',
                  then: () => {
                    changeQnaOrder();
                  },
                  cancel: true,
                });
              }}
            >
              순서 저장
            </button>
            <button type="button" onClick={() => goRegister()}>
              FAQ 등록
            </button>
          </span>
        </div>
        <article className="qna-article">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="qnalists">
              {dropprovided => (
                <Table
                  className="qnalists table-hover"
                  {...dropprovided.droppableProps}
                  ref={dropprovided.innerRef}
                >
                  <colgroup>
                    <col width={40} />
                    <col width={200} />
                    <col width={50} />
                    <col width={100} />
                    <col width={40} />
                    <col width={40} />
                  </colgroup>
                  <thead className="table-tr">
                    <tr>
                      <th> </th>
                      <th>FAQ</th>
                      <th className="td-center">작성자</th>
                      <th className="td-center">등록일</th>
                      <th className="td-center"> </th>
                      <th className="td-center"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {qnaList?.length > 0 ? (
                      qnaList.map((item, index) => (
                        <Draggable
                          draggableId={`qna-${item.qnaIdx}`}
                          index={index}
                          key={`qna-${item.qnaIdx}`}
                        >
                          {(provided, snapshot) => {
                            return (
                              <tr
                                key={index}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <td
                                  className="td-center col-draghandle"
                                  {...provided.dragHandleProps}
                                >
                                  <Image src={images.dragHandle} />
                                </td>
                                <td onClick={() => goDetail(item.qnaIdx)}>
                                  {item.qnaQuestion}
                                </td>
                                <td
                                  className="td-center"
                                  onClick={() => goDetail(item.qnaIdx)}
                                >
                                  {item.adminName}
                                </td>
                                <td
                                  className="td-center"
                                  onClick={() => goDetail(item.qnaIdx)}
                                >
                                  {item.regDate
                                    ? format(
                                        new Date(item.regDate),
                                        'yyyy-MM-dd',
                                      )
                                    : '-'}
                                </td>
                                <td className="td-center col-qnaModi">
                                  <button
                                    type="button"
                                    onClick={() => goModify(item.qnaIdx)}
                                  >
                                    수정
                                  </button>
                                </td>
                                <td className="td-center col-qnaModi">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      CustomSwal.fire({
                                        title: 'FAQ 삭제',
                                        text: 'FAQ를 삭제하시겠습니까?',
                                        then: () => {
                                          deleteQna(item.qnaIdx);
                                        },
                                        cancel: true,
                                      });
                                    }}
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            );
                          }}
                        </Draggable>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={6}>
                          FAQ가 없습니다.
                        </td>
                      </tr>
                    )}
                    {dropprovided.placeholder}
                  </tbody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </article>
      </Container>
    </main>
  );
});
