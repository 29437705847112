/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import { Header, CustomNavigator, CustomSelect } from '@components';
import { Container, Image, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { format } from 'date-fns';
import images from '../../assets/images';
import { RouterPath } from '../../common';
import { getReviewType, saveReview } from '../../api/RestAPI';
import { CustomDatePicker, CustomSwal } from '../../components';
import { handleError } from '../../utils/HandleError';

// 이미지 용량 제한
const MAX_FILE_SIZE = 10 * 1024 * 1024;

// 초기값
const today = new Date();
const initialFormDetail = {
  reviewType: '',
  title: '',
  contents: '',
  files: '',
  regDate: today,
};

// ===================================================================
// [ 게시물 관리 > 후기 등록 ]
// ===================================================================

export default React.memo(function ReviewRegister(props) {
  const navigate = useNavigate();
  const fileInput = useRef();
  const thumbnailInputRef = useRef();

  const [searchTypes, setSearchTypes] = useState([]);
  const [newReview, setNewReview] = useState(initialFormDetail);
  const [files, setFiles] = useState([]);
  const [fileThumbnail, setFileThumbnail] = useState(null);
  const [thumbnailSrc, setThumbnailSrc] = useState(null);

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 선택한 유형 값 업데이트
  const handleSelectChange = selectedOption => {
    setNewReview({ ...newReview, reviewType: selectedOption.value });
  };

  const onThumbnailUpload = e => {
    const newFile = e.target.files[0];
    if (newFile.size > MAX_FILE_SIZE) {
      CustomSwal.fire({
        text: '최대 용량은 20MB 입니다.',
      });

      thumbnailInputRef.current.value = '';
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => {
        setThumbnailSrc(reader.result || null);
        setFileThumbnail(newFile);
      };
    }
  };

  // 파일 삭제 ( 초기화 )
  const removeThumbnail = () => {
    setThumbnailSrc(null);
    setFileThumbnail(null);
  };

  const onFileUpload = e => {
    const newFiles = e.target.files;
    const promises = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < newFiles.length; i++) {
      const newFile = newFiles[i];
      const reader = new FileReader();

      const promise = new Promise(resolve => {
        reader.onload = () => {
          resolve(newFile);
        };
      });

      reader.readAsDataURL(newFile);
      promises.push(promise);
    }

    return Promise.all(promises).then(filesArray => {
      setFiles(prevFiles => [...prevFiles, ...filesArray]);
    });
  };

  // 파일 첨부
  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  // 리스트 페이지 이동
  const moveReviewList = () => {
    navigate(RouterPath.reviewList);
  };

  // 파일 삭제
  const removeFile = index => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  // 등록일 선택 이벤트
  const dateChangeHandler = date => {
    setNewReview({ ...newReview, regDate: date });
  };

  // 빈칸 검증
  const validateForm = () => {
    const result = {
      isValid: false,
      message: '',
    };

    // 유형
    if (!newReview.reviewType.trim()) {
      result.message = '유형을 선택해주세요.';
      return result;
    }

    // 썸네일 확인
    if (!fileThumbnail && !thumbnailSrc) {
      result.message = '썸네일 사진을 등록해 주세요.';
      return result;
    }

    // 제목
    if (!newReview.title.trim()) {
      result.message = '제목을 확인해주세요.';
      return result;
    }

    // 내용
    if (!newReview.contents.trim()) {
      result.message = '내용을 확인해주세요.';
      return result;
    }
    result.isValid = true;
    return result;
  };

  // ===================================================================
  // [ API ] 후기 유형조회
  // ===================================================================
  const getType = async () => {
    try {
      const { data } = await getReviewType();
      const info = data.data;

      setSearchTypes(prevSearchTypes => [
        ...prevSearchTypes,
        ...info.map(item => ({
          value: item.subCode,
          label: item.codeName,
        })),
      ]);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 등록하기
  // ===================================================================

  const registerReview = async () => {
    try {
      const validationResult = validateForm();
      if (!validationResult.isValid) {
        CustomSwal.fire({ text: validationResult.message });
        return;
      }

      const param = {
        categoryCode: newReview.reviewType,
        reviewBody: newReview.contents,
        reviewTitle: newReview.title,
        regDate: format(newReview.regDate, 'yyyy-MM-dd'),
      };

      const formData = new FormData();
      const json = JSON.stringify(param);
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('dto', blob);
      formData.append('thumbnail', fileThumbnail);

      if (files && files.length > 0) {
        files.forEach((file, index) => {
          formData.append(`files`, file);
        });
      }
      const { data } = await saveReview(formData);

      if (data.code === 200) {
        CustomSwal.fire({
          text: '후기가 등록되었습니다.',
          finally: () => navigate(`${RouterPath.reviewList}/1`),
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getType();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="review-Register">
      <Header title="치료후기" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['치료후기', '치료후기 등록']}
          pathList={[RouterPath.reviewList]}
        />
        <div className="title">
          <p className="title-text">치료후기 등록</p>
        </div>
        {/* 입력칸 바디 */}
        <article className="menu-article">
          <div className="align-left">
            {/* 후기 유형 선택 */}
            <div className="add-div">
              <p>
                후기유형 <images.Ellipse />
              </p>
              <CustomSelect
                className="max-200"
                placeholder="유형 선택"
                options={searchTypes}
                onChange={handleSelectChange}
              />
            </div>
            {/* 등록일 */}
            <div className="add-div">
              <p>등록일</p>
              <CustomDatePicker
                className="max-200"
                maxDate={today}
                value={newReview.regDate}
                onChange={dateChangeHandler}
              />
            </div>
            {/* 제목 입력 */}
            <div className="add-div">
              <p>제목</p>
              <Form.Control
                type="text"
                placeholder="제목을 입력하세요."
                onChange={e =>
                  setNewReview({ ...newReview, title: e.target.value })
                }
              />
            </div>
            {/* 내용 입력 */}
            <div className="add-div">
              <p>내용</p>
              <Form.Control
                as="textarea"
                placeholder="내용을 입력하세요."
                style={{ height: '215px', resize: 'none' }}
                onChange={e =>
                  setNewReview({ ...newReview, contents: e.target.value })
                }
              />
            </div>

            <div style={{ marginBottom: '32px', marginTop: '16px' }}>
              <p>썸네일</p>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                {/* 썸네일 미리보기 */}
                {thumbnailSrc && (
                  <div className="upload-css">
                    <Image
                      width="100%"
                      src={thumbnailSrc}
                      className="select-img"
                    />
                    <Image
                      src={images.IcCloseImg}
                      className="close-img"
                      onClick={removeThumbnail}
                    />
                  </div>
                )}
                {/* 이미지 Input */}
                {!thumbnailSrc && (
                  <label htmlFor="ex_file" className="image-input">
                    <Image src={images.cameraAlt} />
                    <span>이미지등록</span>
                    <input
                      id="ex_file"
                      type="file"
                      accept="image/*"
                      ref={thumbnailInputRef}
                      onChange={e => onThumbnailUpload(e)}
                    />
                  </label>
                )}
              </div>
              <p className="helper-text">
                *파일형식 : jpg, gif, png / 파일용량 20MB이하
              </p>
            </div>

            {/* 파일 첨부 */}
            <div>
              <p>파일첨부</p>
              {files.map((file, index) => (
                <div style={{ display: 'flex' }}>
                  <Form.Control
                    type="text"
                    value={file.name}
                    placeholder="파일을 업로드 해주세요"
                    style={{ margin: '0 0 5px', flex: 1 }}
                    onChange={() => false}
                  />
                  <Image
                    src={images.icCancleBtn}
                    className="close-img"
                    onClick={() => removeFile(index)}
                  />
                </div>
              ))}
              {files.length < 5 ? (
                <Button onClick={handleFileButtonClick}>파일첨부</Button>
              ) : (
                ''
              )}
              <input
                type="file"
                ref={fileInput}
                onChange={e => onFileUpload(e)}
                multiple={false}
                style={{ display: 'none' }}
              />
            </div>
            <span
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#525252',
              }}
            >
              *이미지 또는 문서를 첨부해주세요.
            </span>
            <div style={{ textAlign: 'right' }}>
              {/* 취소 btn */}
              <button
                type="button"
                className="cancle-btn"
                onClick={() => {
                  CustomSwal.fire({
                    text: '취소하시겠습니까?',
                    cancel: true,
                    then: moveReviewList,
                  });
                }}
              >
                취소
              </button>
              {/* 등록 btn */}
              <button
                type="button"
                className="register"
                onClick={() => {
                  CustomSwal.fire({
                    text: '치료후기를 등록하시겠습니까?',
                    cancel: true,
                    then: registerReview,
                  });
                }}
              >
                등록
              </button>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
