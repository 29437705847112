/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ScrollTop } from '@components';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';

import './index.scss';
import { persistor, store } from './store';
import { TranslationProvider } from './common/hooks/TranslationProvider';
import ErrorBoundary from './utils/ErrorBoundary';

window.onload = () => {
  const lang = window.navigator.language;
  render();
};

const queryClient = new QueryClient();

function render() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<div>로딩...</div>} persistor={persistor}>
          <TranslationProvider>
            <BrowserRouter>
              <ScrollTop />
              <QueryClientProvider client={queryClient}>
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              </QueryClientProvider>
            </BrowserRouter>
          </TranslationProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
