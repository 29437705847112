import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Errors from '@pages/Errors';

import User from '../pages/User';
import Booking from '../pages/Booking';

import Banner from '../pages/Banner';
import BannerRegister from '../pages/Banner/BannerRegister';
import BannerEdit from '../pages/Banner/BannerEdit';

import Notice from '../pages/Notice';
import NoticeDetail from '../pages/Notice/NoticeDetail';
import NoticeRegister from '../pages/Notice/NoticeRegister';
import NoticeEdit from '../pages/Notice/NoticeEdit';

import Qna from '../pages/Qna';
import QnaDetail from '../pages/Qna/QnaDetail';
import QnaRegister from '../pages/Qna/QnaRegister';
import QnaEdit from '../pages/Qna/QnaEdit';

import Review from '../pages/Review';
import ReviewDetail from '../pages/Review/ReviewDetail';
import ReviewRegister from '../pages/Review/ReviewRegister';
import ReviewEdit from '../pages/Review/ReviewEdit';

import Popup from '../pages/Popup';
import PopupRegister from '../pages/Popup/PopupRegister';
import PopupEdit from '../pages/Popup/PopupEdit';

import Menu from '../pages/Menu';
import MenuPermission from '../pages/MenuPermission';
import Term from '../pages/Term';
import TermRegister from '../pages/Term/TermRegister';
import SysCode from '../pages/SysCode';
import MngLog from '../pages/MngLog';

import LayoutAuth from './LayoutAuth';

import { RouterPath } from '../common';

function AdminRouters({ firstMenuUrl }) {
  return (
    <Routes>
      <Route path={RouterPath.slash} element={<Navigate to={firstMenuUrl} />} />
      <Route
        path={RouterPath.signin}
        element={<Navigate to={firstMenuUrl} />}
      />

      {/* 회원 관리 */}
      <Route
        path={RouterPath.bookingList}
        element={
          <LayoutAuth>
            <Navigate to={`${RouterPath.bookingList}/1`} replace />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.bookingList}/:page`}
        element={
          <LayoutAuth>
            <Booking />
          </LayoutAuth>
        }
      />

      {/* 회원 관리 */}
      <Route
        path={RouterPath.userList}
        element={
          <LayoutAuth>
            <Navigate to={`${RouterPath.userList}/1`} replace />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.userList}/:page`}
        element={
          <LayoutAuth>
            <User />
          </LayoutAuth>
        }
      />

      {/* 배너 */}
      <Route
        path={RouterPath.bannerList}
        element={
          <LayoutAuth>
            <Banner />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.bannerRegister}
        element={
          <LayoutAuth>
            <BannerRegister />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.bannerEdit}
        element={
          <LayoutAuth>
            <BannerEdit />
          </LayoutAuth>
        }
      />

      {/* 공지사항 */}
      <Route
        path={RouterPath.noticeList}
        element={
          <LayoutAuth>
            <Navigate to={`${RouterPath.noticeList}/1`} replace />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.noticeList}/:page`}
        element={
          <LayoutAuth>
            <Notice />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.noticeDetail}/:noticeIdx`}
        element={
          <LayoutAuth>
            <NoticeDetail />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.noticeRegister}
        element={
          <LayoutAuth>
            <NoticeRegister />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.noticeEdit}
        element={
          <LayoutAuth>
            <NoticeEdit />
          </LayoutAuth>
        }
      />

      {/* QNA */}
      <Route
        path={RouterPath.qnaList}
        element={
          <LayoutAuth>
            <Qna />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.qnaDetail}/:qnaIdx`}
        element={
          <LayoutAuth>
            <QnaDetail />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.qnaRegister}
        element={
          <LayoutAuth>
            <QnaRegister />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.qnaEdit}
        element={
          <LayoutAuth>
            <QnaEdit />
          </LayoutAuth>
        }
      />

      {/* 치료 후기 */}
      <Route
        path={RouterPath.reviewList}
        element={
          <LayoutAuth>
            <Navigate to={`${RouterPath.reviewList}/1`} replace />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.reviewList}/:page`}
        element={
          <LayoutAuth>
            <Review />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.reviewDetail}/:reviewIdx`}
        element={
          <LayoutAuth>
            <ReviewDetail />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.reviewRegister}
        element={
          <LayoutAuth>
            <ReviewRegister />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.reviewEdit}
        element={
          <LayoutAuth>
            <ReviewEdit />
          </LayoutAuth>
        }
      />

      {/* 팝업 */}
      <Route
        path={RouterPath.popupList}
        element={
          <LayoutAuth>
            <Popup />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.popupRegister}
        element={
          <LayoutAuth>
            <PopupRegister />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.popupEdit}
        element={
          <LayoutAuth>
            <PopupEdit />
          </LayoutAuth>
        }
      />

      {/* 메뉴 권한 관리 */}
      <Route
        path={RouterPath.menuList}
        element={
          <LayoutAuth>
            <Menu />
          </LayoutAuth>
        }
      />

      {/* 메뉴 권한 관리 */}
      <Route
        path={RouterPath.menuPermission}
        element={
          <LayoutAuth>
            <MenuPermission />
          </LayoutAuth>
        }
      />

      {/* 약관 관리 */}
      <Route
        path={RouterPath.termList}
        element={
          <LayoutAuth>
            <Navigate to={`${RouterPath.termList}/1`} replace />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.termList}/:page`}
        element={
          <LayoutAuth>
            <Term />
          </LayoutAuth>
        }
      />
      <Route
        path={RouterPath.termRegister}
        element={
          <LayoutAuth>
            <TermRegister />
          </LayoutAuth>
        }
      />

      {/* 코드관리 */}
      <Route
        path={RouterPath.sysCode}
        element={
          <LayoutAuth>
            <SysCode />
          </LayoutAuth>
        }
      />

      {/* 로그조회 */}
      <Route
        path={RouterPath.mngLog}
        element={
          <LayoutAuth>
            <Navigate to={`${RouterPath.mngLog}/1`} replace />
          </LayoutAuth>
        }
      />
      <Route
        path={`${RouterPath.mngLog}/:page`}
        element={
          <LayoutAuth>
            <MngLog />
          </LayoutAuth>
        }
      />

      {/* 에러 */}
      <Route
        path="*"
        element={
          <LayoutAuth>
            <Errors />
          </LayoutAuth>
        }
      />
    </Routes>
  );
}

export default AdminRouters;
