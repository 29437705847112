import React, { useState, useEffect } from 'react';
import { Header, CustomNavigator, CustomSelect } from '@components';
import { Container, Form, Button, Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import Images from '../../assets/images';
import { RouterPath } from '../../common';
import {
  getNoticeDetail,
  getNoticeType,
  modifyNotice,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import { CustomSwal } from '../../components';

// 배너 용량 제한
const FILE_SIZE_MAX_LIMIT = 10 * 1024 * 1024;

const initialFormDetail = {
  noticeType: '',
  title: '',
  contents: '',
  files: '',
};

// ===================================================================
// [ 게시물 관리 > 공지 수정 ]
// ===================================================================
export default React.memo(function NoticeEdit(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { noticeIdx } = state;
  const fileInput = React.useRef(null);

  // ===================================================================
  // [ State ]
  // ===================================================================
  const [files, setFiles] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  const [deleteFile, setDeleteFile] = useState([]);
  const [noticeTypes, setNoticeTypes] = useState([]);
  const [editForm, setEditForm] = useState(initialFormDetail);

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 리스트 페이지 이동
  const moveNoticeDetail = () => {
    navigate(`${RouterPath.noticeList}/1`);
  };

  // 검색 유형 값
  const findSearchType = type => {
    return noticeTypes.find(item => item.value === type);
  };

  const onFileUpload = e => {
    const newFiles = e.target.files;
    const promises = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < newFiles.length; i++) {
      const newFile = newFiles[i];
      const reader = new FileReader();

      const promise = new Promise(resolve => {
        reader.onload = () => {
          resolve(newFile);
        };
      });

      reader.readAsDataURL(newFile);
      promises.push(promise);
    }

    return Promise.all(promises).then(filesArray => {
      setFiles(filesArray);
    });
  };

  const handleSelectChange = selectedOption => {
    setEditForm({ ...editForm, categoryCode: selectedOption.value });
  };

  // 파일 업로드 버튼
  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  // 파일 삭제
  const removeFile = index => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  // 파일 삭제
  const removeUploadedFile = fileIdx => {
    const updatedDeleteFile = [...deleteFile];
    updatedDeleteFile.push(fileIdx);
    setDeleteFile(updatedDeleteFile);

    const updatedNoticeFiles = editForm.noticeFiles.filter(
      file => file.uploadedFileIdx !== fileIdx,
    );
    setEditForm({ ...editForm, noticeFiles: updatedNoticeFiles });
  };

  // Input 검증
  const validateForm = () => {
    const result = {
      isValid: false,
      message: '',
    };

    result.isValid = true;
    return result;
  };

  // ===================================================================
  // [ API ] 공지 상세 가져오기
  // ===================================================================
  const getDetail = async () => {
    try {
      const data = await getNoticeDetail(noticeIdx);

      if (data.data.code === 200) {
        const info = data.data.data;

        setEditForm(info);
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 공지 유형조회
  // ===================================================================
  const getType = async () => {
    try {
      const { data } = await getNoticeType();
      const info = data.data;

      setNoticeTypes(prevTypes => [
        ...prevTypes,
        ...info.map(item => ({
          value: item.subCode,
          label: item.codeName,
        })),
      ]);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 공지 수정 값 보내기
  // ===================================================================
  const editNotice = async () => {
    try {
      // Input 검증
      const validationResult = validateForm();
      if (!validationResult.isValid) {
        CustomSwal.fire({ text: validationResult.message });
        return;
      }

      const param = {
        noticeIdx,
        categoryCode: editForm.categoryCode,
        noticeBody: editForm.noticeBody,
        noticeTitle: editForm.noticeTitle,
        removeFiles: deleteFile,
      };

      const formData = new FormData();
      const json = JSON.stringify(param);
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('dto', blob);

      if (files && files.length > 0) {
        files.forEach((file, index) => {
          formData.append(`files`, file);
        });
      }

      const { data } = await modifyNotice(formData);

      if (data.code === 200) {
        CustomSwal.fire({
          text: '수정이 완료되었습니다.',
          finally: () => navigate(`${RouterPath.noticeDetail}/${noticeIdx}`),
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getType();
    getDetail();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="notice-Register">
      <Header title="공지사항 수정" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['공지사항', '공지사항 수정']}
          pathList={[RouterPath.noticeList]}
        />
        <div className="title">
          <p className="title-text">공지사항 수정</p>
        </div>
        <article className="menu-article">
          <div className="align-left">
            {/* 공지 유형 */}
            <div className="add-div">
              <p>
                공지유형 <Images.Ellipse />
              </p>
              <CustomSelect
                className="max-200"
                options={noticeTypes}
                onChange={handleSelectChange}
                value={findSearchType(editForm.categoryCode)}
              />
            </div>
            {/* 제목 */}
            <div className="add-div">
              <p>제목</p>
              <Form.Control
                type="text"
                placeholder="제목을 입력하세요."
                value={editForm.noticeTitle}
                onChange={e =>
                  setEditForm({ ...editForm, noticeTitle: e.target.value })
                }
              />
            </div>
            {/* 내용 */}
            <div className="add-div">
              <p>내용</p>
              <Form.Control
                as="textarea"
                placeholder="내용을 입력하세요."
                value={editForm.noticeBody}
                style={{ height: '215px', resize: 'none' }}
                onChange={e =>
                  setEditForm({ ...editForm, noticeBody: e.target.value })
                }
              />
            </div>
            <div>
              <p>파일첨부</p>
              {editForm.noticeFiles?.map((f, i) => (
                <div style={{ display: 'flex' }}>
                  <Form.Control
                    type="text"
                    value={f.originalFileName}
                    placeholder="파일을 업로드 해주세요"
                    style={{ margin: '0 0 5px', flex: 1 }}
                    onChange={() => false}
                  />
                  <Image
                    src={Images.icCancleBtn}
                    className="close-img"
                    onClick={() => removeUploadedFile(f.uploadedFileIdx)}
                  />
                </div>
              ))}
              {files.map((file, index) => (
                <div style={{ display: 'flex' }}>
                  <Form.Control
                    type="text"
                    value={file.name}
                    placeholder="파일을 업로드 해주세요"
                    style={{ margin: '0 0 5px', flex: 1 }}
                    onChange={() => false}
                  />
                  <Image
                    src={Images.icCancleBtn}
                    className="close-img"
                    onClick={() => removeFile(index)}
                  />
                </div>
              ))}
              <Button onClick={handleFileButtonClick}>파일첨부</Button>
              <input
                type="file"
                ref={fileInput}
                onChange={e => onFileUpload(e)}
                multiple
                style={{ display: 'none' }}
              />
            </div>
            <span
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#525252',
              }}
            >
              *이미지 또는 문서를 첨부해주세요.
            </span>
            <div style={{ textAlign: 'right' }}>
              {/* 취소 btn */}
              <button
                type="button"
                className="cancle-btn"
                onClick={() => {
                  CustomSwal.fire({
                    text: '취소하시겠습니까?',
                    cancel: true,
                    then: moveNoticeDetail,
                  });
                }}
              >
                취소
              </button>
              {/* 수정 btn */}
              <button
                type="button"
                className="register"
                onClick={() => {
                  CustomSwal.fire({
                    text: '공지사항을 수정하시겠습니까?',
                    cancel: true,
                    then: editNotice,
                  });
                }}
              >
                수정
              </button>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
