/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useEffect, useState } from 'react';
import { Header } from '@components';
import { Container, Form, Table } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { handleError } from '../../utils/HandleError';
import { getMenuPermList, modifyMenuPerm } from '../../api/RestAPI';
import ADMIN_GRADE from '../../common/constants/AdminGrade';
import IsYN from '../../common/constants/IsYN';
import { CustomNavigator } from '../../components';

export default React.memo(function SysAuth(props) {
  /**
   * state
   */
  const [admGrade, setAdmGrade] = useState(ADMIN_GRADE.MASTER.code);
  const [authList, setAuthList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  /**
   * api
   */
  const getList = async () => {
    try {
      const { data } = await getMenuPermList(admGrade);
      const resultList = getDepsList(data.data);
      setAuthList(resultList);
    } catch (error) {
      handleError(error);
    }
  };

  const modifyPermission = async (
    e,
    {
      permissionGroupIdx,
      canReadYn,
      canSaveYn,
      canModifyYn,
      canRemoveYn,
      canDownloadYn,
      item,
      parentItem,
    },
  ) => {
    const target = e.currentTarget;
    target.disabled = true;
    try {
      const params = {
        permissionGroupIdx,
        canReadYn,
        canSaveYn,
        canModifyYn,
        canRemoveYn,
        canDownloadYn,
      };
      const { data } = await modifyMenuPerm(admGrade, params);

      // 소메뉴의 읽기 권한이 모두 없는 경우 대메뉴의 읽기 권한도 제거
      if (canReadYn === 'N' && parentItem) {
        // eslint-disable-next-line no-param-reassign
        item.canReadYn = 'N';
        if (
          parentItem.subList.length > 0 &&
          parentItem.subList.filter(v => v.canReadYn === 'Y').length === 0
        ) {
          await modifyMenuPerm({
            grade: admGrade,
            permissionGroupIdx: parentItem.permissionGroupIdx,
            readYn: 'N',
          });
        }
      }
      setRefresh(prev => !prev);
    } catch (error) {
      handleError(error);
    }
    target.disabled = false;
  };

  /**
   * help function
   */
  const getDepsList = list => {
    if (list && list.length > 0) {
      let permList = list;
      const removeList = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const obj of permList) {
        const subList = [];
        permList.forEach(item => {
          // eslint-disable-next-line no-unused-expressions
          if (obj.menuIdx === item.menuParentIdx) {
            subList.push(item);
            removeList.push(item);
          }
        });
        obj.subList = subList;
      }
      permList = permList.filter(item => !removeList.includes(item));
      return permList;
    }
    return [];
  };

  /**
   * useEffect
   */
  useEffect(() => {
    getList();
  }, [admGrade, refresh]);

  return (
    <main id="sys-menu-permission">
      <Header title="권한 관리" />
      <Container className="contents">
        <CustomNavigator pageNameList={['시스템 관리', '권한 관리']} />
        <div className="title">
          <p className="title-text">권한 관리</p>
        </div>
        <article className="menu-article">
          <div className="tab-menu">
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className={admGrade === ADMIN_GRADE.MASTER.code ? 'active' : ''}
              onClick={() => {
                setAdmGrade(ADMIN_GRADE.MASTER.code);
              }}
            >
              최고 관리자
            </button>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className={admGrade === ADMIN_GRADE.DEV.code ? 'active' : ''}
              onClick={() => {
                setAdmGrade(ADMIN_GRADE.DEV.code);
              }}
            >
              개발자
            </button>
          </div>
          <Table className="menulists">
            <colgroup>
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
            </colgroup>
            <thead className="table-tr">
              <tr>
                <th>대메뉴</th>
                <th>소메뉴</th>
                <th className="th-center">읽기 권한</th>
                <th className="th-center">쓰기 권한</th>
                <th className="th-center">수정 권한</th>
                <th className="th-center">삭제 권한</th>
                <th className="th-center">다운로드 권한</th>
              </tr>
            </thead>
            <tbody>
              {authList &&
                authList.length > 0 &&
                authList.map((item, index) => {
                  if (item.subList && item.subList.length > 0) {
                    return (
                      <Fragment key={index}>
                        {item.subList.map((subItem, subIndex) => {
                          return (
                            <tr
                              key={subIndex}
                              style={
                                item.subList.length !== subIndex + 1
                                  ? { borderBottomColor: '#FFFFFF' }
                                  : {}
                              }
                            >
                              {subIndex === 0 && (
                                <td
                                  rowSpan={item.subList.length}
                                  style={{ borderBottomColor: '#dee2e6' }}
                                >
                                  {item.menuName}
                                </td>
                              )}
                              <td>{subItem.menuName}</td>
                              <td className="th-center">
                                <Form.Check
                                  type="checkbox"
                                  checked={subItem.canReadYn === IsYN.Y}
                                  onChange={e => {
                                    modifyPermission(e, {
                                      permissionGroupIdx:
                                        subItem.permissionGroupIdx,
                                      canReadYn: e.target.checked ? 'Y' : 'N',
                                      parentItem: item,
                                      item: subItem,
                                    });
                                  }}
                                />
                              </td>
                              <td className="th-center">
                                <Form.Check
                                  type="checkbox"
                                  checked={subItem.canSaveYn === IsYN.Y}
                                  onChange={e => {
                                    modifyPermission(e, {
                                      permissionGroupIdx:
                                        subItem.permissionGroupIdx,
                                      canSaveYn: e.target.checked ? 'Y' : 'N',
                                      parentItem: item,
                                      item: subItem,
                                    });
                                  }}
                                />
                              </td>
                              <td className="th-center">
                                <Form.Check
                                  type="checkbox"
                                  checked={subItem.canModifyYn === IsYN.Y}
                                  onChange={e => {
                                    modifyPermission(e, {
                                      permissionGroupIdx:
                                        subItem.permissionGroupIdx,
                                      canModifyYn: e.target.checked ? 'Y' : 'N',
                                      parentItem: item,
                                      item: subItem,
                                    });
                                  }}
                                />
                              </td>
                              <td className="th-center">
                                <Form.Check
                                  type="checkbox"
                                  checked={subItem.canRemoveYn === IsYN.Y}
                                  onChange={e => {
                                    modifyPermission(e, {
                                      permissionGroupIdx:
                                        subItem.permissionGroupIdx,
                                      canRemoveYn: e.target.checked ? 'Y' : 'N',
                                      parentItem: item,
                                      item: subItem,
                                    });
                                  }}
                                />
                              </td>
                              <td className="th-center">
                                <Form.Check
                                  type="checkbox"
                                  checked={subItem.canDownloadYn === IsYN.Y}
                                  onChange={e => {
                                    modifyPermission(e, {
                                      permissionGroupIdx:
                                        subItem.permissionGroupIdx,
                                      parentItem: item,
                                      canDownloadYn: e.target.checked
                                        ? 'Y'
                                        : 'N',
                                      item: subItem,
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    );
                  }
                  return (
                    <tr key={index}>
                      <td>{item.menuName}</td>
                      <td />
                      <td className="th-center">
                        <Form.Check
                          type="checkbox"
                          checked={item.canReadYn === IsYN.Y}
                          onChange={e => {
                            modifyPermission(e, {
                              permissionGroupIdx: item.permissionGroupIdx,
                              canReadYn: e.target.checked ? 'Y' : 'N',
                              item,
                            });
                          }}
                        />
                      </td>
                      <td className="th-center">
                        <Form.Check
                          type="checkbox"
                          checked={item.canSaveYn === IsYN.Y}
                          onChange={e => {
                            modifyPermission(e, {
                              permissionGroupIdx: item.permissionGroupIdx,
                              canSaveYn: e.target.checked ? 'Y' : 'N',
                              item,
                            });
                          }}
                        />
                      </td>
                      <td className="th-center">
                        <Form.Check
                          type="checkbox"
                          checked={item.canModifyYn === IsYN.Y}
                          onChange={e => {
                            modifyPermission(e, {
                              permissionGroupIdx: item.permissionGroupIdx,
                              canModifyYn: e.target.checked ? 'Y' : 'N',
                              item,
                            });
                          }}
                        />
                      </td>
                      <td className="th-center">
                        <Form.Check
                          type="checkbox"
                          checked={item.canRemoveYn === IsYN.Y}
                          onChange={e => {
                            modifyPermission(e, {
                              permissionGroupIdx: item.permissionGroupIdx,
                              canRemoveYn: e.target.checked ? 'Y' : 'N',
                              item,
                            });
                          }}
                        />
                      </td>
                      <td className="th-center">
                        <Form.Check
                          type="checkbox"
                          checked={item.canDownloadYn === IsYN.Y}
                          onChange={e => {
                            modifyPermission(e, {
                              permissionGroupIdx: item.permissionGroupIdx,
                              canDownloadYn: e.target.checked ? 'Y' : 'N',
                              item,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </article>
      </Container>
    </main>
  );
});
