/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { CustomNavigator, Header } from '@components';
import { Container, Image, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { format } from 'date-fns';
import { RouterPath } from '../../common';
import 'react-quill/dist/quill.snow.css';
import images from '../../assets/images';
import { handleError } from '../../utils/HandleError';
import { CustomSwal } from '../../components';
import {
  getDownloadFile,
  getPopupList,
  modifyPopupOrder,
  removePopup,
} from '../../api/RestAPI';
import POPUP_TYPE from '../../common/constants/PopupType';

export default React.memo(function PopupManage(props) {
  const navigate = useNavigate();
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [popupList, setPopupList] = useState([]);

  // ===================================================================
  // [ Utils ]
  // ===================================================================
  const goRegister = () => {
    navigate(`${RouterPath.popupRegister}`);
  };

  const goModify = popupIdx => {
    navigate(`${RouterPath.popupEdit}`, { state: { popupIdx } });
  };

  // ===================================================================
  // [ Api ] 팝업 리스트 조회
  // ===================================================================
  const getList = async () => {
    try {
      const { data } = await getPopupList();

      await Promise.all(
        data.data.map(async (item, index) => {
          const params = {
            filePath: item.imagePath,
          };

          try {
            const response = await getDownloadFile(params);
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            });

            const url = window.URL.createObjectURL(blob);
            data.data[index].imagePath = url;
          } catch (downloadError) {
            handleError(downloadError);
          }
        }),
      );

      setPopupList(data.data);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ Api ] 팝업 순서 변경
  // ===================================================================
  const handleDragEnd = async result => {
    if (!result.destination) return;
    const items = Array.from(popupList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPopupList(items);
  };
  const orderParams = useRef({});
  const changePopupOrder = async () => {
    try {
      popupList.forEach((item, idx) => {
        orderParams.current[idx + 1] = item.popupIdx;
      });
      const { data } = await modifyPopupOrder(orderParams.current);
      if (data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: '순서 변경 성공',
        });
      }
    } catch (error) {
      handleError(error);
      getList();
    }
  };

  // ===================================================================
  // [ Api ] 팝업 삭제
  // ===================================================================
  const deletePopup = async idx => {
    try {
      const { data } = await removePopup(idx);
      if (data.code === 200) {
        CustomSwal.fire({
          title: '알림',
          text: '팝업 삭제 성공',
        });
        getList();
      }
    } catch (error) {
      handleError(error);
      getList();
    }
  };

  // ===================================================================
  // [ UseEffect ]
  // ===================================================================
  useEffect(() => {
    getList();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="popup">
      <Header title="팝업 관리" />
      <Container className="contents">
        <CustomNavigator pageNameList={['팝업 관리']} />
        <div className="title">
          <p className="title-text">팝업 관리</p>
          <span>
            <button
              type="button"
              disabled={popupList?.length <= 0}
              style={{ marginRight: '8px' }}
              onClick={() => {
                CustomSwal.fire({
                  title: '팝업 순서 변경',
                  text: '변경된 순서를 저장하시겠습니까?',
                  then: () => {
                    changePopupOrder();
                  },
                  cancel: true,
                });
              }}
            >
              순서 저장
            </button>
            <button type="button" onClick={() => goRegister()}>
              팝업 등록
            </button>
          </span>
        </div>
        <article className="popup-article">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="popuplists">
              {dropprovided => (
                <Table
                  className="popuplists table-hover"
                  {...dropprovided.droppableProps}
                  ref={dropprovided.innerRef}
                >
                  <colgroup>
                    <col width={50} />
                    <col width={80} />
                    <col width={40} />
                    <col width={80} />
                    <col width={80} />
                    <col width={40} />
                    <col width={40} />
                  </colgroup>
                  <thead className="table-tr">
                    <tr>
                      <th />
                      <th />
                      <th className="td-center">팝업 표기 페이지</th>
                      <th className="td-center">연결 링크</th>
                      <th className="td-center">등록일</th>
                      <th className="td-center" />
                      <th className="td-center" />
                    </tr>
                  </thead>
                  <tbody>
                    {popupList?.length > 0 ? (
                      popupList.map((item, index) => (
                        <Draggable
                          draggableId={`popup-${item.popupIdx}`}
                          index={index}
                          key={`popup-${item.popupIdx}`}
                        >
                          {(provided, snapshot) => {
                            return (
                              <tr
                                key={index}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <td
                                  className="td-center col-draghandle"
                                  {...provided.dragHandleProps}
                                >
                                  <Image src={images.dragHandle} />
                                </td>
                                <td>
                                  <Image
                                    className="popup-image"
                                    src={item.imagePath}
                                  />
                                </td>
                                <td className="td-center">
                                  {item.popupType
                                    ? POPUP_TYPE[item.popupType]?.desc || '-'
                                    : '-'}
                                </td>

                                <td className="td-center">
                                  {item.actionLink ? item.actionLink : '-'}
                                </td>
                                <td className="td-center">
                                  {item.regDate
                                    ? format(
                                        new Date(item.regDate),
                                        'yyyy-MM-dd',
                                      )
                                    : '-'}
                                </td>
                                <td className="td-center col-popupModi">
                                  <button
                                    type="button"
                                    onClick={() => goModify(item.popupIdx)}
                                  >
                                    수정
                                  </button>
                                </td>
                                <td className="td-center col-popupModi">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      CustomSwal.fire({
                                        title: '팝업 삭제',
                                        text: '팝업를 삭제하시겠습니까?',
                                        then: () => {
                                          deletePopup(item.popupIdx);
                                        },
                                        cancel: true,
                                      });
                                    }}
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            );
                          }}
                        </Draggable>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={6}>
                          팝업이 없습니다.
                        </td>
                      </tr>
                    )}
                    {dropprovided.placeholder}
                  </tbody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </article>
      </Container>
    </main>
  );
});
