/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
// /* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  FormGroup,
  FormLabel,
  Image,
} from 'react-bootstrap';
import { FormikInput } from '@components';
import { images } from '@assets';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { authAction } from '@reducers/authSlice';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RouterPath, Utils } from '../../common';
import { store } from '../../store';
import { CustomException } from '../../common/exceptions';
import { login } from '../../api/RestAPI';
import { CustomSwal } from '../../components';
import { setStorage } from '../../components/AsyncStorage';
import STORAGE_KEY from '../../common/constants/StorageKey';

export default React.memo(function SignIn(props) {
  const auth = useSelector(state => state.auth);
  const location = useLocation();
  const pathNames = location?.pathname?.split('/');
  const loginType =
    pathNames && pathNames.length > 3 ? pathNames[pathNames.length - 1] : null;
  const [isMember, setIsMember] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [menuUrl, setMenuUrl] = useState();

  // Formik
  const SignInFormik = useFormik({
    initialValues: {
      admId: '',
      admPw: '',
    },
    validationSchema: Yup.object().shape({
      admId: Yup.string().required('아이디를 입력하지 않았습니다. 입력하세요.'),
      admPw: Yup.string().required(
        '비밀번호를 입력하지 않았습니다. 입력하세요.',
      ),
    }),
    onSubmit: values => {
      onLogin(values);
    },
  });

  const onLogin = async inputs => {
    try {
      const { admId, admPw } = inputs;
      const params = {
        loginId: admId,
        password: admPw,
      };
      const { data } = await login(params);
      if (data.code === 200) {
        store.dispatch(
          authAction.setAuth({
            grade: data.data.grade,
            accessToken: data.data.accessToken,
            refreshToken: data.data.refreshToken,
            firstMenuUrl: RouterPath.bookingList,
            isLogin: Utils.encrypt('SUCCESS'),
          }),
        );

        // 로그인 시간 스토리지에 적재
        setStorage(STORAGE_KEY.LOGIN_CHECK, new Date());

        if (data.data.registerPw === true) {
          props.history.replace(RouterPath.memberList);
        } else {
          // props.history.replace(RouterPath.pwdchange);
        }
        setIsMember(true);
      } else {
        throw new CustomException('아이디 또는 비밀번호를 잘못 입력했습니다.');
      }
      return true;
    } catch (error) {
      if (error.message && error.message.includes('1101')) {
        CustomSwal.fire({
          text: '아이디 또는 비밀번호를 잘못 입력했습니다.',
        });
      }
      setErrorMessage(
        '아이디 또는 비밀번호를 잘못 입력했습니다.<br />입력하신 내용을 다시 확인해주세요.',
      );
      setIsMember(false);
      return false;
    }
  };

  const { values, dirty, isValid } = SignInFormik;

  // 로그인실패 후 인풋 입력시 실패메세지 지우기
  useEffect(() => {
    if (isMember === false) setIsMember(null);
    return () => {
      setIsMember(null);
    };
  }, [values.admId, values.admPw]);

  useEffect(() => {
    store.dispatch(authAction.removeAuth());
    setStorage(STORAGE_KEY.LOGIN_CHECK, '');
  }, []);

  return (
    <main id="signin" className="auth">
      <Container>
        <div className="logo-area">
          <div className="logo-box" />
        </div>
        <div className="form-box">
          <div className="title-area signin2-area">
            <h3>로그인</h3>
          </div>
          <FormikProvider value={SignInFormik}>
            <Form className="column" style={{ width: '100%' }}>
              <FormLabel>아이디</FormLabel>
              <FormikInput
                type="text"
                id="admId"
                name="admId"
                placeholder="아이디를 입력하세요"
                limitByte="100"
              />
              <div style={{ marginBottom: '1rem' }} />
              <FormLabel>비밀번호</FormLabel>
              <FormikInput
                type="password"
                id="admPw"
                name="admPw"
                placeholder="비밀번호를 입력하세요"
                limitByte="100"
              />

              {/* <FormGroup className="form-group mt-3">
                <FormCheck type="checkbox" label="아이디 저장" id="saveId" />
              </FormGroup> */}
              {/* <p
                className={classNames('invalid-feedback mt-3', {
                  'd-block': isMember === false,
                })}
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              /> */}

              <div className="btn-area mt-auto">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  className="w-100"
                  disabled={!(dirty && isValid)}
                >
                  로그인
                </Button>
              </div>

              {/* <div className="btn-area finds m-0">
                <Button
                  variant="text-dark"
                  onClick={() => props.history.push(RouterPath.findid)}
                >
                  아이디 찾기
                </Button>
                <span />
                <Button
                  variant="text-dark"
                  onClick={() => props.history.push(RouterPath.findpwd)}
                >
                  비밀번호 찾기
                </Button>
              </div> */}
            </Form>
          </FormikProvider>
        </div>
      </Container>
    </main>
  );
});
