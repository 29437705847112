const ADMIN_GRADE = {
  MASTER: {
    code: 'MASTER',
    desc: '최고 관리자',
  },
  ADMIN: {
    code: 'ADMIN',
    desc: '일반 관리자',
  },
  DEV: {
    code: 'DEV',
    desc: '개발자',
  },
};

export default ADMIN_GRADE;
