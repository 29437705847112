import RESULT_CODE from '../common/constants/ResultCode';
import { CustomException, NetworkException } from '../common/exceptions';
import customSwal from '../components/CustomSwal';

const defaultProcess = errMessage => {
  customSwal.fire({
    title: 'FAIL',
    html: errMessage,
  });
};

// eslint-disable-next-line default-param-last
export const handleError = (error, process = defaultProcess, obj) => {
  switch (true) {
    case error instanceof CustomException:
    case error instanceof NetworkException:
      process(error.message, obj);
      break;
    default:
      console.log('handleError error :: ', error);
      process(RESULT_CODE.CODE_500, obj);
  }
};
