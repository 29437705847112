const LOGIN_TYPE = {
  GOOGLE: {
    value: 'GOOGLE',
    label: '구글',
  },
  KAKAO: {
    value: 'KAKAO',
    label: '카카오',
  },
};

export default LOGIN_TYPE;
