/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import { Header, CustomNavigator, CustomSelect } from '@components';
import { Container, Image, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import images from '../../assets/images';
import { RouterPath } from '../../common';
import { getNoticeType, saveNotice } from '../../api/RestAPI';
import { CustomSwal } from '../../components';
import { handleError } from '../../utils/HandleError';

// 이미지 용량 제한
const FILE_SIZE_MAX_LIMIT = 10 * 1024 * 1024;

// 초기값
const initialFormDetail = {
  noticeType: '',
  title: '',
  contents: '',
  files: '',
};

// ===================================================================
// [ 게시물 관리 > 공지 등록 ]
// ===================================================================

export default React.memo(function NoticeRegister(props) {
  const navigate = useNavigate();
  const fileInput = useRef();

  const [searchTypes, setSearchTypes] = useState([]);
  const [newNotice, setNewNotice] = useState(initialFormDetail);
  const [files, setFiles] = useState([]);

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 선택한 유형 값 업데이트
  const handleSelectChange = selectedOption => {
    setNewNotice({ ...newNotice, noticeType: selectedOption.value });
  };

  const onFileUpload = e => {
    const newFiles = e.target.files;
    const promises = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < newFiles.length; i++) {
      const newFile = newFiles[i];
      const reader = new FileReader();

      const promise = new Promise(resolve => {
        reader.onload = () => {
          resolve(newFile);
        };
      });

      reader.readAsDataURL(newFile);
      promises.push(promise);
    }

    return Promise.all(promises).then(filesArray => {
      setFiles(filesArray);
    });
  };

  // 파일 첨부
  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  // 리스트 페이지 이동
  const moveNoticeList = () => {
    navigate(RouterPath.noticeList);
  };

  // 파일 삭제
  const removeFile = index => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  // 빈칸 검증
  const validateForm = () => {
    const result = {
      isValid: false,
      message: '',
    };

    // 유형
    if (!newNotice.noticeType.trim()) {
      result.message = '유형을 선택해주세요.';
      return result;
    }

    // 제목
    if (!newNotice.title.trim()) {
      result.message = '제목을 확인해주세요.';
      return result;
    }
    // 내용
    if (!newNotice.contents.trim()) {
      result.message = '내용을 확인해주세요.';
      return result;
    }
    result.isValid = true;
    return result;
  };

  // ===================================================================
  // [ API ] 공지 유형조회
  // ===================================================================
  const getType = async () => {
    try {
      const { data } = await getNoticeType();
      const info = data.data;

      setSearchTypes(prevSearchTypes => [
        ...prevSearchTypes,
        ...info.map(item => ({
          value: item.subCode,
          label: item.codeName,
        })),
      ]);
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ API ] 등록하기
  // ===================================================================

  const registerNotice = async () => {
    try {
      const validationResult = validateForm();
      if (!validationResult.isValid) {
        CustomSwal.fire({ text: validationResult.message });
        return;
      }

      const param = {
        categoryCode: newNotice.noticeType,
        noticeBody: newNotice.contents,
        noticeTitle: newNotice.title,
        topYn: 'N',
      };

      const formData = new FormData();
      const json = JSON.stringify(param);
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('dto', blob);

      if (files && files.length > 0) {
        files.forEach((file, index) => {
          formData.append(`files`, file);
        });
      }
      const { data } = await saveNotice(formData);

      if (data.code === 200) {
        CustomSwal.fire({
          text: '공지가 등록되었습니다.',
          finally: () => navigate(`${RouterPath.noticeList}/1`),
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getType();
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="notice-Register">
      <Header title="공지사항" />
      <Container className="contents">
        <CustomNavigator
          pageNameList={['공지사항', '공지사항 등록']}
          pathList={[RouterPath.noticeList]}
        />
        <div className="title">
          <p className="title-text">공지사항 등록</p>
        </div>
        {/* 입력칸 바디 */}
        <article className="menu-article">
          <div className="align-left">
            {/* 공지 유형 선택 */}
            <div className="add-div">
              <p>
                공지유형 <images.Ellipse />
              </p>
              <CustomSelect
                className="max-200"
                placeholder="유형 선택"
                options={searchTypes}
                onChange={handleSelectChange}
              />
            </div>
            {/* 제목 입력 */}
            <div className="add-div">
              <p>제목</p>
              <Form.Control
                type="text"
                placeholder="제목을 입력하세요."
                onChange={e =>
                  setNewNotice({ ...newNotice, title: e.target.value })
                }
              />
            </div>
            {/* 내용 입력 */}
            <div className="add-div">
              <p>내용</p>
              <Form.Control
                as="textarea"
                placeholder="내용을 입력하세요."
                style={{ height: '215px', resize: 'none' }}
                onChange={e =>
                  setNewNotice({ ...newNotice, contents: e.target.value })
                }
              />
            </div>

            {/* 파일 첨부 */}
            <div>
              <p>파일첨부</p>
              {files.map((file, index) => (
                <div style={{ display: 'flex' }}>
                  <Form.Control
                    type="text"
                    value={file.name}
                    placeholder="파일을 업로드 해주세요"
                    style={{ margin: '0 0 5px', flex: 1 }}
                    onChange={() => false}
                  />
                  <Image
                    src={images.icCancleBtn}
                    className="close-img"
                    onClick={() => removeFile(index)}
                  />
                </div>
              ))}
              <Button onClick={handleFileButtonClick}>파일첨부</Button>
              <input
                type="file"
                ref={fileInput}
                onChange={e => onFileUpload(e)}
                multiple
                style={{ display: 'none' }}
              />
            </div>
            <span
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#525252',
              }}
            >
              *이미지 또는 문서를 첨부해주세요.
            </span>
            <div style={{ textAlign: 'right' }}>
              {/* 취소 btn */}
              <button
                type="button"
                className="cancle-btn"
                onClick={() => {
                  CustomSwal.fire({
                    text: '취소하시겠습니까?',
                    cancel: true,
                    then: moveNoticeList,
                  });
                }}
              >
                취소
              </button>
              {/* 등록 btn */}
              <button
                type="button"
                className="register"
                onClick={() => {
                  CustomSwal.fire({
                    text: '공지사항을 등록하시겠습니까?',
                    cancel: true,
                    then: registerNotice,
                  });
                }}
              >
                등록
              </button>
            </div>
          </div>
        </article>
      </Container>
    </main>
  );
});
