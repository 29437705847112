/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Oval } from 'react-loader-spinner';

export default function Loading() {
  return (
    <div css={loading}>
      <div className="background">
        <Oval
          height={60}
          width={60}
          color="#fffffa"
          // eslint-disable-next-line react/jsx-boolean-value
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="gray"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      </div>
    </div>
  );
}

const loading = css`
  .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    // background: #000;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
